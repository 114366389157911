import { graphConfig } from "./authConfig";
/*
 * Manda un token de acceso a la API de MS Graph. Devuelve información sobre el usuario
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => {
            throw error;
        });
}