import React, {Component} from 'react';
import logo from '../../assets/img/ipg-logo.png'

class NotFound extends Component {
  render() {
    return (
    <div className="padre-transform">
      <div>
        <div className="center">
          <img src={logo} alt="logo-ipg" /><br/><br/>
          <span className='not-found bold'>404</span>
          <h3 className="bold">Page not found</h3>
          <h2>This page does not exist. Please check your url or go back to <a href='/' className='forgot'>homepage</a>.</h2>
        </div>
      </div>
    </div>

    
    );
  }
}

export default NotFound;