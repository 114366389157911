/**
 * * FORMULARIO PARA AÑADIR NUEVO USUARIO
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import Select from 'react-select'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

class RegistrarUsuarioInterno extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error : '',
            errors: {},
            input: {},
            isClearable: true,
            tipoUsuario: " ",
            tipoUsuarioList: [],
        }
    this.guardarUsuario = this.guardarUsuario.bind(this);
    this.limpiaCampos = this.limpiaCampos.bind(this);
    }

    /**
    * Obtiene la lista de tipo de usuarios
    */
    componentDidMount() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        this.timer = setTimeout(() => {
            Promise.all([
                fetch(API_PATH +"portal_grupo/", {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }),
            ])
            .then(([res1]) => Promise.all([res1.json()]))
            .then(([data1]) => {
                if(data1.responseCode === 404){
                    Swal.fire({
                        title: DOMPurify.sanitize(`${data1.detail}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    });
                }else{
                    this.setState({
                        tipoUsuarioList: data1,
                    });
                }
            });
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    /**
    * Maneja los cambios que se realizan en los inputs
    */
    handleChange = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [e.target.name]: e.target.value
            }
        }));
    }

    handleChangeS = tipoUsuarioList => {
        const tipoUsuarioS = tipoUsuarioList.map(option => option.label);
        this.setState({ tipoUsuarioS});
        
      };

    /**
    * LIMPIA LOS CAMPOS DESPUES DEL REGISTRO
    */
    limpiaCampos() {
        this.setState({
        input: '',
        });
    }

    /**
    * Validación de inputs
    */
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["name"]) {
            isValid = false;
            errors["name"] = "Ingresa el nombre del usuario";
        }

        if (typeof input["name"] !== "undefined") {
            var pattern = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
            if (!pattern.test(input["name"])) {
                isValid = false;
                errors["name"] = "Solo letras";
            }
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Ingresa el correo del usuario";
        }

        if (typeof input["email"] !== "undefined") {

            var patternMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!patternMail.test(input["email"])) {
                isValid = false;
                errors["email"] = "Ingresa un correo válido";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Función para el guardado de datos (nuevo usuario)
    */
    guardarUsuario(event){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        event.preventDefault();
        
        //VALIDA QUE SE HAYA SELECIIONADO UN TIPO DE USUARIO
        if (this.state.tipoUsuario.value === undefined)  {
            document.getElementById('msg_tipoUsuario').textContent =  'Favor de seleccionar minimo un tipo de usuario';
            //  valida que los campos nombre y correo esten completos y sean válidos  
            if(this.validate()){
                let input = {};
                input["name"] = "";
                input["email"] = "";
                this.setState({input:input});
            }
        } else { // comienza guardado
            const dataBody = {
                nombre: this.state.input.name,
                correo:this.state.input.email,
                grupo:this.state.tipoUsuario.value
            };
            const jsonString = this.convertObjectToJsonString(dataBody);

            const options = {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: jsonString
            }
            fetch(API_PATH + 'usuarios/nuevo_usuario', options)
            .then(response => {
                if (response.ok) {// si el estatus code arroja un 200  continua
                  return response.json()
                } else {// si no arroja el estatus code del error
                    Swal.fire({
                        title: DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
            .then(result => {
                if(result.responseCode === 200){// si en responseCode arroja un 200 continua con el guardado
                    Swal.fire({
                        text: "El usuario se registro correctamente",
                        showCancelButton: false,
                        showConfirmButton: false,
                        icon: "success",
                        timer: 4000
                    })
                    this.props.actualizar();
                    this.limpiaCampos();
                } 
                else{
                    Swal.fire({
                        text: DOMPurify.sanitize(`${result.detail}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                } 
            })
            
        } 
    }

    render() {
    /**
     * CONFIGURA EL RESULTADO DE VALUE Y LABEL DEL SELECT 
     */
    let options = this.state.tipoUsuarioList.map(function (tipoUsuarioList) {
        return { value: tipoUsuarioList.id, label: tipoUsuarioList.nombre };
      })
    const {isClearable} = this.state;
    return (
        <div className="container pad-30">
            <div className="row">
                <div className="col-sm-12">
                    <form onSubmit={this.guardarUsuario} >
                        <i className="fa-solid fa-person"></i>&nbsp;<label>Nombre</label>
                        <input type="text"
                            placeholder="Nombre"
                            name="name"
                            onChange={this.handleChange}
                            value={this.state.input.name}
                            id="name"
                            autoFocus="autofocus"
                            className='form-control'
                        />
                        <p className='error'>{this.state.errors.name}</p>
                        <i className="fa-solid fa-envelope"></i>&nbsp;<label>Correo</label>
                        <input
                            type="text"
                            name="email"
                            value={this.state.input.email}
                            onChange={this.handleChange}
                            placeholder="Correo"
                            id="email"
                            className='form-control'
                        />                        
                        <p className='error'>{this.state.errors.email}</p>
                        <i className="fa-solid fa-address-card"></i>&nbsp;<label>Tipo de usuario</label>
                        <Select
                           // isMulti
                            className="z-index-1"
                            placeholder="Tipo de usuario"
                            options={options}
                            isClearable={isClearable}
                            value={this.state.tipoUsuario}
                            onChange={event => {
                                this.setState({ tipoUsuario: event });
                                document.getElementById('msg_tipoUsuario').textContent =  '';
                            }}

                        />
                        <p className='error' id="msg_tipoUsuario"></p>
                        <div className='right'><button className='btn btn-primary btn-bg'>Guardar</button></div>
                    </form>
                </div>
            </div>
        </div>
    );
  }
}

export default RegistrarUsuarioInterno;