/**
 * Index del formulario de registro
 * Manda llamar al modal con información importante
 * Manda llamar el componente que contiene el formulario
 */
import React from 'react';
import ModalInfluencer from './ModalInfluencer';
import FormInfluencer from './FormInfluencer';
import HeaderInfluencer from '../Header/HeaderInfluencer';

class RegistroInfluencer extends React.Component { 
  render() {
    return(
      <div>
        <HeaderInfluencer/>
        <div className="container form-registro">
          <div className="row">
            <div className="col-md-12">   
              <ModalInfluencer/>
              <FormInfluencer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistroInfluencer;
