/**
 * Index del formulario de registro
 * Manda llamar al modal con información importante
 * Manda llamar el componente que contiene el formulario
 */
import React from 'react';
import ModalRegistroProveedor from './ModalPreregistro';
import Form from './Form';
import Header from '../Header/Header';

class RegistroProveedor extends React.Component { 
  render() {
    return(
      <div>
        <Header/>
        <div className="container form-registro">
          <div className="row">
            <div className="col-md-12">   
              <ModalRegistroProveedor/>
              <Form />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistroProveedor;
