/**
 * MUESTRA LA INFORMACIÓN DEL PROVEEDOR Y PERMITE EDITARLA
*/

import React from 'react';
import DOMPurify from 'dompurify';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import { withRouter } from 'react-router-dom';
import Select from 'react-select'
import axios from 'axios';


class InformacionProveedorEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isClearable: true,
            isEditing: false,
            valorBuscado: 9, //editar proveedor
            redirectToReferrer: false,
            paisCuenta: " ",
            paisCuentaList: [],
            selectedValue: '', // Variable para almacenar el valor seleccionado
            loading: true,
            userData: {
                rfc: '',
                razon_social: '',
                cp:'',
                ciudad:'',
                direccion:'',
                telefono:'',
                email:'',
                pais_cuenta:'',
                clabe_bancaria:'',
                cuenta_bancaria:'',
                titular_cuenta:'',
                referencia_cuenta:'',
                swif_cuenta:'',
                aba_cuenta:'',
                IBAN:'',
                Banco_intermediario:'',
                Swift_banco_intermediario:'',
                ABA_banco_intermediario:'',
                clave_pais:'',
                status:'',
                estatus:'',
                usuario_sistemas:'',
                archivos_rechazados: [],
            },
            archivos_rechazados: [],
            originalData: {},
            uploadedNewFiles: JSON.parse(sessionStorage.getItem('uploadedNewFiles')) || [],
            errorsFile:{},
            files: [],
            errores: {},
        };
    }

    componentWillMount() {
        //valida que solo los usuarios con rol de administrador tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    componentDidMount() {
        // file deepcode ignore ReactMissingCleanup: <please specify a reason of ignoring this>
        setTimeout(() => {
        this.fetchInfoProveedor();
        }, 100);
        setTimeout(() => {
            Promise.all([
                fetch(API_PATH +'paises/', {
                headers: {'Content-Type': 'application/json'},
                }),
            ])
            .then(([res1]) => Promise.all([res1.json()]))
            .then(([data1]) => this.setState({
              paisCuentaList: data1,
            }));
        }, 100);
    }

    fetchInfoProveedor() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const idProveedor = data.idProveedor

        fetch(API_PATH + 'proveedor/datos?id_proveedor='+ idProveedor , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()
            } else {
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
          .then((data) => {
            const detailData = data[0]?.detail || {}; // Asegúrate de manejar el caso en el que el arreglo esté vacío
            const selectedData = {
                rfc: detailData.rfc || '',
                pais_cuenta: detailData.pais_cuenta || '',
                clave_pais: detailData.clave_pais || '',
                razon_social: detailData.razon_social || '',
                cp:detailData.cp||'',
                ciudad:detailData.ciudad ||'',
                direccion:detailData.direccion ||'',
                telefono:detailData.telefono ||'',
                email:detailData.email ||'',
                clabe_bancaria:detailData.clabe_bancaria ||'',
                cuenta_bancaria:detailData.cuenta_bancaria ||'',
                titular_cuenta:detailData.titular_cuenta ||'',
                referencia_cuenta:detailData.referencia_cuenta ||'',
                swif_cuenta:detailData.swif_cuenta ||'',
                aba_cuenta:detailData.aba_cuenta ||'',
                IBAN:detailData.IBAN ||'',
                Banco_intermediario:detailData.Banco_intermediario ||'',
                Swift_banco_intermediario:detailData.Swift_banco_intermediario ||'',
                ABA_banco_intermediario:detailData.ABA_banco_intermediario ||'',
                status:detailData.status ||'',
                estatus:detailData.estatus ||'',
                usuario_sistemas:detailData.usuario_sistemas ||'',
            };
            if (data.length > 0 && data[0].success && data[0].archivos_rechazados) {
                // Obtener la lista de archivos_rechazados
                const archivos_rechazadosData = data[0].archivos_rechazados;

                // Actualizar el estado con la lista de archivos_rechazados
                this.setState({ archivos_rechazados: archivos_rechazadosData });
            } else {
                console.error("La respuesta de la API no contiene archivos_rechazados o no tuvo éxito.");
            }
            this.setState({
              userData: { ...selectedData },
              originalData: { ...selectedData },
              loading: false
            });
          })
        .catch(error => {
            throw error;
        });
    }


    handleInputChange = (e) => {
        const { name, value } = e.target;
        // Actualiza solo el campo editado
        this.setState((prevState) => ({
          userData: {
            ...prevState.userData,
            [name]: value,
          },
        }));
    };

    handleEditClick = () => {
        this.setState({ isEditing: true });
    }

    handleCancelClick = () => {
        this.setState({ isEditing: false });
    }

    handleFileChange = (e, fileId) => {
        const files = Array.from(e.target.files);
        const updatedFiles = files.map(file => ({
        file,
        name: file.name,
        fileId,
        }));
        this.setState(prevState => ({
        files: [...prevState.files.filter(item => item.fileId !== fileId), ...updatedFiles],
        }));
    };
  
    /**
    * Función para subir archivos_rechazados
    */
    handleSubmit = async (e) => {
        e.preventDefault();
        const { files } = this.state;
        const uploadedNewFiles = [];
        const uploadedNewFilesID = [];
        const errorsFile = {};
        // Validar las extensiones de los archivos_rechazados antes de enviarlos
        for (const { file, fileId } of files) {
          const allowedExtensions = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'xlsx', 'pdf'];
          const extension = file.name.split('.').pop().toLowerCase();
          if (!allowedExtensions.includes(extension)) {
            errorsFile[fileId] = 'El archivo debe tener una extensión válida.';
          }
        }
        // Actualizar el estado con los errores
        this.setState({ errorsFile });
        // Si no hay errores de validación, enviar los archivos_rechazados
        if (Object.keys(errorsFile).length === 0) {
            Swal.fire({
                title: 'Guardando...',
                text: 'Por favor, espera mientras se suben los archivos.',
                icon: 'info',
                showConfirmButton: false,
              });
          for (const { file, fileId } of files) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await axios.post(
                    API_PATH + "archivos/carga/?id_documento=" + fileId,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                ); 
                if(response.data.success){
                    uploadedNewFiles.push(response.data.detail);
                    uploadedNewFilesID.push(response.data.archivoID);
                }
            } catch (error) {
                console.error('Error al enviar archivo:', error);
                sessionStorage.removeItem('uploadedNewFiles');
                sessionStorage.removeItem('uploadedNewFilesID');
                Swal.fire({
                    text:"Ocurrió un error al enviar los archivos, intenta nuevamente",
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                }) 
                sessionStorage.removeItem('uploadedNewFiles');
                sessionStorage.removeItem('uploadedNewFilesID');
                // Si se produce un error, reinicia el estado de los archivos_rechazados
                this.setState({ files: [], errorsFile: {} });
                return; // Sale del bucle para evitar más intentos de envío
            }  
              
        }
        Swal.close(); 
         // Actualizar el estado con todos los nombres de archivos_rechazados al finalizar el envío
         this.setState({ uploadedNewFiles, uploadedNewFilesID });
         // Almacenar uploadedNewFiles en sessionStorage
         sessionStorage.setItem('uploadedNewFiles', JSON.stringify(uploadedNewFiles));
         sessionStorage.setItem('uploadedNewFilesID', JSON.stringify(uploadedNewFilesID));
        } 
    };
      
    limpiarSessionStorageYRenderizar() {
        sessionStorage.removeItem('uploadedNewFiles');
        sessionStorage.removeItem('uploadedNewFilesID');
        this.setState({ uploadedNewFiles: [], files: [] });
    }

    /**
    * Función para guardar los datos editados
    */
    editarInformacion = async (event) => {
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const idProveedor = data.idProveedor
        const countryAcount = sessionStorage.getItem("pais_cuenta")
        const countrySelect=( this.state.selectedValue === "") ? countryAcount : this.state.selectedValue;
        const errores = {};
        // Recorrer el arreglo y obtener solo los valores de la propiedad archivoID
        const idsDocsAnterior = this.state.archivos_rechazados.map(archivo => archivo.archivoID);
    
        // Obtener los archivos_rechazados nuevos
        const arrayNewFiles = sessionStorage.getItem("uploadedNewFilesID");
        const newsfilesParse = JSON.parse(arrayNewFiles);
        

        const options = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body:  JSON.stringify(
                {
                    
                        cp: this.state.userData.cp,
                        direccion: this.state.userData.direccion,
                        ciudad: this.state.userData.ciudad,
                        telefono: this.state.userData.telefono,
                        email: this.state.userData.email,
                        pais_cuenta: countrySelect,
                        clabe_bancaria: this.state.userData.clabe_bancaria,
                        cuenta_bancaria: this.state.userData.cuenta_bancaria,
                        titular_cuenta: this.state.userData.titular_cuenta,
                        referencia_cuenta: this.state.userData.referencia_cuenta,
                        swif_cuenta: this.state.userData.swif_cuenta,
                        aba_cuenta: this.state.userData.aba_cuenta,
                        IBAN: this.state.userData.IBAN,
                        Banco_intermediario: this.state.userData.Banco_intermediario,
                        Swift_banco_intermediario: this.state.userData.Swift_banco_intermediario,
                        ABA_banco_intermediario: this.state.userData.ABA_banco_intermediario,
                        id_proveedor: idProveedor,
                        documentos_rechazados: idsDocsAnterior,
                        documentos_nuevos: newsfilesParse || [],
                    
                }
            ) 
        }

        if(this.state.userData.estatus === 9){
            if (!this.state.userData.cp) {
                errores.cp = 'El campo es obligatorio.';
            }
            if (!this.state.userData.ciudad) {
                errores.ciudad = 'El campo es obligatorio.';
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.state.userData.email) {
                errores.email = 'El campo es obligatorio.';
            }else if (!emailRegex.test(this.state.userData.email)) {
                errores.email = 'El correo electrónico no es válido.';
            }
            
            if (!this.state.userData.direccion) {
                errores.direccion = 'El campo es obligatorio.';
            }
            if (!this.state.userData.cuenta_bancaria) {
                errores.cuenta_bancaria = 'El campo es obligatorio.';
            }
            if (!this.state.userData.referencia_cuenta) {
                errores.referencia_cuenta = 'El campo es obligatorio.';
            }
        }

        if (Object.keys(errores).length === 0) {
            Swal.fire({
                title: 'Guardando...',
                text: 'Por favor, espera mientras se guarda la información.',
                icon: 'info',
                showConfirmButton: false,
            });
            fetch(API_PATH + 'proveedor/update/', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                        return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
            .then(result => {
                Swal.close();
                if(result.success === true ){// si en responseCode arroja true  manda mensaje de exito 
                    Swal.fire({
                            text: DOMPurify.sanitize(`${result.detail}`),
                            showCancelButton: false,
                            showConfirmButton: true,
                            icon: "info",
                           // timer: 8000
                    }).then((result) => {
                        this.setState({ isEditing: false })
                        if (result.isConfirmed) {
                            sessionStorage.removeItem('uploadedNewFiles');
                            sessionStorage.removeItem('uploadedNewFilesID');
                            window.location.reload();
                        }
                    });

                    
                    //     setTimeout(function () {
                    //         sessionStorage.removeItem('uploadedNewFiles');
                    //         sessionStorage.removeItem('uploadedNewFilesID');
                    //         //window.location.reload();
                    // },  1000);
                } else{// si arroja false en responseCode 
                        Swal.fire({
                            text: result.detail,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                            icon: "error",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sessionStorage.removeItem('uploadedNewFiles');
                                sessionStorage.removeItem('uploadedNewFilesID');
                                window.location.reload();
                            }
                        });
                    }  
                })
                
            .catch(error => {
                this.setState({ isEditing: true })
                    Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                    sessionStorage.removeItem('uploadedNewFiles');
                    sessionStorage.removeItem('uploadedNewFilesID');
                    throw error;
            })
     
            
        } else{
            this.setState({ errores });
        }
    }

    documentos(){
        const { errorsFile } = this.state;
        //const arrayFiles = sessionStorage.getItem("uploadedNewFiles")
        const uploadedNewFiles = JSON.parse(sessionStorage.getItem('uploadedNewFiles')) || [];
        if(uploadedNewFiles.length > 0){
            return(
                <div>
                    <div className='col-md-12'>  
                        <div className="alert alert-success d-flex align-items-center" role="alert"><div>La carga de archivos se ha completado con éxito</div></div>
                    </div>
                    <ol>
                        {uploadedNewFiles.map((fileName, index) => (
                            <li key={index}>{fileName}</li>
                        ))}
                    </ol>
                    <div className='col-md-12'>
                        <button className='btn btn-primary btn-bg-str ' onClick={() =>this.limpiarSessionStorageYRenderizar()}>Volver a subir archivos</button>
                    </div>              
                </div>
            )
        }
        else{
            return (
                <>
                    <form onSubmit={this.handleSubmit}>

                        {this.state.archivos_rechazados.map(archivo => (
                            <div key={archivo.archivoID}>
                                <b>{archivo.nombreDocumento}</b>
                                <input
                                    type="file"
                                    id={`file-${archivo.tipoDocumento}`}
                                    onChange={(e) => this.handleFileChange(e, archivo.tipoDocumento)}
                                    required
                                    name={archivo.nombreArchivo}
                                    className='form-control'
                                />
                                {errorsFile[archivo.archivoID] && <div style={{ color: 'red' }}>{errorsFile[archivo.archivoID]}</div>}
                                <br/>
                            </div>
                        ))}
                    
                        <div className='col-md-12 right'>
                            <br/>
                            <button className='btn btn-primary btn-bg-str' type='submit'>Subir archivos</button>
                        </div>
                    </form>
                </>
            )
        }
    }

    render() {
        const { valorBuscado,  isEditing, errores } = this.state;
        const data = JSON.parse(sessionStorage.getItem('userData'));
        //verifica si tiene el rol de editar proveedores (9)
        const roles = data.roles;
        const contieneValor = roles.includes(valorBuscado);
        let editar = (contieneValor === true) ? 1 :0
     
        let isVisible = ( this.state.userData.estatus === 9) ? true : false;//aprobado
        //let isVisible = true
        var textoEditar = (this.state.userData.estatus === 9)? "Editar información" : "Editar archivos"
        let options = this.state.paisCuentaList.map(function (paisCuentaList) {
            return { value: paisCuentaList.clave_pais, label: paisCuentaList.nombre };
        })

        const uploadedNewFiles = this.state.archivos_rechazados.length 
        const uploadedNewFilesSession = JSON.parse(sessionStorage.getItem('uploadedNewFiles')) || [];

        let isVisible2 = ( (this.state.userData.status === "Rechazado parcialmente" && uploadedNewFiles > 0)|| this.state.userData.status === "Integrado") ? true : false;
        //let isVisible2= true

        let botonHabilitado = uploadedNewFiles > 0 &&  uploadedNewFilesSession === 0 ? true : false
        if (this.state.loading === true) { // muestra un loading mientras carga la página y datos de la tabla
            return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }

        return (
            <div className="container info-proveedores ">
                <div className='row'>
                    <div className='col-md-12'>
                        <br/>
                        <h2 className='center'>Información de {this.state.userData.razon_social}</h2>
                        <hr/>
                    </div>

                    {editar === 1 && uploadedNewFiles>0 && !isEditing && (
                    <div className="alert alert-warning" role="alert">Tienes documentos por corregir, da clic en editar archivos para enviarlos nuevamente</div>
                    )}  

                    {isVisible2 ? (
                        <>
                           {editar === 1 && !isEditing && (
                                <div className='col-md-12 btns-edit'>
                                    <button onClick={this.handleEditClick} className='btn btn-primary btn-bg'><i className="fa-solid fa-pen-to-square white"></i> {textoEditar}</button>
                                </div>
                            )}  
                        </>
                    ) : null}
                </div>

                {isEditing  && editar === 1 ? (
                    <>
                        <form >
                            {isVisible ? (
                            <>
                                <div className='row'>
                                    <h4 className='blue-str'>Datos Generales</h4><br /><br />
                                    <div className='col-md-4'>
                                        <b>País:</b><br/>
                                        <input
                                            type="text"
                                            placeholder={this.state.userData.clave_pais}
                                            disabled  
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <b>Número de identificación tributaria:</b><br/>
                                        <input
                                            type="text"
                                            placeholder={this.state.userData.rfc}
                                            disabled  
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <b>Correo Electrónico:</b><br/>
                                        <input
                                            type="text"
                                            name='email'
                                           
                                            value={this.state.userData.email}  
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                            
                                        />
                                        {errores.email && <span className="error">{errores.email}</span>}
                                    </div>
                                    <div className='col-md-6'>
                                    <br/><b>Direccion:</b><br/>
                                        <input
                                            type="text"
                                            name='direccion'
                                            // value={this.state.userData.direccion}
                                            // onChange={this.handleInputChange}
                                            placeholder={this.state.userData.direccion}
                                            className='form-control'
                                            disabled  
                                        />
                                        {errores.direccion && <span className="error">{errores.direccion}</span>}
                                    </div>
                                    <div className='col-md-3'>
                                    <br/><b>Código Postal:</b><br/>
                                        <input
                                            type="text"
                                            name="cp"
                                            // value={this.state.userData.cp}
                                            // onChange={this.handleInputChange}
                                            placeholder={this.state.userData.cp}
                                            className='form-control'
                                            disabled  
                                        />
                                        {errores.cp && <span className="error">{errores.cp}</span>}
                                    </div>
                                    <div className='col-md-3'>
                                    <br/><b>Ciudad:</b><br/>
                                        <input
                                            type="text"
                                            name="ciudad"
                                            // value={this.state.userData.ciudad}
                                            // onChange={this.handleInputChange}
                                            placeholder={this.state.userData.ciudad}
                                            className='form-control'
                                            disabled  
                                        />
                                        {errores.ciudad && <span className="error">{errores.ciudad}</span>} 
                                    </div>
                                    <div className='col-md-3'>
                                    <br/><b>Teléfono:</b><br/>
                                        <input
                                            type="text"
                                            name='telefono'
                                            // value={this.state.userData.telefono}
                                            // onChange={this.handleInputChange}
                                            className='form-control'
                                            placeholder={this.state.userData.telefono}
                                            disabled  
                                        />
                                    </div>
                                </div>
                                <br/>
                                <hr/>
                                <br />
                                <div className='row'>
                                    <h4 className='blue-str'>Datos Bancarios</h4><br /><br />
                                    <div className='col-md-2'>
                                        <b>País de Origen Banco:</b><br/>
                                        <Select
                                            className="z-index-1"
                                            options={options}
                                            defaultValue={{value:this.state.userData.pais_cuenta, label:this.state.userData.pais_cuenta}}
                                            onChange={(newSelected)=>{
                                                this.setState({selectedValue: newSelected.value})
                                            }} 
                                        />
                                    </div>
                                    <div className='col-md-5'>
                                        <b>No.Cuenta y/o CLABLE</b><br/>
                                        <input
                                            type="text"
                                            name='cuenta_bancaria'
                                            value={this.state.userData.cuenta_bancaria}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                        {errores.cuenta_bancaria && <span className="error">{errores.cuenta_bancaria}</span>}
                                    </div>
                                    <div className='col-md-5'>
                                        <b>Banco Beneficiario</b><br/>
                                        <input
                                            type="text"
                                            name='referencia_cuenta'
                                            value={this.state.userData.referencia_cuenta}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                        {errores.referencia_cuenta && <span className="error">{errores.referencia_cuenta}</span>}
                                    </div>
                                    <div className='col-md-6'>
                                    <br /><b>Titular de la Cuenta:</b><br/>
                                        <input
                                            type="text"
                                            name='titular_cuenta'
                                            value={this.state.userData.titular_cuenta}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>Swfit:</b><br/>
                                        <input
                                            type="text"
                                            name='swif_cuenta'
                                            value={this.state.userData.swif_cuenta}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>ABA:</b><br/>
                                        <input
                                            type="text"
                                            name='aba_cuenta'
                                            value={this.state.userData.aba_cuenta}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>Cuenta IBAN:</b><br/>
                                        <input
                                            type="text"
                                            name='IBAN'
                                            value={this.state.userData.IBAN}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>Banco Intermediario:</b><br/>
                                        <input
                                            type="text"
                                            name='Banco_intermediario'
                                            value={this.state.userData.Banco_intermediario}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>Swift Banco Intermediario:</b><br/>
                                        <input
                                            type="text"
                                            name='Swift_banco_intermediario'
                                            value={this.state.userData.Swift_banco_intermediario}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                    <br /><b>ABA Banco Intermediario:</b><br/>
                                        <input
                                            type="text"
                                            name='ABA_banco_intermediario'
                                            value={this.state.userData.ABA_banco_intermediario}
                                            onChange={this.handleInputChange}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                            </>
                            ) : null}
                        </form>
                        {this.state.archivos_rechazados.length > 0 && (
                            <div className='col-md-6'>
                                <h4 className='red'>Debes volver a subir los siguientes documentos</h4>
                                {this.documentos()}
                            </div>
                        )}
                        <br/>
                        <div className='btns-edit'>
                            <button onClick={this.handleCancelClick} className='btn btn-primary btn-red '>Cancelar</button>&nbsp;
                            <button  type="submit" className='btn btn-primary btn-green' onClick={this.editarInformacion} disabled={botonHabilitado} >Guardar</button>
                        </div>
                        <br/> 
                    </>
                ) : (   
                    <>
                        <div className='row'>
                        
                            <div className='col-md-12'>
                                <h4 className='blue-str'>Datos Generales</h4>
                                <b>País: </b> {this.state.userData.clave_pais}<br/> 
                                <b>Número de identificación tributaria:</b> {this.state.userData.rfc}<br/>
                                <b>Dirección:</b> {this.state.userData.direccion}<br/>
                                <b>Código Postal:</b> {this.state.userData.cp}<br/>
                                <b>Ciudad:</b> {this.state.userData.ciudad}<br/>
                                <b>Correo Electrónico:</b> {this.state.userData.email}<br/>
                                <b>Teléfono:</b> {this.state.userData.telefono}<br/>
                                <hr/>
                                <b>Usuario:</b>{this.state.userData.usuario_sistemas}<br/>
                                <b>Estatus:</b>{this.state.userData.status}<br/>
                            </div>
                        </div>
                         <hr/>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h4 className='blue-str'>Datos Bancarios</h4>
                                <b>País de Origen Banco:</b> {this.state.userData.pais_cuenta}<br/>
                                <b>No.Cuenta y/o CLABE:</b> {this.state.userData.cuenta_bancaria}<br/>
                                <b>Titular de la Cuenta:</b> {this.state.userData.titular_cuenta}<br/>
                                <b>Banco Beneficiario</b> {this.state.userData.referencia_cuenta}<br/>
                                <b>Swift:</b> {this.state.userData.swif_cuenta}<br/>
                                <b>ABA:</b> {this.state.userData.aba_cuenta}<br/> 
                                <b>Cuenta IBAN:</b> {this.state.userData.IBAN} <br/>
                                <b>Banco Intermediario:</b> {this.state.userData.Banco_intermediario} <br/>
                                <b>Swift Banco Intermediario:</b> {this.state.userData.Swift_banco_intermediario}<br/>
                                <b>ABA Banco Intermediario:</b> {this.state.userData.ABA_banco_intermediario}<br/>
                            </div>                      
                        </div>
                    </>
                )}
            <br/><br/><br/><br/><br/>
        </div> 
        )
    }
}

export default withRouter (InformacionProveedorEdit);
