/**
* Configura las rutas de inicio y registro
*/

import React from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Footer from '../components/Footer/Footer';
import Home from "./Home/Home";
import HomeCierre from "./Home/HomeCierre";
import NotFound from "./NotFound/NotFound";
import RegistroProveedor from "./RegistroProveedor/Registro";
import RegistroInfluencer from "./RegistroProveedor/RegistroInfluencer";

export const SignInButton = () => {
  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/preregistro" component={RegistroProveedor} />
          <Route path="/registroinfluencer" component={RegistroInfluencer} />
          <Route path="/cierre" component={HomeCierre} />
          <Route path="*" component={NotFound}/>
        </Switch>
      </Router>
      <Footer/>
    </React.StrictMode>
  )
}