import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class LanguageSelector extends Component {
  handleChangeLanguage = event => {
    const { i18n, openModal} = this.props;
    i18n.changeLanguage(event.target.value);

    openModal();
  
  };

  render() {
    const { t } = this.props;

    return (
      <select onChange={this.handleChangeLanguage} className='idiomas-options'>
        <option value="es">{t('Buttons.spanish')}</option>
        <option value="en">{t('Buttons.english')}</option>
      </select>
    );
  }
}

export default withTranslation()(LanguageSelector);