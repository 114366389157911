/**
 * MODAL AL INICIO DEL REGISTRO
 */
import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import carta from '../../assets/documents/Carta_de_Ingresos.docx'



class ModalRegistroProveedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    }; 
  }
  render() {
    const { t, isOpen, closeModal } = this.props;
      return(
      <div>
        <Modal show={isOpen} onHide={closeModal} dialogClassName="modal-dialog modal-xl">
          <Modal.Header closeButton>
            <Modal.Title>{t('Modal.text1')}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <p>
          {t('Modal.text2')}
          </p>
          <div className="alert alert-info">
          {t('Modal.text3')}
          </div>
          <p>
          {t('Modal.text4')}
          </p>
          <div className='row'>
            <div className='col-md-4'>
              <h3><small>{t('Modal.text5')}</small></h3>
              <ul>
                <li>{t('Documents.label1')}</li>
                <li>{t('Documents.label2')}</li>
                <li>{t('Documents.label4')}</li>
              
                <li>{t('Documents.label5')}</li>
                <li>{t('Documents.label6')}</li>
                <li>{t('Documents.label9')}</li>
                <li>{t('Documents.label23')} <b>{t('Documents.label023')}</b></li>
              </ul>
              <div className="alert alert-info" role="alert"><a href={carta} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-file-arrow-down"></i> &nbsp;{t('Documents.label25')}</a></div>
             
            </div>
            <div className='col-md-4'>
            <h3><small>{t('Modal.text11')}</small></h3>
              <ul>
              <li>{t('Documents.label1')}</li>
                <li>{t('Documents.label2')}</li>
                <li>{t('Documents.label5')}</li>
                <li>{t('Documents.label6')}</li>
                <li>{t('Influencer.text6')}</li>
                <li>{t('Documents.label13')}</li>
                <li>{t('Documents.label12')}</li>
                <li>{t('Documents.label23')} <b>{t('Documents.label023')}</b></li>
              </ul>
            </div>
            <div className='col-md-4'>
              <h3><small>{t('Modal.text19')}</small></h3>
              <ul>
              <li>{t('Documents.label15')}</li>
                <li>{t('Documents.label16')}</li>
                <li>{t('Documents.label18')}</li>
                <li>{t('Documents.label23')} <b>{t('Documents.label023')}</b></li>
                <li>{t('Documents.label19')}</li>
                <li>{t('Documents.label17')}</li>
              </ul>
              <div className="alert info-alert" role="alert">{t('Documents.label24')}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
          {t('Modal.text25')}
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(ModalRegistroProveedor);