/**
 * * CONTIENE EL FORMULARIO PARA LA SECCION DE CONTACTO AGENCIA
*/

import React, { Component } from 'react';
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { withTranslation } from 'react-i18next';

class ContactoAgencia extends Component {
    /**
    * Llama a la función continuar
    * Primero valida que exista el correo en la base de datos
    */
    continue = e => {
        e.preventDefault();
        const isContactoAgenciaValid = this.props.validateContactoAgencia();
        if (isContactoAgenciaValid ) {
            const options = {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                },
            }
            fetch(API_PATH + 'proveedor/correo_usuario?correo='+ this.props.contactoAgencia, options)
            .then(response => response.json())
            .then(result => {
                if(result.responseCode === 200){// si en responseCode arroja un 200 continua con el registro
                    this.props.nextStep();
                } else{// si no arroja un 200 en responseCode de correo_usuario
                    Swal.fire({
                        text: result.detail,
                        icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }  
            })
            .catch(error => {
                Swal.fire({
                            text: "Ocurrio un error",
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                })
                throw error;
            });
        }
    }

    render() {
      
    /**
    * Obtiene las propiedades desde form 
    * Obtiene los valores de los inputs, mensajes de error  y la funcion de handlechange para los inputs
    */   
    const { 
        handleChange, 
        contactoAgencia,
        validateContactoAgencia,
        errorMessageContactoAgencia,
        isErrorContactoAgencia,
    } = this.props;
    const { t } = this.props;

    
    return (
        <form>
            <br/><br/>
            <div className="stepper-wrapper">
                <div className="stepper-item completed active">
                    <div className="step-counter">1</div>
                    <div className="step-name">{t('TitleSteps.contact')}</div>
                </div>
                <div className="stepper-item">
                    <div className="step-counter">2</div>
                    <div className="step-name">{t('TitleSteps.datosGen')}</div>
                </div>
                <div className="stepper-item">
                    <div className="step-counter">3</div>
                    <div className="step-name">{t('TitleSteps.documents')}</div>
                </div>
            </div>
            <div className='form-group row'>
                <h1 className='intro-registro'>{t('ContactAgency.title')}</h1>
                <div className='col-md-6'>
                    <i className="fa-solid fa-envelope"></i>&nbsp;<label>{t('ContactAgency.label1')}*</label>
                    <input type='text' value={contactoAgencia} name='contactoAgencia' onChange={handleChange('contactoAgencia')} onBlur={validateContactoAgencia} className='form-control'/>
                    <p className='error'>{isErrorContactoAgencia && errorMessageContactoAgencia}</p>
                </div>
            </div>
    
            
            <br/>
            <div style={{textAlign: 'center'}}>
                <button className='buttons__button buttons__button--next' onClick={this.continue}>{t('Buttons.next')}</button>
            </div>
        </form>
    )
  }
}

export default withTranslation()(ContactoAgencia);