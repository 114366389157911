/**
 * TABLA SOLICITUDES
*/
import React from 'react';
import { withRouter} from 'react-router-dom';
import DataGrid, {
    Column,
    Pager,
    HeaderFilter,
    Scrolling,
    FilterRow
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.softblue.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';


class TablaLogInvoices extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
          }, {
            key: 'onClick',
            name: 'On Button Click'
          }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
        //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
        showEmployeeInfo: false,
        displayMode: 'compact',
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        showFilterRow: true,
        showHeaderFilter: true,
        currentFilter: this.applyFilterTypes[0].key,
        selectedItemKeys: [],
        data:[],
        loadingData: false,
        loading: true,
        currentPage: 1,
        pageSize: 10,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.dataGridRef = React.createRef();
    } 

    //Obtiene lista de solicitudes
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchData(this.state.currentPage);
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchData = async (pageIndex) => {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        let url = '';
        if (pageIndex && this.state.pageSize) {
            // Si se ha especificado una página y un tamaño de página, utilizar la URL con paginación
            url = API_PATH + `logs/invoices?page=${pageIndex}&page_size=${this.state.pageSize}`;
        } else {
            // Si no se ha especificado una página y un tamaño de página, utilizar la URL base
            url = API_PATH +'logs/invoices';
        }

        this.setState({ loadingData: true });
        try {
            const response = await axios.get(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            });
            if (!response.data || response.data.length === 0) {
                // No hay datos, deshabilitar el botón de "Siguiente"
                this.setState({
                    data: [],
                    loading: false,
                    disableNextButton: true,
                });
            } else {
                this.setState({
                    data: response.data,
                    currentPage: pageIndex,
                    loading: false,
                    disableNextButton: false,
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                title: 'Ocurrió un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            });
        } finally {
            // Establecer loadingData en false después de recibir la respuesta o en caso de error
            this.setState({ loadingData: false });
        }
    };

    goToPage = (pageIndex) => {
        this.setState({ currentPage: pageIndex }, () => this.fetchData(pageIndex));
    };

    // Function to go to the next page
    goToNextPage = () => {
        const nextPage = this.state.currentPage + 1;
        this.goToPage(nextPage);
    };

    // Function to go to the previous page
    goToPrevPage = () => {
        const prevPage = this.state.currentPage - 1;
        if (prevPage > 0) {
            this.goToPage(prevPage);
        }
    };

    // Function to handle page number input
    handlePageNumberChange = (event) => {
        const pageNumber = parseInt(event.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber > 0) {
            this.setState({ currentPage: pageNumber }, () => {
                this.fetchData(this.state.currentPage);
            });
        }
    };

    // Function to handle records per page input
    handlePageSizeChange = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        if (!isNaN(pageSize) && pageSize > 0) {
            this.setState({ pageSize: pageSize }, () => this.fetchData(this.state.currentPage));
        }
    };

    // Function to handle search value change
    handleSearchValueChange = (event) => {
        const value = event.target.value;
        if (value.length >= 3) { // Verifica si el valor tiene al menos 3 caracteres
            this.setState({ searchValue: value }, () => {
                // Llamar a fetchData cada vez que cambia el valor de búsqueda
                this.fetchData(this.state.currentPage);
            });
        } else {
            // Si el valor no tiene al menos 3 caracteres, no realizar la búsqueda
            this.setState({ searchValue: value }); // Actualiza el estado sin realizar la búsqueda
        }
    };


    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text:"Limpiar filtros ",
                },
            },

            {   //Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
        );
    }

     //ACTUALIZAR TABLA
     refreshData() {
        this.setState({
            data: []
        });
        setTimeout(() => this.fetchData(this.state.currentPage), 10);;
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
            dataGrid.instance.clearFilter();
        }
    };


  

    render() {
        const { currentPage, pageSize, loadingData} = this.state;
        const pageSizeOptions = [10, 20, 50, 100];
        //console.log(this.state.data)
        if (this.state.loading === true) {
            return (
                <div className="preloader" >
                    <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    return (
        <React.Fragment>
            {loadingData && (
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '.my-table-container' }}
                    visible={true}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
            )}

            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="ID"
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                selectedRowKeys={this.state.selectedItemKeys}
                onToolbarPreparing={this.onToolbarPreparing}
                remoteOperations={true}
                paging={false} // Desactivar la paginación de DevExtreme
                // columnWidth={160}
            >
                {/* Paginación */}
                <Pager
                        pageIndex={currentPage}
                        pageSize={pageSize}
                        onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                        onPageChanged={this.handlePageChange}
                    />
                   
                <Scrolling columnRenderingMode="virtual" />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                <FilterRow visible={true} />
                {/* Columnas*/}
                {/* Cambiar los nombre de datafield por como vengan en la api */}
                <Column dataField="name_user" caption="Nombre"/>
                    <Column dataField="email_user" caption="Correo" />
                    <Column dataField="Date_download" caption="Fecha descarga" dataType="date"/>
                    <Column dataField="Purchasing_Document" caption="Purchasing document"/>
                    <Column dataField="Invoice_date" caption="Fecha creación" dataType="date"/>
                    <Column dataField="Invoice_uuid" caption="Invoice uuid"/>
            </DataGrid>
            <br />
                <div className='row'>
                    <div className='col-md-6 col-sm-6  col-6'>
                        <select value={pageSize} onChange={this.handlePageSizeChange} className="form-select select-records" >
                            {pageSizeOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-6 col-sm-6 col-6 end'>

                        <button onClick={this.goToPrevPage} className='btn  btn-ant'><i className="fa-solid fa-chevron-left"></i></button>
                        <label className='label-page'>
                            <input
                                className='form-control bg-white center'
                                value={currentPage}
                                onChange={this.handlePageNumberChange}
                            />
                        </label>
                        <button onClick={this.goToNextPage} disabled={this.state.disableNextButton} className='btn  btn-next' ><i className="fa-solid fa-chevron-right"></i></button>
                    </div>
                </div>
                <br /><br />
        </React.Fragment>
    );
  }
}

export default withRouter (TablaLogInvoices) ;