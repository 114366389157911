/**
 * Muestra un botón que cuando se selecciona, redirigirá la página a la solicitud de inicio de sesión de microsoft
*/
import React from 'react'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { withTranslation } from 'react-i18next';

const Login = ({ t }) => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                
            });
        }
    }   
    return (
        <div className='d-grid gap-2'>
             <div className="btn btn-primary btn-bg-str" onClick={() => handleLogin("popup")}> {t('Home.label6')}</div>
        </div>
    )
}

export default withTranslation()(Login) 