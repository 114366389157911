import React, { useEffect, useState } from "react";
import { SignOutButton } from "./SignOutButton";
import {API_PATH } from '../siteConfig';
import logo from '../assets/img/ipg-logo.png'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
/**
 * Obtine información sobre el usuario obtenida de MS Graph
 * @param props 
 */

export const ProfileData = (props) => {
    // const [info] = useState({
    //     email:props.graphData.userPrincipalName,  
    // })
    const [redirect, setRedirectTo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(''); 

    const estado = sessionStorage.getItem("portalEstado")

    let apisso;
    if(estado === true){
      apisso = "sso2"
    }else{
      apisso = "sso"
    }
    /**
    * Funcion para el logueo en la api
    * le envia el token que devuelve MS Graph
    */
    useEffect(()=>{
      if(props.graphData.userPrincipalName){
        fetch(API_PATH + "login/"+ apisso,
        {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token:props.tokenValue 
      },
    })   
    .then(response => response.json())
    .then(response => {
      if(response.success === "true"){ // si es un usuario con acceso permite el redireccionamiento al home de administracion
        sessionStorage.setItem('userData', JSON.stringify(response));
        setRedirectTo(true)
        setLoading(false)
      }
      else{ // si no tiene acceso manda mensaje de error
        setErrorMessage(response.detail || '');
        setLoading(false)
      }   
    })  
    .catch(error => {
      Swal.fire({
        title: `INTERNAL SERVER ERROR`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#bb2d3b',
      })
      throw error;
    });
    }},[])  // eslint-disable-line

    if(redirect){ // si tiene acceso redirige al home para administracion, envia los datos de nombre y apellido del usuario
        return <div><SignOutButton givenName={props.graphData.givenName} surname={props.graphData.surname}/></div>
    }
    else{

        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="padre-transform">
                            <div className="loader">
                                <div className="face">
                                    <div className="circle"></div>
                                </div>
                                <div className="face">
                                    <div className="circle"></div>
                                </div>
                            </div>
                    </div>
                </div>
            );
        }
        return (
          //<div id="valid_msg"></div>//obtiene el mensaje de error
          <div>
            <div className='container-fluid'>
              <div className='row menu-principal'>
                <div className='col-md-6'>
                  <img src={logo} className="img-fluid logo" alt="logo-hci"/>
                </div>
                <div className='col-md-6 logout white'> 
                </div>
              </div>
            </div>
            
            <div className="error-user">
              <div className="container">
                <div className="row ">
                  <div className="col-sm-12 center">
                    <div className=" error-access pad-30 "><b>{errorMessage}</b></div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        ); 
      }
};