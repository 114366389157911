/**
 * TABLA ORDENES DE COMPRA VISTA ADMIN
*/

import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Button
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.softblue.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Modal from "react-bootstrap/Modal";
import {API_PATH } from '../../siteConfig';
import SubirFactura from './SubirFactura';


const allowedPageSizes = [25, 50, 75, 100, 150, 200,'all'];//configura el número de filas que se mostraran en la tabla

class TablaOrdenesCompraProveedor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
             //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            showEmployeeInfo: false,
            displayMode: 'compact',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showHeaderFilter: true,
            //Carga de datos
            data: [],
            loading: true,
            infoPortal:[],
            //Modal
            isOpen: false,
            isOpenEdit: false,
            isDataLoaded: false,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.descargarPDF = this.descargarPDF.bind(this);
        this.dataGridRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
        //   deepcode ignore ReactNextState: <please specify a reason of ignoring this>
          this.setState({ isDataLoaded: !this.props.loading });
        }
    }
   
    //OBTENER LISTA DE ORDENES DE COMPRA
    componentDidMount() {
        setTimeout(() => {
        // deepcode ignore ReactMissingCleanup: <please specify a reason of ignoring this>
        this.fetchOrdenes();
        }, 100);
        // Obtiene los datos del periodo de cierre si esta activo o no
        fetch(API_PATH + 'periodo_cierre/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then(response => {
                if (response.ok) {
                  return response.json()// si el estatus code arroja un 200  continua
                } else {// si no arroja el estatus code del error
                    Swal.fire({
                        title: DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
              
            .then((responseJson) => {
                this.setState({ infoPortal: responseJson, isDataLoaded:true});
                
            })
            .catch(error => {
                throw error;
            });
    }

    fetchOrdenes() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'get_purchase_orders/all', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        }
        })
        .then(response => {
            if (response.ok) {
              return response.json()
            } else {
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            if(responseJson.status_code === 401){
                Swal.fire({
                    title: DOMPurify.sanitize(`${responseJson.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }else{
                this.setState({ data: responseJson, loading: false });
            }          
        })
        .catch(error => {
            Swal.fire({
                title: `Ocurrio un error`,
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text:"Limpiar filtros ",
                },
            },
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
            data: []
        });
        setTimeout(() => this.fetchOrdenes(), 10);;
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
          dataGrid.instance.clearFilter();
        }
    };   


    /*ACTUALIZAR TABLA DESPUES DE SUBIR FACTURAS*/
    refreshDataGrid() {
        window.location.reload(false);
    }

    /**
    * Abre/cierra modal con formulario para subir factura
    */
     openModal = (e) => this.setState({ 
        isOpen: true ,
        dataOrden:e.row.data // obtiene la informacion por fila y se la envia al formulario para enviar facturas
    });
    closeModal = () => this.setState({ isOpen: false });

    /*
    * Descarga el ZIP
    */
    descargarZip(e){
        const Purchasing_Document = e.row.data.Purchasing_Document
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'bills_purchase_orders/DownloadFile/?Purchase_Document='+ Purchasing_Document, options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                // procesar un Blob
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.click();
                link.remove();
                
            } else {
              // procesar un JSON
             if(data){

             }
            }
        })
        .catch(error => {
            Swal.fire({
                title: 'Ocurrión un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });  
    }

    
    /*
    * Descarga el PDF
    */
    descargarPDF(e){
        const Purchasing_Document = e.row.data.Purchasing_Document
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'get_purchase_orders/download_pdf/'+ Purchasing_Document, options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                //procesar un Blob
                const url = DOMPurify.sanitize(window.URL.createObjectURL(data));
                const link = document.createElement('a');
                link.href = url;
                link.download = DOMPurify.sanitize(`${Purchasing_Document}.pdf`);
                link.click();
                link.remove();
            } else {
              // procesar un JSON
             if(data.status_code){
                Swal.fire({
                    title: DOMPurify.sanitize(`${data.detail}`),
                    icon: 'info',
                    showConfirmButton: true,
                })
             }
            }
        })
        .catch(error => {
            Swal.fire({
                title: 'Ocurrión un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });  
    }
    
    render() {
        let estadoPortal = this.state.infoPortal.success
        const { isDataLoaded } = this.state;
        if (this.state.loading === true) {
            return(
                <div className="preloader" >
                    <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
        <React.Fragment>
             <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '.my-table-container' }}
                    visible={!isDataLoaded}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
            />
        <DataGrid
            ref={this.dataGridRef}
            dataSource={this.state.data}
            keyExpr="uuid"
            showBorders={true}
            hoverStateEnabled={true}
            showColumnLines={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            allowColumnResizing={true}
            onToolbarPreparing={this.onToolbarPreparing}
            allowColumnReordering={true}
            columnAutoWidth={true}
        >
            {/* Buscador */}
            <SearchPanel visible={true}
                width={200}
                placeholder="Buscar"
            /> 
            {/* Paginación */}
            <Paging enabled={true} defaultPageSize={100}/>
            <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode={this.state.displayMode}
                showPageSizeSelector={this.state.showPageSizeSelector}
                showInfo={this.state.showInfo}
                showNavigationButtons={this.state.showNavButtons}
            />
            {/* Filtros */}
            <HeaderFilter visible={this.state.showHeaderFilter} />
            {/* Columnas, 
                Cambiar los nombre de datafield por como vengan en la api */}
                <Column dataField="Purchasing_Document"  caption="Orden de compra" />
                <Column dataField="Puch_Organization"  caption="Sociedad"/>
                <Column  dataField="Document_Date" caption="Fecha documento"/>
                <Column dataField="Purchasing_Group"  caption="Grupo de compra"/>
                <Column dataField="Currency"  caption="Moneda"/>
                <Column type="buttons"  caption="Descargar PDF" name="pdf">
                    <Button hint="Descargar PDF" icon="pdffile"  className='red' onClick={this.descargarPDF} allowHeaderFiltering={false}/>
                </Column>
                <Column type="buttons" caption="Enviar Factura" allowHeaderFiltering={false} allowHiding={false} name="Enviar Facturas">
                    <Button  hint="Upload" icon="import"  onClick={this.openModal}  />
                </Column>  
                <Column dataField="Approved_Internal"  caption="Estatus"/>
          </DataGrid>
            {/* Contiene el modal para añadir subir factura*/}
            <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton >Enviar factura</Modal.Header>
                <SubirFactura datosOrden={this.state.dataOrden} actualizar={()=>this.refreshDataGrid()} cerrar={()=>this.closeModal()} estadoPortal={estadoPortal}/> 
            </Modal>
        </React.Fragment>
        );
    }
}

export default TablaOrdenesCompraProveedor;