/**
 * INDEX PARA LA VISTA DE USUARIOS INTERNOS
*/

import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import TablaUsuarioInternos from './TablaUsuarioInternos';

class VistaAdminUsuarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            valorBuscado: 1, // admin usuarios internos
        };
    }

    componentWillMount() {
        //valida que solo los usuarios con rol de administrador tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div className="container">
                <br/>
                <br/>
                <h3 className='blue'>Administrar Usuarios de la Agencia</h3>
                <br/>
                {/* Llama al componente que muestra la tabla con los usuarios*/}
                 <TablaUsuarioInternos/> 
            </div>
        )
    }
}

export default VistaAdminUsuarios;
