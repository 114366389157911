/**
 * TABLA ALTA DE USUARIOS DE PROVEEDORES
*/
import React from 'react';
import DOMPurify from 'dompurify';
// import { Link, Redirect,  useNavigate } from "react-router-dom";
import { withRouter} from 'react-router-dom';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Button,
    Scrolling,
    ColumnChooser,
} from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.softblue.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Modal from "react-bootstrap/Modal";
import {API_PATH } from '../../siteConfig';
import ResetearPassword from './ResetearPassword';

const allowedPageSizes = [20, 40, 60, 80, 100,'all'];//configura el número de filas que se mostraran en la tabla

const urlMFAC = "https://account.activedirectory.windowsazure.com/usermanagement/multifactorverification.aspx?tenantId=69b88bc1-ac26-4ef5-91d7-8b9980382f55&culture=en-us&requestInitiatedContext=users"

class TablaAltaProveedores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
        showEmployeeInfo: false,
        displayMode: 'compact',
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        showHeaderFilter: true,
        data:[],
        loading: true,
        isDataLoaded: false,
        isOpenEdit: false,
        dataSource: {
            store: [],
            filter: ["age", ">", 25]
          }
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.activarMFA = this.activarMFA.bind(this)
        this.dataGridRef = React.createRef();
    } 
    
    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
          this.setState({ isDataLoaded: !this.props.loading });
        }
    }

    //Obtiene lista de proveedores
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchProveedores();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchProveedores(){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'proveedor/status_integrado', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
                return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then((responseJson) => {
            if(responseJson.status_code === 405){
                Swal.fire({
                    title: DOMPurify.sanitize(`${responseJson.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }else{
                this.setState({ data: responseJson, loading: false, isDataLoaded:true });
            }  
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
            { //Activar MFA
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'key',
                    onClick: () => this.activarMFA(urlMFAC),
                    text:"Activaciones de MFA",
                },
            },
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text:"Limpiar filtros ",
                },
            },
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
            data: []
        });
        setTimeout(() => this.fetchProveedores(), 10);;
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
          dataGrid.instance.clearFilter();
        }
    };   

    /*
    * Función para activar el MFA
    */
    activarMFA = (url) => {
        window.open(url, '_blank');
    }

    openModalEdit = (e) => this.setState({ 
        isOpenEdit: true ,
        dataUser:e.row.data // obtiene la informacion por fila y se la envia al formulario para editar
    });
    closeModalEdit = () => this.setState({ isOpenEdit: false });

    //ACTUALIZAR TABLA DESPUES DE EDITAR
    refreshDataGrid() {
        window.location.reload(false);
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /*
    * Función para activar los usuario en azure
    */
    activarUsuarios(e){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const uuid = e.row.data.uuid
        const options = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify ({
                uuid: uuid
            })
        }
        fetch(API_PATH + 'create_user/azure/', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
            if(result.status_code === 202){
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
                this.refreshData()
            }else{
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })  
    }

    render() {
    const { isDataLoaded } = this.state;
    return (
        <React.Fragment>
             <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '.my-table-container' }}
                    visible={!isDataLoaded}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id"
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                onToolbarPreparing={this.onToolbarPreparing}
                columnWidth={160}
            >
                 {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                <Scrolling columnRenderingMode="virtual" />
                <ColumnChooser
                    enabled={true}
                    mode="select"
                   
                />
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                <HeaderFilter visible={this.state.showHeaderFilter} />
                {/* Columnas, 
                Cambiar los nombre de datafield por como vengan en la api */}
                <Column type="buttons"  caption="Activar usuario en Azure" name="Activar">
                    <Button hint="Activar" icon="check" onClick={this.activarUsuarios.bind(this)} />
                </Column> 
                <Column type="buttons"  caption="Resetear Password" name="Resetear">
                    <Button  hint="Edit" icon="edit" onClick={this.openModalEdit}/>
                </Column>
                <Column dataField="uuid" caption="uuid"/>
                <Column dataField="razon_social" caption="Razón Social" />
                <Column dataField="rfc" caption="RFC" />
                <Column dataField="fecha_alta" dataType="date" caption="Fecha alta" visible={false}/>
                <Column dataField="fecha_modificacion" dataType="date" caption="Fecha modificacion"/>
                <Column dataField="activo" caption="Activo" /> 
                <Column dataField="email" caption="Correo" /> 
                <Column dataField="correo" caption="Correo Azure" /> 
                <Column dataField="Supplier" caption="Supplier" />
                {/* <Column dataField="passU" caption="Password"/> */}
                <Column dataField="id_usu_prov" caption="Id usuario proveedor"/>


                <Column dataField="clave_pais" caption="clave_pais" visible={false}/>
                <Column dataField="interior" caption="interior" visible={false}/>
                <Column dataField="cuenta_bancaria" caption="cuenta_bancaria" visible={false}/>
                <Column dataField="estado" caption="estado" visible={false}/>
                <Column dataField="telefono" caption="telefono" visible={false}/>
                <Column dataField="titular_cuenta" caption="titular_cuenta" visible={false}/>
                <Column dataField="cp" caption="cp" visible={false}/>
                <Column dataField="persona_fisica" caption="persona_fisica" visible={false}/>
                <Column dataField="referencia_cuenta" caption="referencia_cuenta" visible={false}/>
                <Column dataField="nombre_administrador" caption="nombre_administrador" visible={false}/>
                <Column dataField="ciudad" caption="ciudad" visible={false}/>
                <Column dataField="swif_cuenta" caption="swif_cuenta" visible={false}/>
                <Column dataField="puesto_administrador" caption="puesto_administrador" visible={false}/>
                <Column dataField="delegacion" caption="delegacion" visible={false}/>
                <Column dataField="aba_cuenta" caption="aba_cuenta" visible={false}/>
                <Column dataField="contacto_agencia" caption="contacto_agencia" visible={false}/>
                <Column dataField="colonia" caption="colonia" visible={false}/>
                <Column dataField="pais_cuenta" caption="pais_cuenta" visible={false}/>
                <Column dataField="estatus" caption="estatus" visible={false}/>
                <Column dataField="IBAN" caption="IBAN" visible={false}/>
                <Column dataField="calle" caption="calle" visible={false}/>
                <Column dataField="clabe_bancaria" caption="clabe_bancaria" visible={false}/>
                <Column dataField="Swift_banco_intermediario" caption="Swift_banco_intermediario" visible={false}/>
                <Column dataField="Banco_intermediario" caption="Banco_intermediario" visible={false}/>
                <Column dataField="extranjero" caption="extranjero" visible={false}/>
                <Column dataField="numero" caption="numero" visible={false}/>
                <Column dataField="extension" caption="extension" visible={false}/>
                <Column dataField="ABA_banco_intermediario" caption="ABA_banco_intermediario" visible={false}/>
                <Column dataField="Vendor_Status_Descr" caption="Vendor_Status_Descr" visible={false}/>
                <Column dataField="id" caption="id" visible={false}/>
            </DataGrid>
            <Modal show={this.state.isOpenEdit} onHide={this.closeModalEdit}  dialogClassName="modal-90w">
                <Modal.Header closeButton >Resetear Password</Modal.Header>
                <ResetearPassword datosUsuario={this.state.dataUser} actualizar={()=>this.refreshDataGrid()}/>
            </Modal>
        </React.Fragment>
    );
  }
}

export default withRouter(TablaAltaProveedores) ;