import React, {Component} from 'react';
import {Redirect } from 'react-router-dom';

class NotFoundAdmin extends Component {
  render() {
    return (
    <Redirect to="/" />
    );
  }
}

export default NotFoundAdmin;