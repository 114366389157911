/**
 * Index para el formulario en bloques, 
 * manda a llamar a cada componente del formulario
 * continen las funciones de validaciones de cada input 
 * envia la informacion mediente propiedades a su respectivo componente
 */
import React, { Component } from 'react';
import {API_PATH} from '../../siteConfig';
import 'sweetalert2/src/sweetalert2.scss'
import { withTranslation } from 'react-i18next';
import DatosGenerales from './DatosGenerales';
import Documentos from './Documentos';
import ContactoAgencia from './ContactoAgencia';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import ModalRegistroProveedor from '../RegistroProveedor/ModalPreregistro';

class Form extends Component {
  state = {
    step: 1,
    isModalOpen: false, // Nuevo estado para controlar el modal
    tipoProveedor: '',
    errores: {},
  //DATOS CONTACTO AGENCIA
    contactoAgencia:'', 
    isErrorContactoAgencia: true,
    errorMessageContactoAgencia:'',
    tokenRecaptcha: "",
    puntuacionRecaptcha: 0,
    claveRecaptcha: "6LfUMMMlAAAAAFtJY1M4qQ-eKzFulSvfMN697yVP",
    urlApiRecaptcha: "https://www.google.com/recaptcha/api/siteverify"
  };
  constructor(props) {
    super(props);
    this.validateInputs = this.validateInputs.bind(this);
    this.enviarSolicitudRecaptcha = this.enviarSolicitudRecaptcha.bind(this);
  }

  handleToken = async (token) => {
    this.setState({ token });
  };

  /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
  */
  convertObjectToJsonString(obj) {
    let jsonString = '{';
    let isFirst = true;
    for (const [key, value] of Object.entries(obj)) {
      if (!isFirst) {
        jsonString += ',';
    }
    jsonString += `"${key}":`;
    if (typeof value === 'object') {
        jsonString += this.convertObjectToJsonString(value);
    } else if (typeof value === 'string') {
        jsonString += `"${value}"`;
    } else {
        jsonString += value;
    }
    isFirst = false;
    }
    jsonString += '}';
    return jsonString;
  }


  handleOpcionChange = (e) => {
    this.setState({ tipoProveedor: e.target.value });
  }

  /**
   * FUNCION PARA VALIDAR EL TOKEN DEL CAPTCHA EN LA API DE GOOGLE
   */
  enviarSolicitudRecaptcha(event) {
    event.preventDefault();
    const dataBody = {
      // deepcode ignore HardcodedNonCryptoSecret: <please specify a reason of ignoring this>
      secret: this.state.claveRecaptcha,
      response:this.state.token
    };
    const jsonString = this.convertObjectToJsonString(dataBody);
    const options = {
      mode: 'no-cors',
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
      },
      body: jsonString
    } 
    fetch("https://www.google.com/recaptcha/api/siteverify", options)
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // El token de Recaptcha es válido
      } else {
        // El token de Recaptcha no es válido
      }
    })
    .catch(error => console.error(error));
  }

  /**
   * FUNCION PARA PASAR AL SIGUIENTE BLOQUE
  */
  nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1
    }));
  }

  /**
   * FUNCION PARA REGRESAR AL BLOQUE ANTERIOR
   */
  prevStep = () => {
    this.setState(prevState => ({
      step: prevState.step - 1
    }));
  }

  componentDidMount() {
    sessionStorage.removeItem('uploadedFiles');
    sessionStorage.removeItem('uploadedNewFiles');
    sessionStorage.removeItem('uploadedNewFilesID');
    const recaptchaCompletado = localStorage.getItem("recaptchaCompletado") === "true";
    this.setState({ recaptchaCompletado });
  }

  componentDidUpdate() {
    // Guardar el estado del recaptcha en la memoria del navegador
    localStorage.setItem("recaptchaCompletado", this.state.recaptchaCompletado.toString());
  }

  /**
   * VALIDA EN EL ONCHANGE QUE NO QUEDE VACIÓ EL CAMPO (SI ES OBLIQGATORIO)
  */
  validateInputs(input) {  
    if (input === 'contactoAgencia') {
      if (this.state.contactoAgencia.length >= 1) {
        this.setState({
          isErrorContactoAgencia: false
        })
      }
    }
  }

  /**
   * Actualiza el valor de input 
   * Llama a la función de validarinputs en el onchange
  */
  handleChange = input => e => {
    this.setState({
      [input]: e.target.value
    },()=>
        {
          this.validateInputs(input)
        }
    );
  }

  validateContactoAgencia = () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(this.state.contactoAgencia) === false) {
      this.setState({
        isErrorContactoAgencia: true,
        errorMessageContactoAgencia: `${this.props.t('ContactAgency.error1')}`
      });
      return false;
    }
    return true;
  }

  
  /**
   * FUNCIION PARA GUARDAR LOS DATOS DEL NUEVO PROVEEDOR 
  */
  submitData = async () => {
  try {
    let arrayFiles = sessionStorage.getItem("uploadedFiles");
    let filesParse = JSON.parse(arrayFiles);
    const objeto = {};

    filesParse.forEach((archivo, index) => {
      objeto[index] = archivo;
    });

    let tipoProv
        if( this.state.tipoProveedor ==="moral"){ //MORAL
            tipoProv=2
        }else if( this.state.tipoProveedor ==="fisica"){ //FISICA
            tipoProv =1
        }else if( this.state.tipoProveedor === "extranjero"){//EXTRANJERO
            tipoProv = 4
        }


    const dataBody = {
      contacto_agencia: this.state.contactoAgencia,
      archivos: objeto,
      tipoProveedor:1 ,
      tipoPersona:tipoProv
    };

    const jsonString = this.convertObjectToJsonString(dataBody);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonString,
    };

    const response = await fetch(API_PATH + 'proveedor/', options);
    const result = await response.json();

    if (response.ok) {
      return result; // Devuelve la respuesta si la llamada fue exitosa
    } else {
      throw new Error(result.detail || 'Error desconocido'); // Lanza un error si la llamada fue fallida
    }
  } catch (error) {
    throw error; // Propaga el error
  }
};

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      step,
      tipoProveedor,
      errores,
      // DATOS CONTACTO AGENCIA
      contactoAgencia,
      isErrorContactoAgencia,
      errorMessageContactoAgencia,
      isModalOpen
    } = this.state;
    /**
     * CONFIGURACION DE CADA COMPOMENTE 
     * SE LE ENVIAN LAS PROPIEDADES DE CADA INPUT, VALIDACIONES Y MENSAJES DE ERROR
     * SE LE ENVIA LAS FUNCIONES DE SIGUIENTE O ATRAS RESPECTIVAMENTE
     * SE ENVÍA LA FUNCIÓN DE GUARDADO
    */
    return(
      <>
       <GoogleReCaptchaProvider reCaptchaKey="6LfUMMMlAAAAAKqadCI6954J9akIwGiTzU-sqn8A">
        {step === 2 && (
          <GoogleReCaptcha onVerify={this.handleToken}/>
        )}

        {step === 1 && isModalOpen && (
          <ModalRegistroProveedor isOpen={isModalOpen} closeModal={this.closeModal} />
        )}

        {step === 1 && (
          <ContactoAgencia
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          contactoAgencia={contactoAgencia}
          isErrorContactoAgencia={isErrorContactoAgencia}
          errorMessageContactoAgencia={errorMessageContactoAgencia}
          validateContactoAgencia={this.validateContactoAgencia}
        />
        )}

        {step === 2 && (
          <DatosGenerales 
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          tipoProveedor ={tipoProveedor}
          handleOpcionChange ={this.handleOpcionChange}
          errores={errores}
        /> 
        )}

        {step === 3 && (
          <Documentos 
            tipoProveedor ={tipoProveedor}
            prevStep={this.prevStep}
            contactoAgencia={contactoAgencia} 
            submitData={this.submitData} 
          />
        )}

      </GoogleReCaptchaProvider>
      <br/><br/><br/><br/><br/><br/><br/>
      </>
    )
    
  }
}

export default withTranslation()(Form);
