/**
 * TABLA USUARIOS NULL
*/
import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';

;
//configura el número de filas que se mostraran en la tabla
const allowedPageSizes = [10, 15, 20, 25, 30, 35, 40, 45, 50,'all'];

class TablaUsersNull extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
          }, {
            key: 'onClick',
            name: 'On Button Click'
          }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
        //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
        showEmployeeInfo: false,
        displayMode: 'compact',
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        showFilterRow: true,
        showHeaderFilter: true,
        currentFilter: this.applyFilterTypes[0].key,
        selectedItemKeys: [],
        data:[],
        loading: true,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.dataGridRef = React.createRef();
        this.sincronizarUsuariosDB = this.sincronizarUsuariosDB.bind(this);
        this.estatusProveedoresDB = this.estatusProveedoresDB.bind(this);
    } 

    //Obtiene lista de periodos/fechas
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchNull();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchNull() { 
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'vendor_master/obtener_nulos', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error

                Swal.fire({
                    title: DOMPurify.sanitize(`${response.statusText}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {   //Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            }, 
            
            { 
                location: 'after',
                widget: 'dxButton',
                options: {
                    onClick: this.sincronizarUsuariosDB.bind(this),
                    text:" Registrar proveedores",
                    icon: 'check',
                },
            },
            // { 
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         onClick: this.estatusProveedoresDB.bind(this),
            //         text:" Obtener usuarios no sincronizados",
            //         // icon: 'check',
            //     },
            // },
           
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
        data: []
        });
        setTimeout(() => this.fetchNull(), 10);;
    }

    /**
    * sincronizarUsuariosDB
    */

    sincronizarUsuariosDB(e){
        // e.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'Massive_Register_Of_Supplier/Register_Suppliers_User_Not_Linked_With_DB', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
              return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
        })
        .catch(error => {
            throw error;
        });
    }

    /**
    * estatusProveedoresDB
    */

    estatusProveedoresDB(e){
        // e.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'Massive_Register_Of_Supplier/Get_Suppliers_User_Not_Linked_With_BD', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
              return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
        })
        .catch(error => {
            throw error;
        });
    }


    render() {
    if (this.state.loading === true) {
        return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id_usu_prov"
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                selectedRowKeys={this.state.selectedItemKeys}
                onToolbarPreparing={this.onToolbarPreparing}
                onEditorPreparing={this.onEditorPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onSaving={this.onSaving}  
            >
                {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* <Selection mode="multiple" /> */}
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                
                {/* Columnas*/}
                {/* Cambiar los nombre de datafield por como vengan en la api */}
                <Column dataField="Name" caption="Razón Social" />
                <Column dataField="Supplier" caption="Supplier"  />
            </DataGrid>
        </React.Fragment>
    );
  }

     
}

export default TablaUsersNull;