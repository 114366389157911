import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default class SubirFactura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
    this.handleSubmit= this.handleSubmit.bind(this)
  }

  validarArchivos = () => {
    const Country = this.props.datosOrden.Country.trim();
    if(Country === 'MX'){
      const fileInput1 = document.getElementById('file');
      const fileInput2 = document.getElementById('file2');
      const archivo1 = fileInput1.files[0];
      const archivo2 = fileInput2.files[0];
      const esXML = archivo1 && archivo1.name.toLowerCase().endsWith('.xml');
      const esPDF = archivo2 && archivo2.name.toLowerCase().endsWith('.pdf');
      if (!esXML) {
        document.getElementById('valid_msg').innerHTML = 'Por favor, suba un archivo XML.';
        return false;
      } else {
        document.getElementById('valid_msg').innerHTML = '';
      }

      if (!esPDF) {
        document.getElementById('valid_msg2').innerHTML = 'Por favor, suba un archivo PDF.';
        return false;
      } else {
        document.getElementById('valid_msg2').innerHTML = '';
      }
      return true;
    }else{
      const fileInput3 = document.getElementById('file3');
      const archivo3 = fileInput3.files[0];
      const esPDF = archivo3 && archivo3.name.toLowerCase().endsWith('.pdf');
      if (!esPDF) {
        document.getElementById('valid_msg3').innerHTML = 'Por favor, suba un archivo PDF.';
        return false;
      } else {
        document.getElementById('valid_msg3').innerHTML = '';
      }
      return true;
    }
  };

  handleSubmit(event){
    event.preventDefault(); 
    if (this.validarArchivos()) {
    this.setState({ isLoading: true }); // Activa el indicador de carga
    const data = JSON.parse(sessionStorage.getItem('userData'));
    const token = data.AccessToken  
    const idOrden = this.props.datosOrden.Purchasing_Document 
    const Country = this.props.datosOrden.Country.trim();
    let url = '';
    
    const formData = new FormData();
    if(Country === 'MX'){
      const filePDF= document.getElementById('file2').files[0];
      const fileXML = document.getElementById('file').files[0];
      url = API_PATH + 'invoices_purchase_orders/uploadfile?Purchase_Document='+ idOrden;
      formData.append('file_XML', fileXML);
      formData.append('file_PDF', filePDF);
    }else{
      const filePDF= document.getElementById('file3').files[0];
      url = API_PATH + 'invoices_purchase_orders/uploadfile2?Purchase_Document='+ idOrden;
      formData.append('file_PDF', filePDF);
      formData.append('file_XML', null);
    }
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      }
    })
    .then(result => {
      if(result.data.status_code !== 500){
      this.props.cerrar();
        Swal.fire({
          text: DOMPurify.sanitize(`${result.data.detail}`),
          icon: 'info',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.actualizar();
          }
        })
        // setTimeout(() => {
        //    this.props.actualizar();
        // }, 6000);
      }else if(result.data.status_code === 500){
          Swal.fire({
            text: DOMPurify.sanitize(`${result.data.detail}`),
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#bb2d3b',
          })
        }
      })
    .catch(error => error)
    .finally(() => {
      this.setState({ isLoading: false }); // Desactiva el indicador de carga
    });}
    else {
      console.log('Archivos inválidos, no se enviarán.');
    }
  }

  subirDocs(){
    const Country = this.props.datosOrden.Country.trim();
    if(Country === 'MX'){
    return(
      <div className='container'>
        <form>
        <div className="col-md-12 form-group files">
          <p>XML</p>
          <input type="file" id="file" name="myFile"  className="form-control" accept=".xml"/>
          <div  className='error' id="valid_msg" /> 
        </div>
        <br/>
        <div className="col-md-12 form-group files">
          <p>PDF</p>
          <input className="form-control" type="file" id="file2" name="myFile2" accept=".pdf" />
          <div  className='error' id="valid_msg2" />
        </div>
        <br/>
        <div className='col-md-12 right'>
            <button className='btn btn-primary btn-bg-str' onClick={this.handleSubmit} disabled={this.state.isLoading}>{this.state.isLoading ? 'Cargando archivos...' : 'Enviar'}</button>
        </div>
        <br/>
      </form>
      </div>
    )
    }else{
      return(
        <div className='container'>
          <form>
            <div className="col-md-12 form-group files">
              <p>PDF</p>
              <input className="form-control" type="file" id="file3" name="myFile3"  accept=".pdf"/>
              <div  className='error' id="valid_msg3" />
            </div>
            <br/>
            <div className='col-md-12 right'>
                <button className='btn btn-primary btn-bg-str' onClick={this.handleSubmit} disabled={this.state.isLoading}>{this.state.isLoading ? 'Cargando archivos...' : 'Enviar'}</button>
            </div>
            <br/>
          </form>
        </div>
      )
    }
  }
  
  render() {
    let estadoPortal = this.props.estadoPortal
    let isVisible = ( estadoPortal === true) ? false : true;
    let warning = ( estadoPortal === true) ? true : false;
    return (
      <div>
        <br/>
        {isVisible ? (
            <>{this.subirDocs()}</>
        ) : null}
        {warning ? (
          <div className='pad-30'> 
            <div className="alert alert-danger d-flex align-items-center" role="alert">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div>
                Por el momento no puedes subir facturas
              </div>
            </div>
          </div>
        ) : null} 
      </div>
    ) 
  }
}