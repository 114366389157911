/**
*/

import React from 'react';
import DOMPurify from 'dompurify';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import { withRouter } from 'react-router-dom';

class InformacionLoginEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            data: [],
            isClearable: true,
            hasSelection: true,
            isEditing: false,
            valorBuscado: 18, //seguridad
            redirectToReferrer: false,
            errors: {},
            input: {},
            isVisible:true,
            notVisible:false,        
        };

    }

    componentWillMount() {
        //valida que solo los usuarios con rol de administrador tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    /**
    * Obtiene los datos de azure para sso
    */
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchInfoLogin();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchInfoLogin() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken

        fetch(API_PATH + 'proveedor/datos?id_proveedor=2488' , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()
            } else {
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [e.target.name]: e.target.value
            }
        }));
    }

    handleEditClick = () => {
        this.setState({ isEditing: true });
    }

    handleCancelClick = () => {
        this.setState({ isEditing: false });
    }

    /**
    * Validación de inputs
    */
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        function validarVacios(campo, valor) {
            var patternVacio = /^.+$/;
            if (!patternVacio.test(valor)) {
              isValid = false;
              errors[campo] = "El campo no puede estar vacío";
            }
        }

        if (typeof input["clientId"] !== "undefined") {
            validarVacios("clientId", input["clientId"]);
        }

        if (typeof input["authority"] !== "undefined") {
            validarVacios("authority", input["authority"]);
        }

        if (typeof input["redirectUri"] !== "undefined") {
            validarVacios("redirectUri", input["redirectUri"]);
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }
    
    /**
    * Función para guardar los datos editados
    */
   
    handleSaveClick = (event) => {
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken

        if(this.validate()){ // primero valida que los campos sean válidos
            let input = {};
            input["clientId"] = "";
            input["authority"] = "";
            input["redirectUri"] = "";

            this.setState({input:input});

            const dataBody = {
                clientId:this.state.input.clientId,
                authority:this.state.input.authority,
                redirectUri: this.state.input.redirectUri,
            };
    
            const jsonString = this.convertObjectToJsonString(dataBody);

            const options = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: jsonString
            }
            fetch(API_PATH + 'proveedor/seguridad', options)
            .then(response => {
                if (response.ok) {// si el estatus code arroja un 200  continua
                    return response.json()
                } else {// si no arroja el estatus code del error
                    Swal.fire({
                        title:  DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
            .then(result => {
                if(result.responseCode === 200 ){// si en responseCode arroja un 200  manda mensaje de exito 
                    Swal.fire({
                        text:  DOMPurify.sanitize(`${result.detail}`),
                        showCancelButton: false,
                        showConfirmButton: false,
                        icon: "success",
                        timer: 3000
                    })
                    this.setState({ isEditing: false })
                    setTimeout(function () {
                        window.location.href = "/seguridad";
                    },  1000);
                } else{// si arroja un 200 en responseCode 
                    Swal.fire({
                        text:  DOMPurify.sanitize(`${result.detail}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                    this.setState({ isEditing: true })
                    }  
                })
            .catch(error => {
                this.setState({ isEditing: true })
                Swal.fire({
                            text: "Ocurrio un error",
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                })
                throw error;
            });
        } 
    }

   
    render() {
        const { valorBuscado,  isEditing } = this.state;

        const data = JSON.parse(sessionStorage.getItem('userData'));

        //verifica si tiene el rol de seguridad  (18)
        const roles = data.roles;
        const contieneValor = roles.includes(valorBuscado);
        let editar = (contieneValor === true) ? 1 :0

        // if (this.state.redirectToReferrer) {
        //     return (<Redirect to={'/'}/>)
        // }
        return (
            <div>
                <br/><br/>              
                {this.state.data.map((login, i )=> {
                    return(
                        <div className="container info-proveedores " key={i}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Login</h2><br/>
                                </div>
                                {/* {editar === 1 && !isEditing && (
                                    <div className='col-md-12 btns-edit'>
                                    <button onClick={this.handleEditClick} className='btn btn-primary btn-bg'><i className="fa-solid fa-pen-to-square white"></i> Editar datos</button>
                                    </div>
                                )}       */}
                            </div>
                        
                            {/* Verifica que tenga los permisos para editar*/}
                            {isEditing  && editar === 1 ? (
                                <><br/><br/>
                                    <div className='row'>

                                        <div className='col-md-4' key={i}>
                                            <b>clientId:</b><br/>
                                            <input
                                                type="text"
                                                name="clientId"
                                                id="clientId"
                                                className='form-control'
                                                // value={this.state.input.clientId}
                                                defaultValue={login.detail.clientId}
                                                onChange={this.handleChange} 
                                            />
                                           <p className='error'>{this.state.errors.clientId}</p>
                                        </div>

                                        <div className='col-md-4'>
                                            <b>authority:</b><br/>
                                            <input
                                                type="text"
                                                name="authority"
                                                id="authority"
                                                className='form-control'
                                                // value={this.state.input.authority}
                                                defaultValue={login.detail.authority_cuenta}
                                                onChange={this.handleChange}   
                                                                      
                                            />
                                            <p className='error'>{this.state.errors.authority}</p>
                                        </div>

                                        <div className='col-md-4'>
                                            <b>redirectUri:</b><br/>
                                            <input
                                                    type="text"
                                                    name="redirectUri"
                                                    id="redirectUri"
                                                    className='form-control'
                                                    // value={this.state.input.redirectUri}
                                                    defaultValue={login.detail.puesto_administrador}
                                                    onChange={this.handleChange}  
                                                                            
                                            />
                                            <p className='error'>{this.state.errors.redirectUri}</p>
                                        </div>

                                    </div>

                                    <br/>
                                    {isEditing  && editar === 1? (
                                    <>
                                        <div className='btns-edit'>
                                            <button onClick={this.handleCancelClick} className='btn btn-primary btn-red'>Cancelar</button>&nbsp;
                                            <button onClick={this.handleSaveClick} className='btn btn-primary btn-green'>Guardar</button>
                                        </div>
                                    </>
                                    ) : null}  
                                    </>
                                    ) : (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <b>clientId: </b> 532568d4-b8b9-41aa-a086-203e81f469cf<br/>
                                                <b>authority:</b> organizations<br/>
                                                <b>redirectUri:</b> https://ipgproveedores.com.mx<br/>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    })}
            <br/><br/><br/><br/><br/>
        </div> 
        )
    }
}

export default withRouter (InformacionLoginEdit);
