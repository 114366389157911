/**
 * MUESTRA LA INFORMACIÓN DEL PROVEEDOR 
 * CONTIENE LA OPCIÓN DE DESCARGAR ARCHIVOS
 * CONTIENE LAS ACCIONES QUE PODRÁ REALIZAR SEGUN EL ROL
*/

import React from 'react';
import DOMPurify from 'dompurify';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import { withRouter } from 'react-router-dom';
import Select from 'react-select'

//Configuración para mostrar de colores las opciones de las acciones según los colores que mande la api
const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
        color: 'white',
      };
    },
    control: (styles, { selectProps }) => {
      const { isDisabled } = selectProps;
      return {
        ...styles,
        backgroundColor: isDisabled? '#f2f2f2' : '#fff',
        // Aplica un estilo diferente si el componente está deshabilitado
        //opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
};
  
class InformacionProveedor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            data: [],
            historial:'',
            historialList:[],
            acciones:null,
            accionesList: [],
            isClearable: true,
            hasSelection: true,
            redirectToReferrer: false,
            valorBuscadoVer: 8, //visualizar proveedor
            loading: true,
            //DESCARGA DE ARCHIVOS
            archivoID: null,
            isDownloading: false,
            downloadProgress: 0,
            //AÑADIR INFORMACION SERVICIOS PROVEEDOR
            compania:null,
            companiaList: [],

            documents: '',
            isCheckedCC: false,
            alMenosUnoSeleccionado: false,
            errores: {},
            //EDICION DE DATOS PROVEEDOR
            isEditing: false,
            //EDICION SERVICIOS PROVEEDOR
            editable: false,
      
            paisCuenta: " ",
            paisCuentaList: [],
            selectedValue: '', // Variable para almacenar el valor seleccionado

            selectValueCompany:'',
            userData: {
                razon_social: '',
                rfc: '',
                cp:'',
                ciudad:'',
                direccion:'',
                telefono:'',
                email:'',
                pais_cuenta:'',
                clabe_bancaria:'',
                cuenta_bancaria:'',
                titular_cuenta:'',
                referencia_cuenta:'',
                swif_cuenta:'',
                aba_cuenta:'',
                IBAN:'',
                Banco_intermediario:'',
                Swift_banco_intermediario:'',
                ABA_banco_intermediario:'',
                clave_pais:'',
                fecha_alta:'',
                nombre_administrador:'',
                usuario_sistemas:'',
                contacto_agencia:'',
                idSupplierVantage:'',

                corresponde_a:'',
                que:'',
                cuando:'',
                monto:'',
                lugar:'',
                donde:'',
                observaciones:'',
                notas: '',

                status:'',
                estatus:'',
                tipo_p:'',
                archivos: [],
            },
            originalData: {},
            archivos: [],


            xTagValue: ''
        };
        this.accionesProveedor= this.accionesProveedor.bind(this)
        this.actualizarInfoServicios= this.actualizarInfoServicios.bind(this)
        this.downloadFiles= this.downloadFiles.bind(this)   
        this.editarInformacion= this.editarInformacion.bind(this)  
    }

    componentWillMount() {
        //valida que solo los usuarios con rol de administrador tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscadoVer } = this.state;
        const contieneValor = roles.includes(valorBuscadoVer);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    /**
    * Obtiene los datos del proveedor
    * Otiene las acciones que puede realizar el proveedor
    */
    componentDidMount() {
            this.fetchInfoProveedor()
            .then(() => {
                const data = JSON.parse(sessionStorage.getItem('userData'));
                const token = data.AccessToken;
                const idProveedor = this.props.location.state.id || this.props.match.params.id;
                const estado =this.props.location.state.estatus || this.props.match.params.estatus;
                
                if (estado !== 1 && estado !== 11) {
                    this.setState({
                        editable: false,
                        
                    });
                } else {
                    this.setState({
                      editable: true,
                    });
                }
    
                const accionesPromise = fetch(API_PATH + 'usuarios_acciones/?estatus=' +estado, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error ${response.status}`);
                    }
                    return response.json();
                });
    
                const historialPromise = fetch(API_PATH + 'proveedor_workflow/comentarios?id_proveedor=' + idProveedor, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error ${response.status}`);
                    }
                    return response.json();
                });
    
                const companiasPromise = fetch(API_PATH + 'companias/', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error ${response.status}`);
                    }
                    return response.json();
                });
    
                const paisesPromise = fetch(API_PATH + 'paises/', {
                    headers: { 'Content-Type': 'application/json' },
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error ${response.status}`);
                    }
                    return response.json();
                });
    
                return Promise.all([accionesPromise, historialPromise, companiasPromise, paisesPromise]);
            })
            .then(([accionesData, historialData, companiaData, paisData]) => {
                this.setState({
                    accionesList: accionesData,
                    historialList: historialData,
                    companiaList: companiaData,
                    paisCuentaList: paisData,
                    loading: false,
                });
            })
            .catch(error => {
                console.error('Error al cargar datos:', error);
                // Manejar el error adecuadamente
            });
    }

    /**
    * Obtener la información del proveedor
    */
        fetchInfoProveedor() {
           
            const data = JSON.parse(sessionStorage.getItem('userData'));
            const token = data.AccessToken
            const idProveedor = this.props.location.state.id || this.props.match.params.id;
    
            return fetch(API_PATH + 'proveedor/datos?id_proveedor='+ idProveedor , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            })
            .then(response => {
                if (response.ok) {
                  return response.json()
                } else {
                    Swal.fire({
                        title: DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
            .then((data) => {
                const detailData = data[0]?.detail || {}; // Asegúrate de manejar el caso en el que el arreglo esté vacío
                const selectedData = {
                    pais_cuenta: detailData.pais_cuenta || '',
                    clave_pais: detailData.clave_pais || '',
                    razon_social: detailData.razon_social || '',
                    rfc: detailData.rfc || '',
                    email: detailData.email || '',
                    cp:detailData.cp||'',
                    ciudad:detailData.ciudad ||'',
                    direccion:detailData.direccion ||'',
                    telefono:detailData.telefono ||'',
                    clabe_bancaria:detailData.clabe_bancaria ||'',
                    cuenta_bancaria:detailData.cuenta_bancaria ||'',
                    titular_cuenta:detailData.titular_cuenta ||'',
                    referencia_cuenta:detailData.referencia_cuenta ||'',
                    swif_cuenta:detailData.swif_cuenta ||'',
                    aba_cuenta:detailData.aba_cuenta ||'',
                    IBAN:detailData.IBAN ||'',
                    Banco_intermediario:detailData.Banco_intermediario ||'',
                    Swift_banco_intermediario:detailData.Swift_banco_intermediario ||'',
                    ABA_banco_intermediario:detailData.ABA_banco_intermediario ||'',
                    fecha_alta:detailData.fecha_alta ||'',
                    nombre_administrador:detailData.nombre_administrador ||'',
                    usuario_sistemas:detailData.usuario_sistemas ||'',
                    contacto_agencia:detailData.contacto_agencia ||'',
                    idSupplierVantage:detailData.idSupplierVantage ||'',
                    corresponde_a:detailData.corresponde_a ||'',
                    que:detailData.que ||'',
                    cuando:detailData.cuando ||'',
                    lugar:detailData.lugar ||'',
                    donde:detailData.donde ||'',
                    observaciones:detailData.observaciones ||'',
                    status:detailData.status ||'',
                    monto:detailData.monto ||'',
                    tipo_p:detailData.tipo_p||'',
                    estatus:detailData.estatus ||'',
                };
                if (data.length > 0 && data[0].success && data[0].archivos) {
                    // Obtener la lista de archivos
                    const archivosData = data[0].archivos;
    
                    // Actualizar el estado con la lista de archivos
                    this.setState({ archivos: archivosData });
                } else {
                    console.error("La respuesta de la API no contiene archivos o no tuvo éxito.");
                }
                this.setState({
                  userData: { ...selectedData },
                  originalData: { ...selectedData },
                loading: false
                });
            })
            .catch(error => {
                throw error;
            });
        }
    

    /**
    * Maneja los cambios que se realizan en los inputs
    */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSelectChange = (name, selectedOption) => {
        this.setState({ [name]: selectedOption });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        // Actualiza solo el campo editado
        this.setState((prevState) => ({
          userData: {
            ...prevState.userData,
            [name]: value,
          },
        }));
    };

    handleEditClick = () => {
        this.setState({ isEditing: true });
    }

    handleCancelClick = () => {
        this.setState({ isEditing: false });
    }

    handleCheckboxChange = (archivoID) => {
        if (archivoID === 'ningunDocumento') {
            // Si se selecciona 'Ningún documento', deseleccionar todos los otros documentos
            const archivosActualizados = this.state.archivos.map(archivo => ({
                ...archivo,
                selected: false
            }));
    
            this.setState({
                isCheckedCC: true, // Marcar el checkbox "Ningún documento"
                archivos: archivosActualizados,
                alMenosUnoSeleccionado: false // Al seleccionar "Ningún documento", ningún otro archivo está seleccionado
            });
        } else {
            // Si se selecciona un documento individual
            const nuevosArchivos = this.state.archivos.map(archivo => 
                archivo.archivoID === 'ningunDocumento'  // Si se selecciona 'Ningún documento', no se seleccionan otros archivos
                    ? archivo
                    : archivo.archivoID === archivoID 
                        ? {...archivo, selected: !archivo.selected} 
                        : archivo
            );
    
            const alMenosUnoSeleccionado = nuevosArchivos.some(archivo => archivo.selected); // Verificar si al menos uno está seleccionado
            const isCheckedCC = false; // Desmarcar el checkbox "Ningún documento" si se selecciona otro archivo
    
            this.setState({
                archivos: nuevosArchivos,
                alMenosUnoSeleccionado,
                isCheckedCC
            });
        }
    }




    /**
    * Función para guardar los datos editados
    */
   
    editarInformacion = async (event) => {
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const idProveedor = this.props.location.state.id || this.props.match.params.id;
        const countryAcount = sessionStorage.getItem("pais_cuenta")
        const countrySelect=( this.state.selectedValue === "") ? countryAcount : this.state.selectedValue;
        const errores = {};
        
        const options = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body:  JSON.stringify(
                {
                    
                        cp: this.state.userData.cp,
                        direccion: this.state.userData.direccion,
                        ciudad: this.state.userData.ciudad,
                        telefono: this.state.userData.telefono,
                        email: this.state.userData.email,
                        pais_cuenta: countrySelect,
                        clabe_bancaria: this.state.userData.clabe_bancaria,
                        cuenta_bancaria: this.state.userData.cuenta_bancaria,
                        titular_cuenta: this.state.userData.titular_cuenta,
                        referencia_cuenta: this.state.userData.referencia_cuenta,
                        swif_cuenta: this.state.userData.swif_cuenta,
                        aba_cuenta: this.state.userData.aba_cuenta,
                        IBAN: this.state.userData.IBAN,
                        Banco_intermediario: this.state.userData.Banco_intermediario,
                        Swift_banco_intermediario: this.state.userData.Swift_banco_intermediario,
                        ABA_banco_intermediario: this.state.userData.ABA_banco_intermediario,
                        id_proveedor: idProveedor,
                    
                }
            ) 
        }

        if (!this.state.userData.cp) {
            errores.cp = 'El campo es obligatorio.';
        }
        if (!this.state.userData.ciudad) {
            errores.ciudad = 'El campo es obligatorio.';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.state.userData.email) {
            errores.email = 'El campo es obligatorio.';
        }else if (!emailRegex.test(this.state.userData.email)) {
            errores.email = 'El correo electrónico no es válido.';
        }
        
        if (!this.state.userData.direccion) {
            errores.direccion = 'El campo es obligatorio.';
        }
        if (!this.state.userData.cuenta_bancaria) {
            errores.cuenta_bancaria = 'El campo es obligatorio.';
        }
        if (!this.state.userData.referencia_cuenta) {
            errores.referencia_cuenta = 'El campo es obligatorio.';
        }

        if (Object.keys(errores).length === 0) {
            Swal.fire({
                title: 'Guardando...',
                text: 'Por favor, espera mientras se guarda la información.',
                icon: 'info',
                showConfirmButton: false,
            });
            fetch(API_PATH + 'proveedor/update', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                        return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
            .then(result => {
                Swal.close();
                if(result.success === true ){// si en responseCode arroja true  manda mensaje de exito 
                    Swal.fire({
                            text: DOMPurify.sanitize(`${result.detail}`),
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: "success",
                            timer: 8000
                    })
                    this.setState({ isEditing: false })
                        setTimeout(function () {
                            window.location.reload();
                    },  1000);
                } else{// si arroja false en responseCode 
                        Swal.fire({
                            text: result.detail,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                            icon: "error",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }  
                })
                
            .catch(error => {
                this.setState({ isEditing: true })
                    Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
            })
     
            
        } else{
            this.setState({ errores });
        }
    }


    /**
    * Función para aprobar o rechazar el proveedor y enviar su información de servicios
    */
    accionesProveedor(event){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const roles = data.roles;
        const {
            acciones, 
            archivos, 
            isCheckedCC
        } = this.state;
        const errores = {};
        const archivosSeleccionados = this.state.archivos.filter(archivo => archivo.selected);
        const algunArchivoSeleccionado = archivos.some(archivo => archivo.selected);
        const documents =  archivosSeleccionados.map((archivo) => archivo.archivoID);
        // Validar si se ha seleccionado una acción
        if (!acciones) {
            errores.acciones = 'Debes seleccionar una acción.';
        } else {
            // Realizar otras validaciones si se ha seleccionado una acción 1 o 19
            if (acciones.value === 19 || acciones.value === 1) {
                if (!this.state.userData.cuando) {
                    errores.cuando = 'El campo es obligatorio.';
                }
                if (!this.state.userData.lugar) {
                    errores.lugar = 'El campo es obligatorio.';
                }
                if (!this.state.userData.monto) {
                    errores.monto = 'El campo es obligatorio.';
                }
                if (!this.state.userData.que) {
                    errores.que = 'El campo es obligatorio.';
                }
                if (!this.state.selectValueCompany) {
                    errores.corresponde_a = 'Debes seleccionar una compañía.';
                }
                if(this.state.userData.tipo_p === 2){
                    if (!this.state.userData.donde) {
                        errores.donde = 'El campo es obligatorio.';
                    }
                }              
            } else if (acciones.value === 13 || acciones.value === 14 || acciones.value === 20 || acciones.value === 3) { //ingresar nuevos valores de rechazo
                if (!this.state.userData.notas) {
                    errores.notas = 'Debes ingresar el motivo del rechazo.';
                }
            } else if (acciones.value === 21) {
                if (!this.state.userData.notas) {
                    errores.notas = 'Debes ingresar el motivo del rechazo.';
                }
                if (!isCheckedCC && !algunArchivoSeleccionado) {
                    errores.documents = 'Selecciona una opción';
                }
            }
        }
        let companiaID = "";
        const companySelect=( this.state.selectValueCompany === "") ? companiaID: this.state.selectValueCompany;
       
        const indice = roles.includes(13) || roles.includes(23);
        event.preventDefault();
        if (Object.keys(errores).length === 0) {
        Swal.fire({
            title:  DOMPurify.sanitize(`Estas seguro de ${this.state.acciones.label} este proveedor`),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#bb2d3b',
            confirmButtonText: `Si, ${this.state.acciones.label}`,
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {// si confirman la acion a realizar
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Guardando...',
                    text: 'Por favor, espera mientras se guarda la información.',
                    icon: 'info',
                    showConfirmButton: false,
                });
                this.setState({ hasSelection: true });
                const options = {
                    method: ( indice === true) ? 'POST' : 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body:  JSON.stringify({ 
                        accionID: acciones.value,
                        corresponde_a: companySelect,
                        que: this.state.userData.que,
                        monto: this.state.userData.monto,
                        lugar: this.state.userData.lugar,
                        cuando: this.state.userData.cuando,
                        donde: this.state.userData.donde,
                        notas: this.state.userData.notas,
                        observaciones: this.state.userData.observaciones,
                        documentos: documents
                    })
                }
                fetch(API_PATH + 'proveedor_workflow/?id_proveedor='+ this.props.location.state.id, options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title:  DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
                .then(result => {
                    Swal.close();
                    if(result.success === true ){// si en responseCode arroja un 201  manda mensaje de exito 
                        Swal.fire({
                            text:  DOMPurify.sanitize(`${result.detail}`),
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#003767',
                            icon: "info",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // Redirige al home
                                setTimeout(function () {
                                window.location.href = "/";
                              }, 8000);
                            }
                        });
                        setTimeout(() => {
                            const confirmButton = document.querySelector('.swal2-confirm');
                            if (confirmButton && !confirmButton.classList.contains('swal2-confirm-clicked')) {
                                // Si el botón de confirmación no ha sido presionado después de 5 segundos, redirige al home
                                window.location.href = "/";
                            }
                        }, 8000);
                    } 
                    else{// si no arroja un 200 en responseCode 
                        Swal.fire({
                            text:   result.detail || 'Ocurrió un error desconocido',
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                        this.setState({ hasSelection: false });
                    }  
                })
                .catch(error => {
                    Swal.fire({
                                text: "Ocurrio un error",
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
                })

            }
        }) } else {
            this.setState({ errores });
        }
    }

    /**
    * Función para descargar el zip con los archivos del proveedor
    */
    downloadFiles() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        const idProveedor = this.props.location.state.id || this.props.match.params.id;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', API_PATH + 'archivos/descarga/?id_proveedor=' + idProveedor, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.responseType = 'blob';
        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                this.setState({ downloadProgress: progress });
            }
        };

        xhr.onload = () => {
            this.setState({ isDownloading: false, downloadProgress: 0 });
            if (xhr.status === 200) {
                const url = window.URL.createObjectURL(xhr.response);
                const link = document.createElement('a');
                link.href = url;
                link.download = `documentos.${this.props.location.state.id}.zip`;
                link.click();
                link.remove();
                const xTagValue = xhr.getResponseHeader('X-Tag');
                this.setState({ xTagValue });
            } else {
                // Manejar errores
            }
        };
        xhr.onerror = () => {
            this.setState({ isDownloading: false, downloadProgress: 0 });
            // Manejar errores
            Swal.fire({
                title: 'Ocurrió un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            });
        };
        this.setState({ isDownloading: true });
        xhr.send();
    }


    handleEditarClick = () => {
        // Al hacer clic en editar, se activan los campos editables
        this.setState({
          editable: true,
        });
    }
    
    // actualizarInfoServicios = () => {
    //     // Aquí podrías enviar los datos a una API para guardarlos
    //     // Supongamos que se guarda exitosamente y se desactivan los campos editables
    //     this.setState({
    //       editable: false,
    //     });
    // }
    
    handleCancelarClick = () => {
        // Al cancelar, se desactivan los campos editables y se muestra el botón de editar
        this.setState({
          editable: false,
        });
    }

    actualizarInfoServicios = async (event) => {
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const idProveedor = this.props.location.state.id || this.props.match.params.id;
        sessionStorage.setItem("corresponde_a", this.state.userData.corresponde_a)
        const companyAcount = sessionStorage.getItem("corresponde_a")
        const companySelect=( this.state.selectValueCompany === "") ? companyAcount : this.state.selectValueCompany;
     
        const errores = {};
        
        const options = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body:  JSON.stringify(
                {
                    corresponde_a: companySelect,
                    que: this.state.userData.que,
                    monto: this.state.userData.monto,
                    lugar: this.state.userData.lugar,
                    cuando: this.state.userData.cuando,
                    donde: this.state.userData.donde,
                    observaciones: this.state.userData.observaciones,
                    id_proveedor: idProveedor,
                }
            ) 
        }

        if (!this.state.userData.cuando) {
            errores.cuando = 'El campo es obligatorio.';
        }
        if (!this.state.userData.lugar) {
            errores.lugar = 'El campo es obligatorio.';
        }
        if (!this.state.userData.monto) {
            errores.monto = 'El campo es obligatorio.';
        }
        if (!this.state.userData.que) {
            errores.que = 'El campo es obligatorio.';
        }

        if (Object.keys(errores).length === 0) {
            Swal.fire({
                title: 'Guardando...',
                text: 'Por favor, espera mientras se guarda la información.',
                icon: 'info',
                showConfirmButton: false,
            });
            fetch(API_PATH + 'proveedor_datos_adicionales/editar_datos_adicionales', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                        return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
            .then(result => {
                Swal.close();
                if(result.success === true ){// si en responseCode arroja true  manda mensaje de exito 
                    Swal.fire({
                            text: DOMPurify.sanitize(`${result.detail}`),
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: "success",
                            timer: 8000
                    })
                    this.setState({ editable: false })
                        setTimeout(function () {
                            window.location.reload();
                    },  1000);
                } else{// si arroja false en responseCode 
                        Swal.fire({
                            text: result.detail,
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                            icon: "error",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }  
                })
                
            .catch(error => {
                this.setState({ isEditing: true })
                    Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
            })
            
        } else{
            this.setState({ errores });
        }
    }
 
    render() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const {
                acciones, 
                errores, 
                isEditing,
                userData, 
                editable,
        } = this.state;

        //obtiene las opciones de aprobación en el flujo
        let options = this.state.accionesList.map(function (accionesList) {
            return { value: accionesList.AccionID, label: accionesList.Nombre, color: accionesList.color};
        })

        // Verifica si this.state.companiaList tiene datos antes de mapearlo
        let optionsCompany = [];
        if (this.state.companiaList && this.state.companiaList.length > 0) {
            optionsCompany = this.state.companiaList.map(function (companiaList) {
                return { value: companiaList.Company_Code, label: companiaList.Company_Name};
            });
        }

        //obtiene las opciones de paises
        let optionsPais = this.state.paisCuentaList.map(function (paisCuentaList) {
            return { value: paisCuentaList.clave_pais, label: paisCuentaList.nombre };
        })

        //genera el listado de aprobaciones
        const historialAprobaciones = this.state.historialList.map((elemento, index) => (
            <div key={index}>
                {elemento}
                <br />
            </div>
        ));

        //verifica si tiene el rol de division o proyectista (13 o 20),
        let visibleComents
        if( roles.includes(20)){
            visibleComents = true
        }
        let visibleInfoProveedor    
        if(roles.includes(13) || roles.includes(24)){
            visibleInfoProveedor= true
        }

        let visibleDonde =(this.state.userData.tipo_p === 1) ? false : true
       
        let editar = (roles.includes(9) === true) ? 1 :0

        let visibleServices = (roles.includes(24)) ? false : true
        let visibleEditServices = (roles.includes(24) ) ? true : false
        let diableServicios = (this.state.userData.estatus === 14 || this.state.userData.estatus === 3 )? true : false

        let fechaCuando=this.state.userData.cuando
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }

        if (this.state.loading === true) {
            return(
                <div className="preloader" >
                    <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        sessionStorage.setItem("pais_cuenta", this.state.userData.pais_cuenta)
        var estatusAprobacion;
        if ((this.state.userData.status === "Aprobado por UN y/o proyectista pendiente de aprobar área de Finanzas" && (roles.includes(13) || roles.includes(24)) ) || this.state.userData.status === "En Espera" || this.state.userData.status === "Rechazado" || this.state.userData.status === "Rechazado parcialmente" ) {
           estatusAprobacion = true;
        } else {
            estatusAprobacion = false;
        } 

        var textoNotas = (this.state.acciones && (acciones.value === 3 || acciones.value === 13 || acciones.value === 14 || acciones.value === 20 || acciones.value === 21))? "Motivo del rechazo" : "Notas"

        return (
            <div>
                <>
                    <div className="container info-proveedores ">
                        <div className='row'>
                            <div className='col-md-12'> <br/>
                                <h2 className='center'>Información de {this.state.userData.razon_social}</h2>
                                <hr/>
                            </div>
                            {editar === 1 && !isEditing && (
                                <div className='col-md-12 btns-edit'>
                                    <button onClick={this.handleEditClick} className='btn btn-primary btn-bg'><i className="fa-solid fa-pen-to-square white"></i> Editar Información</button>
                                </div>
                            )} 
                            {isEditing  && editar === 1 ? (
                                <form onSubmit={this.editarInformacion}>
                                    <div className='row'>
                                        <h4 className='blue-str'>Datos Generales</h4><br /><br />
                                        <div className='col-md-4'>
                                            <b>País:</b><br/>
                                            <input
                                                type="text"
                                                placeholder={this.state.userData.clave_pais}
                                                disabled  
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <b>Número de identificación tributaria:</b><br/>
                                            <input
                                                type="text"
                                                placeholder={this.state.userData.rfc}
                                                disabled  
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <b>Correo Electrónico:</b><br/>
                                            <input
                                                type="text"
                                                name='email'
                                                value={this.state.userData.email}  
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                            {errores.email && <span className="error">{errores.email}</span>}
                                        </div>
                                        <div className='col-md-6'>
                                            <br/><b>Direccion:</b><br/>
                                            <input
                                                type="text"
                                                name='direccion'
                                                value={this.state.userData.direccion}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                             {errores.direccion && <span className="error">{errores.direccion}</span>}
                                        </div>
                                        <div className='col-md-3'>
                                            <br/><b>Código Postal:</b><br/>
                                            <input
                                                type="text"
                                                name="cp"
                                                value={this.state.userData.cp}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                             {errores.cp && <span className="error">{errores.cp}</span>}
                                        </div>
                                        <div className='col-md-3'>
                                            <br/><b>Ciudad:</b><br/>
                                            <input
                                                type="text"
                                                name="ciudad"
                                                value={this.state.userData.ciudad}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                             {errores.ciudad && <span className="error">{errores.ciudad}</span>}
                                        </div>
                                        <div className='col-md-3'>
                                            <br/><b>Teléfono:</b><br/>
                                            <input
                                                type="text"
                                                name='telefono'
                                                value={this.state.userData.telefono}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <br/><hr/> <br />
                                    <div className='row'>
                                        <h4 className='blue-str'>Datos Bancarios</h4><br /><br />
                                        <div className='col-md-2'>
                                            <b>País de Origen Banco:</b><br/>
                                            <Select
                                                className="z-index-1"
                                                options={optionsPais}
                                                defaultValue={{value:this.state.userData.pais_cuenta, label:this.state.userData.pais_cuenta}}
                                                onChange={(newSelected)=>{
                                                    this.setState({selectedValue: newSelected.value})
                                                }} 
                                            />
                                        </div>
                                        <div className='col-md-5'>
                                            <b>No.Cuenta y/o CLABE:</b><br/>
                                            <input
                                                type="text"
                                                name='cuenta_bancaria'
                                                value={this.state.userData.cuenta_bancaria}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                             {errores.cuenta_bancaria && <span className="error">{errores.cuenta_bancaria}</span>}
                                        </div>
                                        {/* <div className='col-md-3'>
                                            <b>Clabe Bancaria:</b><br/>
                                            <input
                                                type="text"
                                                name='clabe_bancaria'
                                                value={this.state.userData.clabe_bancaria}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div> */}
                                        <div className='col-md-5'>
                                            <b>Banco Beneficiario</b><br/>
                                            <input
                                                type="text"
                                                name='referencia_cuenta'
                                                value={this.state.userData.referencia_cuenta}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                             {errores.referencia_cuenta && <span className="error">{errores.referencia_cuenta}</span>}
                                        </div>
                                        <div className='col-md-6'>
                                            <br /><b>Titular de la Cuenta:</b><br/>
                                            <input
                                                type="text"
                                                name='titular_cuenta'
                                                value={this.state.userData.titular_cuenta}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>Swfit:</b><br/>
                                            <input
                                                type="text"
                                                name='swif_cuenta'
                                                value={this.state.userData.swif_cuenta}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>ABA:</b><br/>
                                            <input
                                                type="text"
                                                name='aba_cuenta'
                                                value={this.state.userData.aba_cuenta}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>Cuenta IBAN:</b><br/>
                                            <input
                                                type="text"
                                                name='IBAN'
                                                value={this.state.userData.IBAN}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>Banco Intermediario:</b><br/>
                                            <input
                                                type="text"
                                                name='Banco_intermediario'
                                                value={this.state.userData.Banco_intermediario}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>Swift Banco Intermediario:</b><br/>
                                            <input
                                                type="text"
                                                name='Swift_banco_intermediario'
                                                value={this.state.userData.Swift_banco_intermediario}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        <div className='col-md-3'>
                                            <br /><b>ABA Banco Intermediario:</b><br/>
                                            <input
                                                type="text"
                                                name='ABA_banco_intermediario'
                                                value={this.state.userData.ABA_banco_intermediario}
                                                onChange={this.handleInputChange}
                                                className='form-control'
                                            />
                                        </div>
                                        
                                    </div>
                                    <br/> 
                                    <div className='btns-edit'>
                                        <button onClick={this.handleCancelClick} className='btn btn-primary btn-red'>Cancelar</button>&nbsp;
                                        <button  type="submit" className='btn btn-primary btn-green'>Guardar</button>
                                    </div>
                                    <hr/>
                                </form>
                                ) : (   
                                <>
                                    <div className='col-md-12'>
                                        <h4 className='blue-str'>Datos Generales</h4>
                                        <b>País: </b> {this.state.userData.clave_pais}<br/>
                                        <b>Número de identificación tributaria:</b> {this.state.userData.rfc}<br/>
                                        <b>Dirección:</b> {this.state.userData.direccion}<br/>
                                        <b>CP:</b> {this.state.userData.cp}<br/>
                                        <b>Ciudad:</b> {this.state.userData.ciudad}<br/>
                                        <b>Correo Electrónico:</b> {this.state.userData.email}<br/>
                                        <b>Teléfono:</b> {this.state.userData.telefono}<br/>
                                        <b>Fecha de registro:</b> {this.state.userData.fecha_alta}<br/> <br/>
                                    </div>
                                    <hr/>
                                    <div className='col-md-4'>
                                        <h4 className='blue-str'>Datos Bancarios</h4>
                                        <b>Pais de Cuenta:</b> {this.state.userData.pais_cuenta}<br/>
                                        <b>No. Cuenta y/o CLABE:</b> {this.state.userData.cuenta_bancaria}<br/>
                                        {/* <b>Clabe Bancaria:</b> {this.state.userData.clabe_bancaria}<br/> */}
                                        <b>Titular de la Cuenta:</b> {this.state.userData.titular_cuenta}<br/>
                                        <b>Banco:</b> {this.state.userData.referencia_cuenta}<br/>
                                        <b>Swif:</b> {this.state.userData.swif_cuenta}<br/>
                                        <b>ABA:</b> {this.state.userData.aba_cuenta}<br/> 
                                        <b>Cuenta IBAN:</b> {this.state.userData.IBAN} <br/>
                                        <b>Banco Intermediario:</b> {this.state.userData.Banco_intermediario} <br/>
                                        <b>Swif Banco Intermediario:</b> {this.state.userData.Swift_banco_intermediario}<br/>
                                        <b>ABA Banco Intermediario:</b> {this.state.userData.ABA_banco_intermediario}<br/>
                                    </div>
                                </>
                                )}
                                <div className='col-md-5'>
                                    <h4 className='blue-str'>Datos de Personas de contacto</h4>
                                    <b>Nombre:</b> {this.state.userData.nombre_administrador}<br/>
                                    <b>Usuario:</b> {this.state.userData.usuario_sistemas}<br/>
                                </div>
                                <div className='col-md-3'>
                                    <b>Correo electrónico de contacto de la agencia:</b> <br/>{this.state.userData.contacto_agencia}<br/><br/><br/>
                                    <b>Status:</b> {this.state.userData.status} <br/>
                                    <b>Id supplier vantage:</b> {this.state.userData.idSupplierVantage}  
                                </div> 
                                {visibleServices? (
                                <div className='col-md-12'>  <br/>  <hr/>
                                    <h4 className='blue-str'>Descripción de los servicios</h4>
                                    <b>El proveedor corresponde a:</b> {this.state.userData.corresponde_a}<br/>
                                    <b>¿Que hará?:</b> {this.state.userData.que}<br/>
                                    <b>¿Cuándo lo hara?:</b> {this.state.userData.cuando}<br/>
                                    <b>Monto de pago:</b> {this.state.userData.monto}<br/>
                                    <b>Lugar donde se explotan los servicios:</b> {this.state.userData.lugar}<br/>
                                    <b>¿Dónde lo hará?:</b> {this.state.userData.donde}<br/>
                                    <b>Comentarios adcionales:</b> {this.state.userData.observaciones}<br/><br/> 
                                    <hr/>
                                </div>
                                ) : null}

                                <div className='col-md-6'><br/> 
                                    <h4 className='blue-str'>Documentos</h4>
                                    <div className="btn btn-primary btn-bg-str" onClick={() => this.downloadFiles()} disabled={this.state.isDownloading}>
                                        {this.state.isDownloading ? `Descargando... ${Math.round(this.state.downloadProgress)}%` : 'Descargar Documentos'}
                                    </div>
                                    <br/><br/>
                                    {this.state.isDownloading && (
                                        <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${this.state.downloadProgress}%` }}
                                            aria-valuenow={this.state.downloadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                        </div>
                                    )}
                                    {this.state.xTagValue && (
                                        <p>La contraseña es: <b> {this.state.xTagValue}</b></p>
                                    )}

                                </div>
                                <div className='col-md-12'>  <br/>  <hr/>
                                    <h4 className='blue-str'>Historial de aprobaciones</h4>
                                    {historialAprobaciones}
                                    <hr/>
                                </div>
                                
                            </div>
                        </div>
                        <br/>
                        <div className='container'>   
                            <div className='row'>
                                <form onSubmit={this.accionesProveedor}>
                                    <div className='row '>
                                        {visibleInfoProveedor ? (
                                            <>
                                            <div className='col-md-6'>
                                                <h4 className='blue-str'>Información sobre el servicio del proveedor</h4><br/><br/>
                                            </div>
                                            {visibleEditServices? (
                                            <div className='col-md-6 end'> 
    {!editable && userData.estatus !== 1 && userData.estatus !== 11 && (
        <button onClick={this.handleEditarClick} className='btn btn-primary btn-bg' disabled={diableServicios}><i className="fa-solid fa-pen-to-square white"></i> Editar información</button>
    )}
</div>
                                            ) : null}

                                            <div className='col-md-6'>  
                                                <label> El proveedor corresponde a:</label>
                                                {/* <Select
                                                    className="z-index-1"
                                                    placeholder="Mccann o Corporación"
                                                    options={optionsCompany}
                                                    value={compania}
                                                    onChange={(selectedOption) => this.handleSelectChange('compania', selectedOption)}
                                                    //isDisabled={estatusAprobacion}
                                                />  */}

                                            <Select
                                                className="z-index-1"
                                                placeholder="Mccann o Corporación"
                                                options={optionsCompany}
                                                defaultValue={{value:this.state.userData.corresponde_a, label:this.state.userData.corresponde_a}}
                                                onChange={(newSelected)=>{
                                                    this.setState({selectValueCompany: newSelected.value})
                                                }} 
                                                isDisabled={!editable}
                                            />
                                                {errores.corresponde_a && <span className="error">{errores.corresponde_a}</span>}
                                            </div>
                                            <div className='col-md-6'>
                                                <label> ¿Cuándo lo hara?</label>
                                                <input 
                                                    type="date"
                                                    name="cuando"
                                                    value={fechaCuando || ''}
                                                    placeholder='Fecha exacta en la cual se deberá el servicio'
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    disabled={!editable}
                                                />
                                              
                                                {errores.cuando && <p className="error">{errores.cuando}</p>}
                                            </div>
                                            <div className='col-md-6'><br/>
                                                <label> Monto de pago</label>
                                                <input 
                                                    type="text"
                                                    name="monto"
                                                    value={userData.monto}
                                                    placeholder='Cuanto se le pagará y si aplica una condición de pago especifica'
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    disabled={!editable}
                                                />
                                                {errores.monto && <p className="error">{errores.monto}</p>}
                                            </div>
                                            <div className='col-md-6'><br/>
                                                <label> Lugar donde se explotan los servicios</label>
                                                <input 
                                                    name="lugar"
                                                    value={userData.lugar}
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    disabled={!editable}
                                                />
                                                {errores.lugar && <p className="error">{errores.lugar}</p>}
                                            </div>
                                            <div className='col-md-6'><br/>
                                                <label> ¿Que hará?</label>
                                                <textarea 
                                                    className="form-control" id="que"
                                                    placeholder="Descripción detallada del servicio" 
                                                    name="que"
                                                    value={this.state.userData.que}
                                                    onChange={this.handleInputChange}
                                                    disabled={!editable}
                                                />
                                                {errores.que && <p className="error">{errores.que}</p>}
                                            </div>
                                            {visibleDonde ? (
                                            <div className='col-md-6'><br/>
                                                <label> ¿Dónde lo hará?</label>
                                                <textarea 
                                                    placeholder='En Facebook, Instagram, TikTok, etc.  '
                                                    name="donde"
                                                    value={userData.donde}
                                                    onChange={this.handleInputChange}
                                                    className="form-control"
                                                    disabled={!editable}
                                                />
                                                {errores.donde && <p className="error">{errores.donde}</p>}
                                            </div>
                                            ) : null}
                                            <div className='col-md-6'><br/>
                                            <label>Comentarios Adicionales</label>
                                                <textarea 
                                                    className="form-control" id="observaciones"
                                                    placeholder="Ingresa algún comentario adicional" 
                                                    name="observaciones"
                                                    value={userData.observaciones}
                                                    onChange={this.handleInputChange}
                                                    disabled={!editable}
                                                    
                                                />
                                            </div>
                                            </>
                                        ) : null}
                                    
                                    </div>
                                    <div className='row '>
                                    <div className='col-md-6'>
                                            <br/>
                                            <label>Selecciona la acción que deseas realizar</label>
                                            <Select
                                                className="z-index-1"
                                                placeholder="Selecciona una acción"
                                                options={options}
                                                value={acciones}
                                                onChange={(selectedOption) => this.handleSelectChange('acciones', selectedOption)}
                                                styles={colorStyles}
                                                isDisabled={estatusAprobacion}
                                            
                                            /> 
                                            {errores.acciones && <span className="error">{errores.acciones}</span>}
                                        </div>
                                        {visibleComents? (
                                            <div className='col-md-6'><br/>
                                                <label>{textoNotas}</label>
                                                <textarea 
                                                    className="form-control" id="notas"
                                                    placeholder={textoNotas} 
                                                    name="notas"
                                                    value={userData.notas}
                                                    onChange={this.handleInputChange}
                                                    disabled={estatusAprobacion}
                                                    rows="1" 
                                                />
                                                {errores.notas && <span className="error">{errores.notas}</span>}
                                            </div>
                                        ) : null}
                                        {acciones && acciones.value === 21 && (//CAMBIAR POR EL VALOR DE LA ACCION DE RECHAZO PARCIAL
                                            <div className='col-md-8'>
                                                <br/>
                                                <b>Seleccione los documentos que esten incorrectos.</b><br/>
                                                {/* <i>En caso de que no haya ningún documento con problemas, selecciona <b>'Ningún documento'</b></i><br/> */}
                                                {/* <label>
                                                    <input
                                                        className="option-input checkbox"
                                                        type="checkbox"
                                                        checked={this.state.isCheckedCC} 
                                                        onChange={event => {
                                                                const isChecked = event.target.checked;
                                                                this.setState({
                                                                    isCheckedCC: isChecked,
                                                                });
                                                                // Actualizar el estado de los archivos después de que isCheckedCC se haya actualizado
                                                                const nuevosArchivos = this.state.archivos.map(archivo => ({
                                                                    ...archivo,
                                                                    selected: false, // Desmarcar todos los archivos cuando se selecciona "Ningún documento"
                                                                    disabled: isChecked // Si isCheckedCC es true, todos los checkboxes se deshabilitarán
                                                                }));

                                                                this.setState({
                                                                    archivos: nuevosArchivos
                                                                });
                                                            }}
                                                            id="ningunDocumento" 
                                                        />
                                                    Ningún documento
                                                </label> */}
                                                {this.state.archivos.map(archivo => (
                                                    <div key={archivo.archivoID}>
                                                        <label>
                                                            <input
                                                                className="option-input checkbox"
                                                                type="checkbox"
                                                                checked={archivo.archivoID === 'ningunDocumento' ? this.state.isCheckedCC : archivo.selected || false}
                                                                onChange={() => this.handleCheckboxChange(archivo.archivoID)}
                                                            />
                                                        {archivo.nombreCompleto}
                                                        </label>
                                                    </div>
                                                ))}
                                                
                                                    <br/><br/>
                                                    {errores.documents && <span className="error">{errores.documents}</span>}
                                                </div>
                                            )}
                                            <div className='col-md-12 end'><br/>
                                                {/* <button className='btn btn-primary btn-green' onClick={this.accionesProveedor} disabled={estatusAprobacion}>Enviar</button> */}
                                                {/* {editarServicios? (
                                                    <button onClick={this.handleActualizarServicios} className='btn btn-primary btn-green'>Guardar</button>
                                                    ) : (
                                                        <button onClick={this.handleActualizarServicios} className='btn btn-primary btn-green'>Guardar</button>
                                                )} */}
                                                <br />
                                                
                                                 {(estatusAprobacion === false && (
                                                    <button onClick={this.accionesProveedor} className='btn btn-primary btn-green'>Enviar</button>
                                                ))} 

                                                
                                                {editable && userData.estatus !== 1 &&  userData.estatus !== 11 && (
                                                <>
                                                    <button onClick={this.handleCancelarClick} className='btn btn-primary btn-red'>Cancelar</button>&nbsp;
                                                    <button onClick={this.actualizarInfoServicios} className='btn btn-primary btn-green'>Actualizar información</button>
                                                    
                                                </>
                                                )}
                                               
                                            </div> 
                                        </div>
                                </form>
                            </div>
                        </div>
                    </> <br/><br/><br/><br/>
            </div> 
        )
    }
}

export default withRouter (InformacionProveedor);
