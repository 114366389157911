/**
 * * FORMULARIO PARA EDITAR UN USUARIO SSC
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

class EditarAviso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errores: {},
            aviso:'',
            titulo: '',
          
        }
    this.editarAviso = this.editarAviso.bind(this);
    }

    /**
    * Maneja los cambios que se realizan en los inputs
    */
 
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
       
    };

    
    /*
    * Función para resetear el password de los usuarios en azure
    */
    editarAviso(event){
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        const {aviso, titulo} = this.state;
        const errores = {};
        // if (!aviso) {
        //     errores.aviso = 'El campo es obligatorio.';
        // }
        // if (!titulo) {
        //     errores.titulo = 'El campo es obligatorio.';
        // }
        if (Object.keys(errores).length === 0) {
        const options = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify ({
                Nombre:titulo || this.props.datosUsuario.Nombre,
                Texto:aviso || this.props.datosUsuario.Texto,
                ID:this.props.datosUsuario.ID
            })
        }
        fetch(API_PATH + 'portal_avisos/update_aviso', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
            if(result.success === true){
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: "success",
                    // timer: 4000
                })
                setTimeout(() => {
                    this.props.actualizar();
                }, 5000);
            }else{
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        }) 
        } else {
            this.setState({ errores });
        }
    }

    render() {
        const {errores} = this.state;
        return (
            <div className="container pad-30">
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={this.editarAviso} >
                            <label>Título</label>
                            <input
                                ref="titulo"
                                name="titulo"
                                defaultValue={this.props.datosUsuario.Nombre}
                                placeholder={this.props.datosUsuario.Nombre}
                                onChange={this.handleChange}
                                className="form-control"                    
                            />
                            {errores.titulo && <p className="error">{errores.titulo}</p>}
                            <br/>
                           <label> Aviso</label>
                            <textarea
                                ref="aviso"
                                name="aviso"
                                defaultValue={this.props.datosUsuario.Texto}
                               placeholder={this.props.datosUsuario.Texto}
                                onChange={this.handleChange}
                                className="form-control"                    
                            />
                            {errores.aviso && <p className="error">{errores.aviso}</p>}
                            <br/>
                            <div className='right'><button className='btn btn-primary btn-bg'>Guardar</button></div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditarAviso;