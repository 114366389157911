/**
 * HEADER PARA EL REGISTRO DE PROVEEDOR
*/

import React, { Component } from 'react';
import logo from '../../assets/img/ipg-logo.png'
import LanguageSelector from '../../translations/LanguageSelector'
import ModalInfluencer from '../RegistroProveedor/ModalInfluencer';

class HeaderInfluencer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: true,
    };
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <div className='container-fluid'>
          <div className='row menu-principal'>
            <div className='col-md-6'>
            <a className="navbar-brand" href="/"><img src={logo} className="img-fluid logo" alt="logo-hci"/></a>
            </div>
            <div className='col-md-6 idiomas'>
            <LanguageSelector openModal={this.openModal}/>
            <ModalInfluencer isOpen={this.state.isModalOpen} closeModal={this.closeModal} />
            </div>
          </div>
        </div>
    );
  }
}

export default HeaderInfluencer