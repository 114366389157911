import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const idiomaMostrar = 'es'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: {
        translation: {
          Home:{
            label1: 'Ayuda',
            label2: 'BIENVENIDO',
            label3: 'AL PORTAL DE PROVEEDORES',
            label4: 'Comunicado Personas Fisicas/Morales ',
            label5: 'Comunicado Proveedores Servicios especializados  ',
            label6: 'Ingresar',
            label7: 'Registro Proveedor General',
          },
          TitleSteps:{
            contact: 'Contacto Agencia',
            datosGen:'Datos Generales',
            documents: 'Documentos',
            datosBan:'Datos Bancarios',
            datosAdmin:'Datos Administrador'
          },
          Buttons:{
            next:'Siguiente',
            previous:'Anterior',
            save:'Guardar',
            upload:'Subir archivos',
            await:'Espere mientras se cargan los archivos',
            uploadnew:'Volver a subir archivos',
            spanish:'ES',
            english:'EN',
            exito:"El Proveedor se registró correctamente",
            error:"Ocurrio un error"
          },
          ContactAgency: {
            title: "Contacto Agencia",
            label1: 'Correo electrónico de contacto de la agencia',
            error1:'Favor de ingresar un correo válido',
            error2:'El correo que ingresaste esta incorrecto o no existe, verifica con tu contacto para continuar el registro'
          },
          GeneralData:{
            title: 'Datos Generales',
            label1:'Pais',
            label2:'Razón Social',
            label3:'Código de Identificación Tributaria o Número de Identificación Fiscal',
            label4:'Dirección',
            label5:'Código Postal',
            label6:'Estado / Región',
            label7:'Ciudad',
            label8:'Alcaldía / Municipio',
            label9:'Colonia', 
            label10:'Calle',
            label11:'Número',
            label12:'Interior',
            label13:'Teléfono',
            label14:'Correo electrónico para recibir notificaciones',
            label15:'Tipo de Proveedor',
            error1:'Favor de seleccionar un pais',
            error2:'Favor de ingresar tu Razon Social',
            error3:'Favor de ingresar tu Código Postal',
            error4:'Favor de ingresar tu Calle',
            error5:'Ingresa un teléfono válido',
            error6:'Favor de ingresar un correo válido',
            error7:'Favor de ingresar el Código de Identificación Tributaria o Número de Identificación Fiscal',
            error8:'El Código de Identificación Tributaria o Número de Identificación Fiscal ya esta registrado',
            error9:'Ingrese un RFC válido',
            error10:'El RFC ya esta registrado',
            error11:'El correo ya esta registrado',
            error12:'Favor de ingresar el teléfono',
            error13:'Debes seleccionar una opción'
          },
          Documents:{
            title: 'Documentos',

            label1:'Constancia de situación fiscal del mes en curso',
            label2:'Opinión de cumplimiento de obligaciones fiscales del mes en curso positiva.',
            label3:'Detalle de las Declaraciones Anuales 2021 y 2022',
            label4:'Carta de ingresos , debidamente requisitada y firmada. o Declaraciones anuales 2022 y 2023 para personas físicas con actividad empresarial y profesional, (en el caso de personas que tributen en el RIF, presentará últimas tres bimestrales)',
            label5:'Comprobante de domicilio menor a dos meses de antigüedad',
            label6:'Carátula de estado de cuenta del mes anterior.',
            label7:'Contrato  Firmado',
            label8:'Aviso de Privacidad Fimado ', 
            label9:'Identificación oficial',

            label11:'Convenio de confiencialidad firmado',
            label12:'Identificación de Representante Legal.',
            label13:'Poder notarial del Representante Legal completo',
            label14:'Acta constitutiva completa',

            label15:'Número de identificación tributaria TAX ID, VAT, RFC',
            label16:'Certificación bancaria Código Swift, ABA, EBAM.',
            label17:'Proxy o poder notarial que acredita la representación en caso de ser persona moral.',
            label18:'Identificación (de ser persona moral se requiere la indentificación de representante legal)',
            label19:'El proveedor extranjero que sea persona moral deberá enviar su acta constitutiva. ',
           
            label20:'Es obligatorio subir todos los documentos en formato .pdf, .doc., docx, .xls, .png o .jpg',
            label21:'La carga de archivos se ha completado con éxito',
            label22:'Archivos subidos',
            label23:'Formato de solicitud (debidamente llenado)',
            label023:'EN FORMATO EXCEL',
            
            label24:'Constancia de residencia fiscal emitida por la autoridad tributaria del lugar donde tribute el proveedor, vigente en el momento del pago (ésta se solicitará para determinar las tasas  de retenciones aplicables al servicio prestado). Revisar condiciones de pago con tu contacto de agencia',
            label25:'Descargar formato de carta de Ingresos',
            label26:'Llenar únicamente los apartados en amarillo',
            error1:'Solo se permiten archivos .pdf, .doc., docx, .png y .jpg',
            error2:'Verifica que todos los campos tengan archivos con extensión .pdf, .doc., docx, .png o .jpg',
            error3:'Debes seleccionar un archivo para cada campo',
            error4:'Ocurrió un error al cargar los archivos, vuelva a intentarlo'
          },
          DataBank:{
            title: 'Datos Bancarios',
            label1:'País cuenta',
            label2:'Cuenta Bancaria',
            label3:'Beneficiario',
            label4:'Referencia',
            label5:'Clabe Bancaria Estandarizada número de 18 dígitos',
            label6:'Banco',
            label7:'Cuenta IBAN',
            label8:'Banco intermediario',
            label9:'Swift Banco intermediario',
            label10:'ABA Banco intermediario',
            error1:'Favor de ingresar la cuenta bancaria',
            error2:'Favor de ingresar el nombre del beneficiario',
            error3:'Favor de ingresar una clabe bancaria de 18 digitos',
            error4:'Favor de ingresar el banco'
          },
          DataAdmin:{
            title: 'Datos Aministrador',
            label1:'Name',
            label2:'Puesto',
            error1:'Favor de ingresar el nombre',
            error2:'Favor de ingresar el puesto'
          },
          Confirm:{
            title:'Confirmación de datos',
            confidencialidad: 'Aviso de confidencialidad ',
            privacidad:'Aviso de privacidad',
            conducta:'Código de Conducta',
            terminos:'He leído y acepto el Aviso de confidencialidad, Aviso de privacidad y Código de conducta',
            error:'Acepta el Aviso de confidencialidad, Aviso de privacidad y Código de conducta'
          },
          Footer:{
            nosotros: 'Sobre Nosotros',
            agencias:'Nuestras agencias',
            ideas:'Ideas',
            noticias:'Noticias',
            diversidad:'Diversidad',
            contacto:'Contáctanos',
            ayuda:'Ayuda',
          },
          Modal:{
            text1:'Requisitos para el registro',
            text2:'Estimado Proveedor, en caso de alguna duda durante tu proceso de registro debes comunicarte con tu contacto de agencia para recibir la orientacion correspondiente.',
            text3:'¡Info! Para poder obtener tu contrato, por favor de revisar con tu contacto de la agencia.',
            text4:'Además debes tener a la mano la siguiente documentación, escaneada en alguno de los siguientes formatos: PDF, JPG, PNG o DOC.',
            text5:'Persona Física',
            text11:'Persona Moral',
            text19:'Proveedor Extranjero',
            text25:'Cerrar',
          },
          Influencer:{
            text1:'Registro Influencer o Agente ',
            modal1:'Requisitos para el registro de Influencer o Agente',
            modal0:'Para realizar tu registro debes tener a la mano la siguiente documentación, escaneada en alguno de los siguientes formatos: PDF, JPG, PNG o DOC.',
            modal2:'Constancia de situación fiscal del mes en curso. ',
            modal3:'Opinión de cumplimiento de obligaciones fiscales del mes en curso positiva.',
            modal4:'Carta de ingresos. O Declaraciones anuales 2021 y 2022 para personas físicas con actividad empresarial y profesional, (en el caso de personas que tributen en el RIF, presentará últimas tres bimestrales).',
            modal5:'Comprobante de domicilio menor a dos meses de antigüedad',
            modal6:'Carátula de estado de cuenta del mes anterior.',
            modal7:'Identificación oficial.',
            modal8:'Formato de solicitud (debidamente llenado)',
            modal9:'NOTA: En caso de actuar como Agente de un Influencer, deberá de proporcionarse Carta poder o contrato de representación artística o poder notarial que faculte al proveedor para actuar en representación del talento que se desee contratar .',
            modal09:'Carta poder o contrato de representación artística o poder notarial que faculte al proveedor para actuar en representación del talento que se desee contratar',
            modal10:'Acta constitutiva completa',
            modal11:'Poder notarial del Representante Legal completo',
            modal12:'Identificación de Representante Legal.',
            modal14:'Carta poder o contrato de representación artística o poder notarial que faculte al proveedor para actuar en representación del talento que se desee contratar.',
            
            modal15:'Número de identificación tributaria TAX ID, VAT, RFC. ',
            modal16:'Certificación bancaria Código Swift, ABA, EBAM. ',
            modal17:'Identificación (de ser persona moral se requiere la indentificación de representante legal)',
            modal18:'El proveedor extranjero que sea persona moral deberá enviar su acta constitutiva. ',
            modal19:'Proxy o poder notarial que acredita la representación en caso de ser persona moral.',
            modal20:'Carta poder o contrato de representación artística o poder notarial que faculte al proveedor para actuar en representación del talento que se desee contratar.',
            modal21:'Constancia de residencia fiscal emitida por la autoridad tributaria del lugar donde tribute el proveedor, vigente en el momento del pago (ésta se solicitará para determinar las tasas  de retenciones aplicables al servicio prestado). Revisar condiciones de pago con tu contacto de agencia.',
           
            modal22:'En caso de cargar una carta poder esta deberá contener la firma de:  1. La agencia, 2. El influencer, 3.Dos testigos y la identificación de cada uno de los firmantes. ',
            modal23:'Proxy o poder notarial que acredita la representación',
            text2:'Confirmación y envío de información',
            text3:'Carta poder, Contrato de representación artística o Poder notarial',
            text4:'Soy Agente',
            text5:'Soy Persona Moral',
            text6:'Acta constitutiva'

          },
        },
      },
      en: {
        translation: {
          Home:{
            label1: 'Help',
            label2: 'WELCOME ',
            label3: 'TO THE VENDOR PORTAL',
            label4: 'Individual/Corporate Communication',
            label5: 'Specialized Services Provider Communication',
            label6: 'Log in',
            label7: 'Register New Vendor',
          },
          TitleSteps:{
            contact: 'Contact Agency',
            datosGen:'General Information',
            documents: 'Documents',
            datosBan:'Banking Information',
            datosAdmin:'Administrator Information',
          },
          Buttons:{
            next:'Next',
            previous:'Previous',
            save:'Save',
            upload:'Upload files',
            await:'Please wait while the files are being loaded',
            uploadnew:'Upload files again',
            spanish:'ES',
            english:'EN',
            exito:"The supplier has been registered successfully",
            error:"An error occurred"
          },
          ContactAgency: {
            title: 'Contact Agency',
            label1: 'Agency contact email',
            error1:'Please enter a valid email',
            error2:'The email you entered is incorrect or does not exist, please check with your contact to continue the registration.'
          },
          GeneralData:{
            title: 'General Information',
            label1:'Country',
            label2:'Legal name',
            label3:'Tax identification code or Fiscal Identification Number',
            label4:'Address',
            label5:'Postal Code',
            label6:'State/Region',
            label7:'City',
            label8:'Delegation / Municipality',
            label9:'Neighborhood', 
            label10:'Street',
            label11:'Number',
            label12:'Interior',
            label13:'Phone number',
            label14:'Email address to receive notifications',
            label15:'Vendor Type',
            error1:'Please select a country',
            error2:'Please enter your legal name',
            error3:'Please enter your postal code',
            error4:'Please enter your street',
            error5:'Enter a valid phone number',
            error6:'Please enter a valid email',
            error7:'Please enter your Tax identification code or Fiscal Identification Number',
            error8:'The Tax identification code or Fiscal Identification Number is already registered',
            error9:'Enter a valid RFC (tax ID)',
            error10:'The RFC (tax ID) is already registered',
            error11:'The email is already registered',
            error12:'Please enter the phone number',
            error13:'You must select an option'
          },
          Documents:{
            title: 'Documents',
            label1:'Certificate of current fiscal status',
            label2:'Positive opinion on compliance with tax obligations for the current month',
            label3:'Details of Annual Declarations for 2021 and 2022',
            label4:'Income letter, properly completed and signed, or Annual tax declarations for 2022 and 2023 for individuals with business and professional activities (in the case of individuals subject to the RIF, submit the last three bimonthly declarations).',
            label5:'Proof of address dated less than two months ago.',
            label6:'Cover page of the previous month s bank statement.',
            label7:'Signed Contract',
            label8:'Signed Privacy Notice', 
            label9:'Official identification',

            label11:'Signed Confidentiality Agreement',
            label12:'Identification of the Legal Representative',
            label13:'Full Notarial Power of Attorney for the Legal Representative',
            label14:'Complete Articles of Incorporation',

            label15:'Tax Identification Number (TIN), VAT, RFC',
            label16:'Bank Certification Swift Code, ABA, EBAM.',
            label17:'Proxy or notarial power of attorney accrediting representation in the case of being a legal entity.',
            label18:'Identification (If a legal entity, the identification of the legal representative is required)',
            label19:'Foreign supplier that is a legal entity must send its Articles of Incorporation.',
            label23:'Application form (properly filled out)',
            label023:'EXCEL FORMAT',
           
            label20:'It is mandatory to upload all documents in .pdf, .doc., docx, .png or .jpg format',
            label21:'File upload completed successfully',
            label22:'Files uploaded',
            label24:'Certificate of fiscal residence issued by the tax authority of the jurisdiction where the supplier pays taxes, valid at the time of payment (this will be requested to determine the applicable withholding tax rates for the service provided). Please review the payment conditions with your agency contact.',
            label25:'Download Income Letter',
            label26:'Fill out only the sections highlighted in yellow.',
            error1:'Only .pdf, .doc., docx, .xls, .png, and .jpg files are allowed',
            error2:'Make sure that all fields have files with .pdf, .doc., docx, .png, or .jpg extension',
            error3:'You must select a file for each field',
            error4:'An error occurred while loading the files, please try again',
          },
          DataBank:{
            title: 'Banking Information',
            label1:'Country of the account',
            label2:'Bank Account',
            label3:'Beneficiary',
            label4:'Reference',
            label5:'Standardized Banking CLABE number of 18 digits',
            label6:'Bank',
            label7:'IBAN account',
            label8:'Intermediary Bank',
            label9:'Intermediary Bank Swift',
            label10:'Intermediary Bank ABA',
            error1:'Please enter the bank account',
            error2:'Please enter the name of the beneficiary',
            error3:'Please enter an 18-digit bank CLABE',
            error4:'Please enter the bank'
          },
          DataAdmin:{
            title: 'Administrator Information',
            label1:'Name',
            label2:'Position',
            error1:'Please enter the name',
            error2:'Please enter the position'
          },
          Confirm:{
            title:'Data Confirmation',
            confidencialidad: 'Confidentiality Notice',
            privacidad:'Privacy Notice',
            conducta:'Code of Conduct',
            terminos:'I have read and accept the Confidentiality Notice, Privacy Notice, and Code of Conduct.',
            error:'I accept the Confidentiality Notice, Privacy Notice, and Code of Conduct'
          },
          Footer:{
            nosotros: 'About Us',
            agencias:'Our Agencies',
            ideas:'Ideas',
            noticias:'News',
            diversidad:'Diversity',
            contacto:'Contact Us',
            ayuda:'Help',
          },
          Modal:{
            text1:'Registration Requirements',
            text2:'Dear Supplier, in case of any doubts during your registration process, you must contact your agency contact to receive the corresponding guidance.',
            text3:'Info! In order to obtain your contract, please check with your contact.',
            text4:'Additionally, you must have the following documentation on hand, scanned in one of the following formats: PDF, JPG, PNG, or DOC.',
            text5:'Individual Person',
            text6:'RFC (showing the fiscal address) and tax compliance opinion ',
            text7:'Copy of Proof of Address that matches the fiscal address, not older than 3 months (electricity, water, telephone, cable TV)',
            text8:'Copy of Bank Statement showing the Standardized Banking Key (18-digit CLABE) with a validity not exceeding 3 months. If the account is recently opened, a letter stamped by the bank or a copy of the document issued by the bank (contract specifying the CLABE account) is required. No other type of document is accepted.',
            text9:'Copy of Official Identification of the Legal Representative (IFE or Passport) to verify the signature on the contract (must be legible).',
            text10:'Contract signed by the legal representative (in addition to uploading it, it must be sent in its original form to the agency).',
            text11:'Legal Entity',
            text12:'RFC (showing the fiscal address) and tax compliance opinion', 
            text13:'Copy of Proof of Address that matches the fiscal address, not older than 3 months (electricity, water, telephone, cable TV)',
            text14:'Copy of Bank Statement showing the Standardized Banking Key (18-digit CLABE) with a validity not exceeding 3 months. If the account is recently opened, a letter stamped by the bank or a copy of the document issued by the bank (contract specifying the CLABE account) is required. No other type of document is accepted.',
            text15:'Copy of Official Identification of the Legal Representative (IFE or Passport) to verify the signature on the contract (must be legible).',
            text16:'Contract signed by the legal representative (in addition to uploading it, it must be sent in its original form to the agency).',
            text17:'Articles of Incorporation',
            text18:'Notarial Power of Attorney',
            text19:'Foreign vendor',
            text20:'Tax Identification Code or Tax Identification Number issued in your country',
            text21:'Bank Account (document including codes and references for international transfer', 
            text22:'Contract signed by the legal representative (in addition to uploading it, it must be sent in its original form to the agency)',
            text23:'Copy of Official Identification of the Legal Representative to verify the signature on the contract (must be legible)',
            text24:'Proof of Residence Certificate',
            text25:'Close'
          },
          Influencer:{
            text1:'Influencer or Agent registration',
            modal1: 'Requirements for Influencer or Agent Registration',
            modal0: 'To complete your registration, you must have the following documentation scanned in any of the following formats: PDF, JPG, PNG, or DOC.',
            
            modal2: 'Current month fiscal status certificate.',
            modal3: 'Positive opinion on compliance with tax obligations for the current month.',
            modal4: 'Income letter. Or 2021 and 2022 annual declarations for individuals with business and professional activities (for those taxed under RIF, the last three bimonthly declarations will be submitted).',
            modal5: 'Proof of address less than two months old.',
            modal6: 'Front page of the previous month s bank statement.',
            modal7: 'Official identification.',
            modal8: 'Application form (properly filled out)',
            modal9: 'NOTE: If acting as an Agent for an Influencer, a power of attorney or artistic representation contract must be provided, or a notarial power authorizing the provider to act on behalf of the talent to be hired.',
            modal09: 'Power of attorney or artistic representation contract, or notarial power authorizing the provider to act on behalf of the talent to be hired',

            modal10: 'Complete articles of incorporation',
            modal11: 'Complete notarial power of the Legal Representative',
            modal12: 'Identification of the Legal Representative.',
            modal14: 'Power of attorney or artistic representation contract, or notarial power authorizing the provider to act on behalf of the talent to be hired.',
            
            modal15: 'Tax identification number TAX ID, VAT, RFC.',
            modal16: 'Bank certification Swift Code, ABA, EBAM.',
            modal17: 'Identification (if a legal entity, the identification of the legal representative is required)',
            modal18: 'Foreign providers who are legal entities must send their articles of incorporation.',
            modal19: 'Proxy or notarial power accrediting representation in the case of legal entities.',
            modal20: 'Power of attorney or artistic representation contract, or notarial power authorizing the provider to act on behalf of the talent to be hired.',
            modal21: 'Proof of tax residence issued by the tax authority of the providers location, valid at the time of payment (this will be requested to determine the applicable withholding rates for the service provided). Check payment conditions with your agency contact.',
            modal22: 'If a power of attorney is submitted, it must contain the signature of: 1. The agency, 2. The influencer, 3. Two witnesses and the identification of each of the signatories.',
            modal23:'Proxy or notarial power accrediting representation',
            text2: 'Confirmation and submission of information',
            text3:'Power of Attorney, Artistic Representation Contract, or Notarial Power',
            text4:'I am an agent.',
            text5: "I am a corporate entity.",
            text6: "Articles of incorporation"
            

          },
        },
      },
    },
    
    lng: `${idiomaMostrar}`, // if you're using a language detector, do not define the lng option
    fallbackLng: `${idiomaMostrar}`,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });