/**
 * CONFIGURACION DEL MENU
*/

import React, {Component} from 'react';
import 'sweetalert2/src/sweetalert2.scss'

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFeed: '',
      loading: true,
      activeLink: null,
    }
    this.getUserFeed = this.getUserFeed.bind(this);
  }

  /**
  * Obtiene los datos del usuario
  */
  componentWillMount() {
    if (sessionStorage.getItem('userData')) {
      this.getUserFeed();
    } else {
    this.setState({ redirect: true, loading: false });
    }
  }

  componentDidMount() {
    const path = window.location.pathname;
    this.setState({ activeLink: path });
  }

  getUserFeed() {
    let data = {};
    new Promise((resolve, reject) => {
    data = JSON.parse(sessionStorage.getItem('userData'));
      resolve();
    }).then(() => {
      this.setState({ userData: data });
    });
  }

  handleClick = (link) => {
    this.setState({ activeLink: link });
  }
  
  render() {
    let data = JSON.parse(sessionStorage.getItem("userData"));

    //quita los roles para que no se muestren en el menu ya que no son vistas
    //const roles = data.roles.filter((idRol) => ![7, 8, 10, 13, 14, 17, 20, 21, 22].includes(idRol));

    const roles = data.menu;
    // ordena los roles para la vista del menu
    const compareFunction = (a, b) => {
      const order = [0, 1, 3, 16, 2, 5, 19, 9, 6, 15, 4, 25,26,18,27];
      return order.indexOf(a) - order.indexOf(b);
    };
    roles.sort(compareFunction);

    const { activeLink} = this.state;
    var link
    var nombreEnlace
    const opcionesMenu = roles.map((idRol, i) =>
        {
          if(idRol=== 0){
            nombreEnlace ="Proveedores";
            link="/home"
          }else if(idRol === 1){
            nombreEnlace="Administrar usuarios internos"
            link="/administracion-usuarios"
          }else if(idRol === 2){
            nombreEnlace="Baja de usuarios de proveedores"
            link="/baja-proveedores"
          }else if(idRol === 3){
            nombreEnlace="Administrar usuarios SSC"
            link="/usuarios-ssc"
          }else if(idRol === 4){
            nombreEnlace="Logs"
            link="/logs"
          }else if(idRol === 5){
            nombreEnlace="Ordenes de compra"
            link="/ordenes-compra"
          }else if(idRol === 6){
            nombreEnlace="Administrar periodo de cierre"
            link="/periodo-cierre"
          }else if(idRol === 9){
            nombreEnlace="Editar datos"
            link="/editar"
          }else if(idRol === 15){
            nombreEnlace="Servicios DB y AZURE"
            link="/sftp"
          }else if(idRol === 16){
            nombreEnlace="Alta de Proveedores"
            link="/alta-proveedores"
          }else if(idRol === 18){
            nombreEnlace="Seguridad"
            link="/seguridad"
          }else if(idRol === 19){
            nombreEnlace="Facturas V3"
            link="/facturas-v3"
          }else if(idRol === 25){// cambiar por rol avisos
            nombreEnlace="Avisos"
            link="/avisos"
          }else if(idRol === 27){// cambiar por rol log
            nombreEnlace="Logs Invoices"
            link="/logs-invoices"
          }
          return (
            <li key={i} > <a href={link} onClick={() => this.handleClick(link)} className={activeLink === link ? 'activem' : ''}>{nombreEnlace }</a></li>
          )
        }
      )

    return (
      <div className='container pad-submenu'>
        <label htmlFor="show-menu" className="show-menu"><i className="fa-solid fa-bars white"></i></label>
        <input type="checkbox" id="show-menu" className='checkbox-menu' ></input>
        <ul className='menu' id="menu">
            {opcionesMenu}
        </ul> 
      </div>
    );
    }
  }

export default Menu;