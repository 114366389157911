/**
* Configura las rutas una vez logueados
*/
import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Footer from '../components/Footer/Footer';
import NotFoundAdmin from "./NotFound/NotFoundAdmin";
import PaginaInicio from "./Home/index"
import HeaderAdmin from '../components/Header/HeaderAdmin';
import InformacionProveedor from "./Admin/InformacionProveedor";
import VistaAdminUsuarios from "./Admin/VistaAdminUsuarios";
import VistaBajaProveedorres from "./Admin/VistaBajaProveedores";
import VistaAdminUsuariosSSC from "./Admin/VistaAdminUsuariosSSC";
import VistaLogs from "./Admin/VistaLogs";
import VistaCierrePerido from "./Admin/VistaCierrePerido";
import VistaOrdenesCompra from "./Admin/VistaOrdenesCompra";
import VistaAltaProveedores from "./Admin/VistaAltaProveedores";
import Menu from "./Home/Menu";
import InformacionProveedorEdit from "./Admin/InformacionProveedorEdit";
import VistaSFTP from "./Admin/VistaSFTP";
import VistaSeguridad from "./Admin/VistaSeguridad";
import VistaFacturasV3 from "./Admin/VistaFacturasV3";
import VistaAvisos from "./Admin/VistaAvisos";
import VistaLogsInvoices from "./Admin/VistaLogsInvoices";

export const SignOutButton = (props) => {
  return (
    <React.StrictMode>
      <Router>
        <HeaderAdmin name={props}/>
        <Menu/>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path="/home" component={PaginaInicio} />
            <Route exact path="/aprobaciones/:id" component={InformacionProveedor} />
            <Route path="/administracion-usuarios" component={VistaAdminUsuarios} />
            <Route path="/baja-proveedores" component={VistaBajaProveedorres} />
            <Route path="/usuarios-ssc" component={VistaAdminUsuariosSSC} />
            <Route path="/logs" component={VistaLogs} />
            <Route path="/logs-invoices" component={VistaLogsInvoices} />
            <Route path="/periodo-cierre" component={VistaCierrePerido} />
            <Route path="/ordenes-compra" component={VistaOrdenesCompra} />
            <Route path="/facturas-v3" component={VistaFacturasV3} />
            <Route path="/editar" component={InformacionProveedorEdit} />
            <Route path="/alta-proveedores" component={VistaAltaProveedores} />
            <Route path="/sftp" component={VistaSFTP} />
            <Route path="/seguridad" component={VistaSeguridad} />
            <Route path="/avisos" component={VistaAvisos} />
            <Route path="*" component={NotFoundAdmin}/>  
          </Switch>
          <br/><br/><br/>
          <Footer/>
      </Router>
      
    </React.StrictMode>
  )
}