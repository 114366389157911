/**
 * TABLA ORDENES DE COMPRA VISTA RECHAZAR FACTURAS
*/

import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Button,
    Scrolling,
    Selection,
    ColumnChooser,
    Export,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import LoadPanel from 'devextreme-react/load-panel';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Modal from "react-bootstrap/Modal";
import {API_PATH } from '../../siteConfig';
import SubirFactura from './SubirFactura';

const allowedPageSizes = [25, 50, 75, 100, 150, 200,'all'];//configura el número de filas que se mostraran en la tabla

class TablaOrdenesCompraPurchaser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
             //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            showEmployeeInfo: false,
            displayMode: 'compact',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showHeaderFilter: true,
            selectedItemKeys: [],
            //Carga de datos
            data: [],
            loading: true,
            //Modal
            isOpen: false,
            isOpenEdit: false,
            descargando: false,
            rechazado: false,
            originalColumns: [],
            visibleColumns: [],
            isDataLoaded: false,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.descargarPDF = this.descargarPDF.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.descargarZip = this.descargarZip.bind(this);
        this.rechazarOrden = this.rechazarOrden.bind(this);
        this.isUploadDisabled = this.isUploadDisabled.bind(this);
        this.isRejectDisabled = this.isRejectDisabled.bind(this);
        this.dataGridRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
          this.setState({ isDataLoaded: !this.props.loading });
        }
    }

    
    //OBTENER LISTA DE ORDENES DE COMPRA Y CONFIGURACION COLUMNAS
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchOrdenes();
        }, 100);

        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
            const columns = dataGrid.instance.getVisibleColumns();
            this.setState({
                originalColumns: columns,
                visibleColumns: columns.map(column => column.dataField),
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchOrdenes() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'get_purchase_orders/all', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()
            } else {
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
          .then((responseJson) => {
            this.setState({ data: responseJson, loading: false, isDataLoaded:true });
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
        
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.resetColumns.bind(this),
                    text:"Limpiar filtros ",
                },
            },
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
            data: []
        });
        setTimeout(() => this.fetchOrdenes(), 10);;
    }

    /*ACTUALIZAR TABLA DESPUES DE SUBIR FACTURAS*/
    refreshDataGrid() {
        window.location.reload(false);
    }

    /**
    * Abre/cierra modal con formulario para subir factura
    */
     openModal = (e) => this.setState({ 
        isOpen: true ,
        dataOrden:e.row.data // obtiene la informacion por fila y se la envia al formulario para enviar facturas
    });
    closeModal = () => this.setState({ isOpen: false });



    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA SUBIR ORDENES
    */
    isChief(Approved_Internal) {
        if(Approved_Internal === "Cancelado"){
        return true
        }else{
            return false
        }
    }
    isUploadDisabled(e) { 
        return this.isChief(e.row.data.Approved_Internal)
    }



    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA RECHAZAR y DESCARGAR ZIP
    */
    isChiefR(Approved_Internal) {
        if(Approved_Internal === "Pendiente de descarga" || Approved_Internal === "Descargado"){
        return false
        }else{
            return true
        }
    }
    isRejectDisabled(e) { 
        return this.isChiefR(e.row.data.Approved_Internal)
    }
      
    /*
    * Descarga el PDF
    */
    descargarPDF(e){
        const Purchasing_Document = e.row.data.Purchasing_Document
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'get_purchase_orders/download_pdf/'+ Purchasing_Document, options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                // procesar un Blob
                const url = DOMPurify.sanitize(window.URL.createObjectURL(data));
                const link = document.createElement('a');
                link.href = url;
                link.download = DOMPurify.sanitize(`${Purchasing_Document}.pdf`);
                link.click();
                link.remove();
            } else {
              // procesar un JSON
             if(data.status_code){
                Swal.fire({
                    title: DOMPurify.sanitize(`${data.detail}`),
                    icon: 'info',
                    showConfirmButton: true,
                    confirmButtonColor: '#3085d6',
                })
             }
            }
        })
        .catch(error => {
            throw error;
        });
    }

    /*
    * Función para rechazar las ordenes
    */
    rechazarOrden(e) {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        const Purchasing_Document = e.row.data.Purchasing_Document;
    
        Swal.fire({
            text: 'Escribe el motivo del rechazo',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Rechazar',
            preConfirm: (motivo) => { // Captura el valor del input
                const options = {
                    method: 'PATCH',
                    headers: { 
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({ motivo }) // Envía el motivo en el cuerpo de la solicitud
                };
    
                return fetch(API_PATH + 'invoices_purchase_orders/DeclineInvoices/?Purchase_Document=' + Purchasing_Document, options)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    })
                    .then(result => {
                        if(result.status_code){
                            Swal.fire({
                                text: DOMPurify.sanitize(`${result.detail}`),
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                this.refreshData();
                                }
                            })
                            
                        }else{
                            Swal.fire({
                                text: DOMPurify.sanitize(`${result.detail}`),
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                            })
                        }
                        this.setState({ rechazado: false });
                    })
            },
        });
    }
    

   
    /*
    * Descarga el ZIP
    */
    descargarZip(e){
        const Purchasing_Document = e.row.data.Purchasing_Document
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        this.setState({ descargando: true })
        fetch(API_PATH + 'invoices_purchase_orders/Download_File/'+ Purchasing_Document, options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                // procesar un Blob
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.click();
                link.remove();
                
            } else {
              // procesar un JSON
             if(data.status_code){
                Swal.fire({
                    text: DOMPurify.sanitize(`${data.detail}`),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((data) => {
                    if (data.isConfirmed) {
                      this.refreshData();
                    }
                })
             }
            }
            this.setState({ descargando: false });
        })
        .catch(error => {
            throw error;
        });
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }


    /*
    * Descarga seleccionados
    */

    descargaSelect(e){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        var purchaseIds =[];
        this.state.selectedItemKeys.forEach((key) => {
            purchaseIds.push(key);
        });

        // const dataBody = {
        //     purchaseIds
        // };
        // const jsonString = this.convertObjectToJsonString(dataBody);

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body:JSON.stringify(purchaseIds)
        }
        fetch(API_PATH + 'invoices_purchase_orders/Download_Selected_Files/', options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                // procesar un Blob
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.click();
                link.remove();
                
            } else {
              // procesar un JSON
             if(data.status_code){
                Swal.fire({
                    text: DOMPurify.sanitize(`${data.detail}`),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((data) => {
                    if (data.isConfirmed) {
                      this.refreshData();
                    }
                })
             }
            }
        })
        .catch(error => {
            throw error;
        });
    }

    /*
    * Descarga masiva
    */
    descargarMasiva(e){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'invoices_purchase_orders/Download_All_Files/', options)
        .then(response => {
            if (response.ok) {
              if (response.headers.get('content-type') === 'application/json') {
                return response.json();
              } else {
                return response.blob();
              }
            } else {
              throw new Error('Response not OK');
            }
          })
        .then(data => {
            // procesar la respuesta en función del tipo de dato
            if (data instanceof Blob) {
                // procesar un Blob
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.click();
                link.remove();
                
            } else {
              // procesar un JSON
             if(data.status_code){
                Swal.fire({
                    text: DOMPurify.sanitize(`${data.detail}`),
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((data) => {
                    if (data.isConfirmed) {
                      this.refreshData();
                    }
                })
             }
            }
        })
        .catch(error => {
            throw error;
        });
    }

    //Resetear columnas

    handleColumnVisibilityChanged = (e) => {
        this.setState({
          visibleColumns: e.component.getVisibleColumns().map(column => column.dataField),
        });
    };


    resetColumns = () => {
        const { originalColumns } = this.state;
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
          dataGrid.instance.clearFilter();
          dataGrid.instance.columnOption(null, 'visible', false);
          originalColumns.forEach(column => {
            dataGrid.instance.columnOption(column.dataField, 'visible', true);
          });
          this.setState({
            visibleColumns: originalColumns.map(column => column.dataField),
          });
        }
    };
   
    render() {
        const { isDataLoaded } = this.state;
        return (
            <React.Fragment>
                 <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ of: '.my-table-container' }}
                    visible={!isDataLoaded}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <DataGrid
                   ref={this.dataGridRef}
                    dataSource={this.state.data}
                    keyExpr="Purchasing_Document"
                    showBorders={true}
                    hoverStateEnabled={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    columnWidth={160}
                    selectedRowKeys={this.state.selectedItemKeys}
                    onExporting={this.onExporting}
                    allowColumnReordering={true}
                    columnAutoWidth={true}
                >
                {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* Boton para exportar a excel */}
                <Export enabled={true} allowExportSelectedData={false} />
                <Selection
                    mode="multiple"
                    selectAllMode="allPages"
                    showCheckBoxesMode="onClick"
                />
                <Scrolling columnRenderingMode="virtual" />
                {/* permite seleccionar las columnas para ver */}
                <ColumnChooser
                    enabled={true}
                    mode="select"
                    onVisibilityChanged={this.handleColumnVisibilityChanged}
                />
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={100}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                {/* Columnas, 
                    Cambiar los nombre de datafield por como vengan en la api */}
                    <Column dataField="Purchasing_Document"  caption="Orden de compra" />
                    <Column type="buttons" caption="Enviar Factura" allowHeaderFiltering={false} allowHiding={false}  name="Enviar Facturas">
                        <Button  hint="Upload" icon="import"  onClick={this.openModal}  disabled={this.isUploadDisabled}/>
                    </Column>
                    <Column  dataField="razon_social" caption="Nombre Proveedor"/> 
                    <Column dataField="RFC_Emisor"  caption="RFC Proveedor"/> 
                    <Column  dataField="Invoice_number" caption="Número de la factura"/> 
                    {/* <Column  dataField="Invoice_date" caption="Fecha de factura" dataType="date"/>  */}
                    <Column  dataField="Invoice_uuid" caption="uuid de factura"/> 
                    <Column dataField="Currency"  caption="Moneda"/>
                    <Column  dataField="Document_Date" caption="Fecha documento" dataType="date"/>
                    <Column  dataField="Invoice_date_created" caption="Fecha de carga de factura" dataType="date"/>
                    <Column  dataField="Country" caption="País"/>
                    {/* <Column dataField="Approved_On"  caption="Aprobado"/> */}
                    {/* <Column  dataField="Created_On" caption="Creado"/> */}
                    <Column dataField="Created_By"  caption="Creado por"/>
                    {/* <Column dataField="Company_Code"  caption="Código compañía" /> */}
                    <Column dataField="Supplier"  caption="Proveedor" />
                    <Column dataField="TotalAmount"  caption="Total"/>
                    <Column dataField="end_log_download_zip.Date_download"  caption="Fecha de ultima descarga" dataType="date"/>
                      
                    {/* <Column type="buttons"  caption="Rechazar factura" name="rechazar">
                        <Button hint="Rechazar" icon="clear" onClick={this.rechazarOrden} disabled={this.isRejectDisabled} />
                    </Column>   */}
                    <Column type="buttons"  caption="Descargar PDF" name="pdf">
                        <Button hint="Descargar PDF" icon="pdffile"  className='red' onClick={this.descargarPDF} allowHeaderFiltering={false} />
                    </Column>
                    {/* <Column type="buttons"  caption="Descargar Zip" name="zip">
                        <Button hint="Descargar Archivos" icon="download"  onClick={this.descargarZip} allowHeaderFiltering={false}  disabled={this.isRejectDisabled}/>
                    </Column> */}
                    
                    <Column dataField="Approved_Internal"  caption="Estatus" allowFiltering={true}/>
                    <Column dataField="Invoice_Version"  caption="Versión"/>
                    <Column dataField="Buyer_Name"  caption="Nombre Comprador"/>

                    <Column dataField="RegimenFiscalReceptor"  caption="Regimen Fiscal"/>
                    <Column dataField="MetodoPago"  caption="Método Pago"/>
                    <Column dataField="UsoCFDI"  caption="Uso CFDI"/>
                    <Column dataField="FormaPago"  caption="Forma Pago"/>
                    <Column dataField="TotalImpuestosRetenidos"  caption="Importe retencion"/>
            </DataGrid>
            {/* Contiene el modal para añadir subir factura*/}
            <Modal show={this.state.isOpen} onHide={this.closeModal} >
                <Modal.Header closeButton >Enviar factura</Modal.Header>
                <SubirFactura datosOrden={this.state.dataOrden} actualizar={()=>this.refreshDataGrid()} cerrar={()=>this.closeModal()}/> 
            </Modal>
        </React.Fragment>
        );
    }

    
    onExporting(e) {
        // Obtener filas seleccionadas, si no hay seleccionadas, obtener todos los datos
        let selectedRows = e.component.getSelectedRowsData();
        if (selectedRows.length === 0) {
            selectedRows = e.component.getDataSource().items(); // Exportar todos los datos
        }
    
        // Obtener todas las columnas, incluidas las no visibles
        const allColumns = e.component.getController('columns').getColumns();
        const exportableColumns = allColumns.filter((col) => 
            col.dataField !== 'editar' && col.dataField
        );
    
        // Crear un arreglo de datos solo con las columnas válidas de las filas seleccionadas
        const formattedData = selectedRows.map((row) => {
            const formattedRow = {};
            exportableColumns.forEach((col) => {
                formattedRow[col.caption || col.dataField] = row[col.dataField];
            });
            return formattedRow;
        });
    
        // Crear la hoja de Excel con los datos seleccionados
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Exported Data');
    
        // Guardar el archivo Excel
        XLSX.writeFile(workbook, 'OrdenesCompra.xlsx');
    
        // Cancelar la exportación por defecto de DevExtreme
        e.cancel = true;
    }
}

export default TablaOrdenesCompraPurchaser;