/**
 * INDEX PARA LA VISTA DE LOS LOGS
*/

import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {API_PATH } from '../../siteConfig';
import TableLogs from './TableLogs';

class VistaLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            valorBuscado: 4, //logs
            logAll: [],
            logSFTP:[], 
            logAzure:[],
            logsBills:[],
            loading: true,

        };
        this.descargarLogs = this.descargarLogs.bind(this);
    }

    componentWillMount() {
        //valida que solo los usuarios con rol de logs tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    componentDidMount() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        //Obtiene los logs, logs de azure y logs de sftp
        this.timer = setTimeout(() => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            }
            Promise.all([
                fetch(API_PATH + 'show_file_logs/Azure', options),
                fetch(API_PATH + 'show_file_logs/SFTP', options),
                fetch(API_PATH + 'show_file_logs/Invoices', options),
            ])
                .then(([res1, res2, res3]) => Promise.all([res1.json(), res2.json(), res3.json()]))
                .then(([data1, data2, data3]) => this.setState({
                    logAzure: data1,
                    logSFTP: data2,
                    logsBills: data3,
                    loading: false
                }));
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    descargarLogs(e){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'show_file_logs/ZIP', options)
        .then(response => response.blob())
        .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'logs.zip';
        link.click();
        link.remove();
        
      })
      .catch(error => {
        throw error;
    });
    }
   

    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }
        //convierte en string el resultado
        let logsAzure =this.state.logAzure
        const resultAzure= logsAzure.toString()

        let logsSFTP = this.state.logSFTP
        const resultSFTP = logsSFTP.toString()

        let logsBills = this.state.logsBills
        const resultBills = logsBills.toString()
        if (this.state.loading === true) {
            return(
                <div className="preloader" >
                    <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                                <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="container">
                <br/>
                <br/>
                <h3 className='blue'>Logs</h3>
                
                <div className='row'>
                    <div className='col-md-12 download-logs'>
                        <button className='btn btn-primary' onClick={this.descargarLogs}>Descargar Logs</button>
                    </div>
                </div>
                <br/>
                <h6><b>LOGS </b></h6>
                <hr/>
                <TableLogs/>
                <br/><br/>
                <h6><b>LOGS AZURE</b></h6>
                <hr/>
                {resultAzure.split("\n").map((logs, i) => (
                    <div key={i}>
                        {logs}
                    </div>
                ))
                }
                <br/>
                <h6><b>LOGS SFTP</b></h6>
                <hr/>
                {resultSFTP.split("\n").map((logs, i) => (
                    <div key={i} >
                        {logs}
                    </div>
                ))
                }
                <br/>
                <h6><b>LOGS FACTURAS</b></h6>
                <hr/>
                {resultBills.split("\n").map((logs, i) => (
                    <div key={i} >
                        {logs}
                    </div>
                ))
                }
               <br/>
            </div>
        )
    }
}

export default VistaLogs;
