/**
 * TABLA ADMINISTRACION DE PROVEEDORES
*/
import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Selection,
    Editing,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';

;
//configura el número de filas que se mostraran en la tabla
const allowedPageSizes = [10, 15, 20, 25, 30, 35, 40, 45, 50,'all'];

class TablaBajaProveedores extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
        }, {
            key: 'onClick',
            name: 'On Button Click'
        }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
        //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
        showEmployeeInfo: false,
        displayMode: 'compact',
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        showFilterRow: true,
        showHeaderFilter: true,
        currentFilter: this.applyFilterTypes[0].key,
        selectedItemKeys: [],
        data:[],
        loading: true,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.desactivarProveedor = this.desactivarProveedor.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.isEditDisabled = this.isEditDisabled.bind(this);
        this.dataGridRef = React.createRef();
    } 

    //Obtiene lista de proveedores
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchProveedores();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchProveedores() { 
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'show_all_users/azure', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
                return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then((responseJson) => {
            if(responseJson.status_code === 405){
                Swal.fire({
                    title: DOMPurify.sanitize(`${responseJson.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }else{
                this.setState({ data: responseJson, loading: false });
            }  
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
        
        {// Activar proveedor
            location: 'after', 
            widget: 'dxButton',
            options: {
                icon: 'unlock',
                text:"Activar",
                onClick: (eventArgs) => {
                    this.desactivarProveedor(eventArgs, true);
                }
            }
        },
        {// Desactivar proveedor
            location: 'after', 
            widget: 'dxButton',
            options: {
                icon: 'lock',
                text:"Desactivar",
                onClick: (eventArgs) => {
                    this.desactivarProveedor(eventArgs, false);
                }
            }
            },
        {   //Actualizar tabla
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: this.refreshData.bind(this),
            },
        },
        { //Limpiar filtros
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.clearFilters.bind(this),
                text:"Limpiar filtros ",
            },
        },
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
        data: []
        });
        setTimeout(() => this.fetchProveedores(), 10);;
    }

    //Limpiar filtros
    clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
            dataGrid.instance.clearFilter();
        }
    };   


    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA EDITAR USUARIO SI EL USUARIO ESTA INACTIVO
    */
    isChief(activo) {
        if(activo === false){
        return false
        }else{
            return true
        }
       // return estatus && ['0'].indexOf(estatus.trim()) >= 0;
    }
    isEditDisabled(e) { 
        return this.isChief
    }

    /**
    * Convierte la columna Estatus en activo o inactivo el estatus del usuario, ya que lo devuelve en 1 o 0
    */
    cellRenderP(data) {
        let estatus
        if(data.data.status === true) {
                estatus= "Activo"
        }else{
            estatus= "Inactivo"
        }
        return <span>{estatus}</span>;
    }

    cellRenderDB(data) {
        let estatus
        if(data.data.status_DB === true) {
                estatus= "Activo"
        }else{
            estatus= "Inactivo"
        }
        return <span>{estatus}</span>;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
            if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }


    /**
    * Desactiva las filas (proveedores) seleccionadas
    * NO se elimina de la base de datos, solo se desactiva 
    */
    desactivarProveedor(e, desactivar) {
        e.event.preventDefault();
        var mensaje = ( desactivar === false) ?'Desactivar' : 'Activar';
        var accion = ( desactivar === false) ? false : true;

        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        var deleteIds =[];
        this.state.selectedItemKeys.forEach((key) => {
            deleteIds.push(key);
        });
        Swal.fire({
            title: DOMPurify.sanitize(`¿Estás seguro de ${mensaje} este/estos proveedor/es?`),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#bb2d3b',
            confirmButtonText: ` ${mensaje}`,
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {
            if (result.isConfirmed) {
                // const dataBody = {
                //     id_list: deleteIds ,
                //     status: accion
                // };
                // const jsonString = this.convertObjectToJsonString(dataBody);

                const options = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify ({
                        id_list: deleteIds ,
                        status: accion
                    })

                }
                fetch(API_PATH + 'change_status_users/azure', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                        return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
                .then(result => {
                
                    if(result.status_code === 202){// si en responseCode arroja un 202  manda mensaje de exito 
                        Swal.fire({
                            text: "El proveedor se actualizó correctamente",
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: "success",
                            timer: 3000
                        })
                        this.refreshData()
                    } else{// si no arroja un 405 en responseCode de desactivar proveedor
                        Swal.fire({
                            text: "Ocurrio un error",
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }  
                })
                .catch(error => {
                    Swal.fire({
                                text: "Ocurrio un error",
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
                });
            }
        })
    }


    /**
    * Actualiza el nombre del usuario 
    */
    onSaving = (e) => {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken

        let row = e.changes
        let id
        row.forEach(row =>id = row.key);

        let name
        row.forEach(row =>name= row.data.name);

        const dataBody = {
            name: name,
            id: id
        };
        const jsonString = this.convertObjectToJsonString(dataBody);

        const options = {
        method: 'PATCH',
        headers: { 
            'Content-Type': 'application/json' ,
            Authorization: 'Bearer ' + token,
        },
        body: jsonString
        }
        fetch(API_PATH + 'update_user/azure/', options)
        .then(response => response.json())
        .then(result => {
            if(result.status_code === 202){// si en responseCode arroja un 202  manda mensaje de exito 
                Swal.fire({
                    text: "El usuario se actualizó correctamente",
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: "success",
                    timer: 3000
                })
                this.refreshData()
            } else{// si no arroja un 202 en responseCode de desactivar proveedor
                Swal.fire({
                    text: "Ocurrio un error",
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }  
        })
        .catch(error => {
            Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });
    }

    render() {
    if (this.state.loading === true) {
        return(
            <div className="preloader" >
                <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id"
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                selectedRowKeys={this.state.selectedItemKeys}
                onToolbarPreparing={this.onToolbarPreparing}
                onEditorPreparing={this.onEditorPreparing}
                // onSelectionChanged={this.onSelectionChanged}
                onSaving={this.onSaving}
            >
                {/* Buscador */}
                <Selection mode="multiple" />
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                {/* Modo de edición en línea */}
                <Editing
                    mode="batch"
                    allowUpdating={true}
                />
                {/* Columnas, 
                Cambiar los nombre de datafield por como vengan en la api */}
                <Column dataField="name" caption="Nombre" allowEditing={this.isEditDisabled} />
                <Column dataField="email" caption="Correo" allowEditing={false}/>
                <Column dataField="Estatus"  cellRender={this.cellRenderP} allowHeaderFiltering={false} width={100} allowEditing={false}/>
                <Column dataField="date_modified"  allowEditing={false} caption="Fecha de modificación" dataType="date"/> 
            </DataGrid>
        </React.Fragment>
    );
    }

/**
    * Obtiene las filas seleccionadas
    * Verifica si tiene estatus activo o no para que se desahbilite la opcion de seleccionar
    */

    isSelectable(item) {
        return item.status;
    }

    isSelectAll(dataGrid) {
        let items = [];
        dataGrid.getDataSource().store().load().done(function (data) {
            items = data;
        });
        let selectableItems = items.filter(this.isSelectable);
        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys.length) {
            return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    }

    onEditorPreparing(e) {
        let dataGrid = e.component;
        if (e.command === "select") {
            if (e.parentType === "dataRow" && e.row) {
                if (!this.isSelectable(e.row.data))
                    e.editorOptions.disabled = true;
                } else if (e.parentType === "headerRow") {
                    e.editorOptions.onInitialized = (e) => {
                        this.setState({
                        'selectAllCheckBox': e.component
                        })
                    };
                    e.editorOptions.value = this.isSelectAll(dataGrid);
                    e.editorOptions.onValueChanged = (e) => {
                    if (!e.event) {
                        if (e.previousValue && !this.state.checkBoxUpdating) {
                            e.component.option("value", e.previousValue);
                        }
                        return;
                    }
                    if(this.isSelectAll(dataGrid) === e.value) {
                        return;
                    }
                    e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                    e.event.preventDefault();
                }
            }
        }
    }

    onSelectionChanged(e) {
        let deselectRowKeys = [];
        e.selectedRowsData.forEach((item) => {
            if (!this.isSelectable(item))
                deselectRowKeys.push(e.component.keyOf(item));
        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }
        this.setState({
            'checkBoxUpdating': true
        });
        if (this.state.selectAllCheckBox) {
            this.state.selectAllCheckBox.option && this.state.selectAllCheckBox.option("value", this.isSelectAll(e.component));
        }
        this.setState({
            'checkBoxUpdating': false
        });
    }  



}

export default TablaBajaProveedores;