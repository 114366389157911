/**
 * HEADER PARA LA ADMINISTRACIÓN UNA VEZ LOGUEDADOS
*/

import React from "react";
import logo from '../../assets/img/ipg-logo.png'
import { useMsal } from "@azure/msal-react";
//import { useLocation } from "react-router-dom";


const HeaderAdmin = (props) => {
    //asignación de variable de ubicación
   // const location = useLocation();

    //desestructura el nombre de la ruta desde la ubicación
    //const { pathname } = location;

    //método de división de javascript para obtener el nombre de la ruta en la matriz
    //const splitLocation = pathname.split("/");

    const { instance } = useMsal();

    //función para el delogueo de microsoft
    const handleLogout = (logoutType) => {
        if (logoutType === "popup") { 
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <div className='container-fluid'>
          <div className='row menu-principal'>
            <div className='col-md-6'>
                <a className="navbar-brand" href="/"><img src={logo} className="img-fluid logo" alt="logo-hci"/></a>
            </div>
            <div className='col-md-6 logout white'>
                <span><i className="fa-solid fa-user"></i> &nbsp; {props.name.givenName} {props.name.surname} </span>  | &nbsp;
                <span className="cursor white " onClick={() => handleLogout("popup")}>Salir <i className="fas fa-sign-out-alt"></i></span>
            </div>
          </div>
        </div>
    )


   
}

export default HeaderAdmin