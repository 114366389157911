/**
 * INDEX PARA LA VISTA DE SEGURIDAD
*/

import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import InformacionLoginEdit from './InformacionLoginEdit';
import SeguridadUsuariosExternos from './SeguridadUsuariosExternos';
// import SeguridadPoliticaPassword from './SeguridadPoliticaPassword';

class VistaSeguridad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            valorBuscado: 18, //seguridad
        };
    }
    
    componentWillMount() {
        //valida que solo los usuarios con rol de proveedor tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div className="container">
                <br/><br/>
                <h3 className='blue'>Seguridad</h3>
                <br/>
                <Tabs
                    defaultActiveKey="login"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {/* <Tab eventKey="password" title="Politica de password">
                       <SeguridadPoliticaPassword/>
                    </Tab> */}
                    <Tab eventKey="login" title="Login">
                    <InformacionLoginEdit/>
                    </Tab>
                    <Tab eventKey="contact" title="Usuarios externos">
                       <SeguridadUsuariosExternos/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default VistaSeguridad;
