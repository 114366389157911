/**
 * TABLA ADMINISTRACION DE AVISOS
*/
import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    Button,
    Scrolling,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import RegistrarAviso from './RegistrarAviso.js';
import EditarAviso from './EditarAviso.js';


const allowedPageSizes = [20, 40, 60, 80, 100,'all'];//configura el número de filas que se mostraran en la tabla

class TablaAvisos extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
        }, {
            key: 'onClick',
            name: 'On Button Click'
        }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
            //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            showEmployeeInfo: false,
            displayMode: 'compact',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: this.applyFilterTypes[0].key,
            selectedItemKeys: [],
            //Modal
            isOpen: false,
            isOpenEdit: false,
            //Carga de datos
            data: [],
            loading: true,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
       
        this.refreshData =  this.refreshData.bind(this);
        this.isEditDisabled = this.isEditDisabled.bind(this);



        this.eliminarRegistro = this.eliminarRegistro.bind(this);
       
        this.dataGridRef = React.createRef();
    }

    /*
    *OBTIENE LA LISTA DE AVISOS
    */
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fecthAvisos();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fecthAvisos() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'portal_avisos/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {//Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },
            { //Añadir aviso
                location: 'after',
                widget: 'dxButton',
                options: {
                    onClick: this.openModal,
                    icon:'add'
                },
            },
        );
    }

    /*
    *ACTUALIZA TABLA DESPUES DE EDITAR O GUARDAR AVISO
    */
    refreshDataGrid() {
    window.location.reload(false);
    }

    /*
    *ACTUALIZA LA TABLA
    */
    refreshData() {
    this.setState({
        data: []
    });
    setTimeout(() => this.fecthAvisos(), 10);
    }


    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA EDITAR USUARIO SI EL USUARIO ESTA INACTIVO
    */
    isChief(activo) {
        let estatus= JSON.stringify(activo)
        return estatus && ['0'].indexOf(estatus.trim()) >= 0;
    }
    isEditDisabled(e) {
        return this.isChief(e.row.data.activo);
    }

    /**
    * Abre/cierra modal con formulario para añadir un usuario
    */
    openModal = (e) => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    /**
    * Abre/cierra modal con formulario para editar un usuario
    */
    openModalEdit = (e) => this.setState({ 
        isOpenEdit: true ,
        dataUser:e.row.data // obtiene la informacion por fila y se la envia al formulario para editar
    });
    closeModalEdit = () => this.setState({ isOpenEdit: false });

    /**
    * Convierte la columna Estatus en activo o inactivo el estatus del usuario, ya que lo devuelve en 1 o 0
    */
    cellRenderP(data) {
        let estatus = ( data.data.activo === 0) ? 'Inactivo' : 'Activo'
        return <span>{estatus}</span>;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Elimina las filas (usuarios) seleccionadas
    */
    eliminarRegistro (e){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        let id = e.row.data.ID
        Swal.fire({
            title: '¿Estás seguro de eliminar este aviso?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#bb2d3b',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
        })
        .then((result) => {//si confirman la eliminación del AVISO
            if (result.isConfirmed) {
                fetch(API_PATH + `portal_avisos/delete_aviso?ID=${id}`, {
                    method: "DELETE",
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token
                    }
                })
                  .then(response => response.json())
                  .then(result => {
                    if (result.responseCode === 200) {
                      Swal.fire({
                              text: DOMPurify.sanitize(`${result.detail}`),
                              showCancelButton: false,
                              showConfirmButton: false,
                              icon: "success",
                              timer: 3000
                              })
                              this.refreshData()
                    } else {
                      Swal.fire({
                          text: DOMPurify.sanitize(`${result.detail}`),
                          icon: 'error',
                          showConfirmButton: true,
                          confirmButtonColor: '#bb2d3b',
                      })
                    }
                  })
                  .catch(error => {
                      Swal.fire({
                          text: "Ocurrio un error",
                          icon: 'error',
                          showConfirmButton: true,
                          confirmButtonColor: '#bb2d3b',
                          })
                      throw error;
                  });
            }
        })
    }
    
    render() {
        if (this.state.loading === true) { // muestra un loading mientras carga la página y datos de la tabla
            return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
        return (
            <React.Fragment>
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                keyExpr="ID"
                showBorders={true}
                hoverStateEnabled={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                onToolbarPreparing={this.onToolbarPreparing}
                selectedRowKeys={this.state.selectedItemKeys}
            > 
                
                
                {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                <Scrolling columnRenderingMode="virtual" />
                { /* Columnas, 
                Cambiar los nombre de datafield por como vengan en la api */}
                <Column type="buttons" caption="Editar" allowHiding={false} width={50} name="Ver más">
                    <Button  hint="Edit" icon="edit" onClick={this.openModalEdit} disabled={this.isEditDisabled} />
                </Column>
                <Column dataField="Nombre" caption="Nombre" />
                <Column dataField="Texto" caption="Descripción" />
                <Column dataField="Activo" caption="Estatus"  />
                <Column type="buttons"  caption="Eliminar" name="eliminar">
                    <Button  hint="Eliminar" icon="trash" onClick={this.eliminarRegistro}/>
                </Column> 
            </DataGrid>
            {/* Contiene el modal para añadir nuevo aviso*/}
            <Modal show={this.state.isOpen} onHide={this.closeModal}  dialogClassName="modal-90w">
                <Modal.Header closeButton >Añadir Aviso</Modal.Header>
                <RegistrarAviso actualizar={()=>this.refreshDataGrid()}/>
            </Modal>
            {/* Contiene el modal para editar un aviso*/}
            <Modal show={this.state.isOpenEdit} onHide={this.closeModalEdit}  dialogClassName="modal-90w">
                <Modal.Header closeButton >Editar Aviso</Modal.Header>
                <EditarAviso datosUsuario={this.state.dataUser} actualizar={()=>this.refreshDataGrid()}/>
            </Modal>
        </React.Fragment>
        );
    }
    
    /**
    * Obtiene las filas seleccionadas
    * Verifica si tiene estatus activo o no para que se desahbilite la opcion de seleccionar
    */

    isSelectable(item) {
        return item.activo;
    }
    
    isSelectAll(dataGrid) {
        let items = [];
        dataGrid.getDataSource().store().load().done(function (data) {
          items = data;
        });
        let selectableItems = items.filter(this.isSelectable);
        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys.length) {
          return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    }
    
    
    

}

export default TablaAvisos;