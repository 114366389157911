/**
 * INDEX PARA LA VISTA DE ORDENES DE COMPRA SEGUN ROL
*/
import React, { Component } from 'react';
import TablaOrdenesCompraSSC from './TableOrdenesCompraSSC';
// import TablaOrdenesCompraReject from './TableOrdenesCompraReject';
import TablaOrdenesCompraAdmin from './TableOrdenesCompraAdmin';
import TablaOrdenesCompraProveedor from './TableOrdenesCompraProveedor';
import TablaOrdenesCompraPurchaser from './TableOrdenesCompraPurchaser';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';

export default class VistaOrdenesCompra extends Component {
  constructor(props) {
    super(props);
    this.state = {            
      data: [],
      loading: true,
    }
  }

  /*
  *OBTIENE LA LISTA DE AVISOS
  */
  componentDidMount() {
      this.timer = setTimeout(() => {
          this.fecthAvisos();
      }, 100);
  }

  componentWillUnmount() {
      clearTimeout(this.timer);
  }

  fecthAvisos() {
      const data = JSON.parse(sessionStorage.getItem('userData'));
      const token = data.AccessToken
      fetch(API_PATH + 'portal_avisos/', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
      },
      })
      .then(response => {
          if (response.ok) {
            return response.json()// si el estatus code arroja un 200  continua
          } else {// si no arroja el estatus code del error
              Swal.fire({
                  title: response.status,
                  icon: 'error',
                  showConfirmButton: true,
                  confirmButtonColor: '#bb2d3b',
              })
          }
        })
      .then((responseJson) => {
          this.setState({ data: responseJson, loading: false });
      })
      .catch(error => {
          throw error;
      });
  }

  render() {
    const data = JSON.parse(sessionStorage.getItem('userData'));
    const array = data.roles;
  
  //  const array = [5, 7];
    let textoAMostrar = '';
    let titulo = 'Ordenes de compra';

    if (array.includes(7) && array.includes(10)) {
      textoAMostrar = <TablaOrdenesCompraAdmin/>;
    } else if (array.includes(5) && array.includes(7)) {
      textoAMostrar = <TablaOrdenesCompraSSC/>;
      titulo="Descarga de facturas de las ordenes de compra"
    } else if (array.includes(7)) {
      textoAMostrar = <TablaOrdenesCompraSSC/>;
      titulo="Descarga de facturas de las ordenes de compra"
    } else if (array.includes(10)) {
      textoAMostrar = <TablaOrdenesCompraAdmin/>;
    } else if (array.includes(21) && array.includes(22)) {
      textoAMostrar = <TablaOrdenesCompraPurchaser/>;
      titulo="Facturas ordenes de compra"
    }else if (array.includes(5)) {
      textoAMostrar = <TablaOrdenesCompraProveedor/>;
    } else {
      textoAMostrar = ''
      Swal.fire({
        title: `No tienes permisos para visualizar ordenes de compra`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#bb2d3b',
    })
    }

    let visibleAvisos = (array.includes(9))? true : false; //cambiar por rol avisos


    return (
      <div className='container'>
        <br/>
        <br/>
        <h3 className='blue'>{titulo}</h3>
        <br/>
        {visibleAvisos ? (
        <div>
          {this.state.data.map((item, index) => (
            <div className="alert alert-warning" role="alert" key={index}>
              {item.Nombre}<br/>
              {item.Texto}
              </div>
          ))}
          </div>
          ) : null}
        {textoAMostrar}
      </div>);
  
  }
  
}
