import React, { Component } from 'react';
import axios from 'axios';
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { withTranslation } from 'react-i18next';
import carta from '../../assets/documents/Carta_de_Ingresos.docx'
import doc1 from '../../assets/documents/AVISO_DE_CONFIDENCIALIDAD_MCCANN_PROVEEDORES.pdf'
import doc2 from '../../assets/documents/AVISO_DE_PRIVACIDAD_MCCANN_PROVEEDORES.pdf'
import doc3 from '../../assets/documents/CODIGO_DE_CONDUCTA_PROVEEDORES.pdf'

class Documentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data: [],
        loading: true,
        files: [],
        errors: {},
        uploadedFiles: JSON.parse(sessionStorage.getItem('uploadedFiles')) || [],
        };
        this.handleCheck = this.handleCheck.bind(this);
    }

    /*
    *OBTIENE LA LISTA DE USUARIOS
    */
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchDocuments();
        }, 100);
    }

    fetchDocuments() {
        const {tipoProveedor } = this.props;
        let tipoProv
        if(tipoProveedor ==="moral"){ //MORAL
            tipoProv=2
        }else if(tipoProveedor ==="fisica"){ //FISICA
            tipoProv =1
        }else if(tipoProveedor === "extranjero"){//EXTRANJERO
            tipoProv = 4
        }

        fetch(API_PATH + 'archivos/documentos?tipo_proveedor=2&tipo_persona='+tipoProv, {
        method: 'GET'
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: response.status,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    //LLAMA A LA FUNCIÓN PARA REGRESAR AL PASO ANTERIOR
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    /**
    * Llama a la función para guardar los datos
    * Primero valida que se hayan aceptado los avisos de privacidad y confidencialidad
    * Despues valida que los campos obligatorios esten llenos y tengan formato válido
    */
  handleCheck = async (e) => {
    e.preventDefault();
    if (this.state.isCheckedCC === true) {
        document.getElementById('valid_AC').textContent = '';
        this.setState({ isLoading: true });
        Swal.fire({
            title: 'Guardando...',
            text: 'Por favor, espera mientras se guarda la información.',
            icon: 'info',
            showConfirmButton: false,
        });
        try {
            const result = await this.props.submitData();
            if (result && result.success === true) {
            this.setState({ isSubmitButtonDisabled: true });
            // Mostrar la alerta de éxito
            Swal.close();
            Swal.fire({
                text: result.detail,
                showCancelButton: false,
                showConfirmButton: true,
                icon: "info",
            }).then((result) => {
                if (result.isConfirmed) {
                    // Redirige al home
                sessionStorage.removeItem('uploadedFiles');
                window.location.href = "/";
                }
            });
            setTimeout(() => {
                const confirmButton = document.querySelector('.swal2-confirm');
                if (confirmButton && !confirmButton.classList.contains('swal2-confirm-clicked')) {
                    // Si el botón de confirmación no ha sido presionado después de 5 segundos, redirige al home
                    sessionStorage.removeItem('uploadedFiles');
                    window.location.href = "/";
                }
            }, 5000);
            } else {
            console.error('submitData error:', result);
            // Mostrar la alerta de error
            Swal.fire({
                text: result.detail || 'Ocurrió un error desconocido',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            });
            }
        } catch (error) {
            console.error('submitData error:', error);
            Swal.fire({
            text: 'Ocurrió un error',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#bb2d3b',
            });
            this.setState({ isSubmitButtonDisabled: false });

        } finally {
            this.setState({ isLoading: false });
        }
    } else {
      document.getElementById('valid_AC').textContent = `${this.props.t('Confirm.error')}`;
    }
    };


    handleFileChange = (e, fileId) => {
        const files = Array.from(e.target.files);
        const updatedFiles = files.map(file => ({
        file,
        name: file.name,
        fileId,
        }));
        this.setState(prevState => ({
        files: [...prevState.files.filter(item => item.fileId !== fileId), ...updatedFiles],
        }));
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { files } = this.state;
        const uploadedFiles = [];
        const errors = {};
        // Validar las extensiones de los archivos antes de enviarlos
        for (const { file, fileId } of files) {
          const allowedExtensions = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'xlsx', 'pdf'];
          const extension = file.name.split('.').pop().toLowerCase();
          if (!allowedExtensions.includes(extension)) {
            errors[fileId] = 'El archivo debe tener una extensión válida.';
          }
        }
        // Actualizar el estado con los errores
        this.setState({ errors });
        // Si no hay errores de validación, enviar los archivos
        if (Object.keys(errors).length === 0) {
            Swal.fire({
                title: 'Guardando...',
                text: 'Por favor, espera mientras se suben los archivos.',
                icon: 'info',
                showConfirmButton: false,
              });
          for (const { file, fileId } of files) {
            const formData = new FormData();
            formData.append('file', file);
            
            try {
                const response = await axios.post(
                    API_PATH + "archivos/carga/?id_documento=" + fileId,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                ); 
            //   console.log('Archivo enviado:', response);
                if(response.data.success){
                    uploadedFiles.push(response.data.detail);
                }
                
            } catch (error) {
                console.error('Error al enviar archivo:', error);
                sessionStorage.removeItem('uploadedFiles');
                Swal.fire({
                    text:"Ocurrió un error al enviar los archivos, intenta nuevamente",
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                }) 
                sessionStorage.removeItem('uploadedFiles');
                // Si se produce un error, reinicia el estado de los archivos
                this.setState({ files: [], errors: {} });
                return; // Sale del bucle para evitar más intentos de envío
            }  
              
        }
        Swal.close(); 
         // Actualizar el estado con todos los nombres de archivos al finalizar el envío
         this.setState({ uploadedFiles });
         // Almacenar uploadedFiles en sessionStorage
         sessionStorage.setItem('uploadedFiles', JSON.stringify(uploadedFiles));
        } 
    };
      
  
    limpiarSessionStorageYRenderizar() {
        sessionStorage.removeItem('uploadedFiles');
        this.setState({ uploadedFiles: [], files: [] });
    }

    render() {
        const { t, contactoAgencia, tipoProveedor } = this.props;
        var tipoProv
        
        if(tipoProveedor ==="moral"){ //MORAL
            tipoProv="Persona Moral"
        }else if(tipoProveedor ==="fisica"){ //FISICA
            tipoProv ="Persona Física"
        }else if(tipoProveedor === "extranjero"){//EXTRANJERO
            tipoProv = "Proveedor Extranjero Moral"
        }
        const { errors } = this.state;
        const visibleAnuncio= (tipoProveedor === "moral" || tipoProveedor === "fisica") ? false : true
        const visibleCartaIngresos= ( tipoProveedor === "fisica") ? true : false
        const visibleCartaPoder= (tipoProveedor === "moral" || tipoProveedor === "extranjero") ? true : false
        const arrayFiles = sessionStorage.getItem("uploadedFiles")
        let files = JSON.parse(arrayFiles);
        const uploadedFiles = JSON.parse(sessionStorage.getItem('uploadedFiles')) || [];

        if (this.state.loading === true) { // muestra un loading mientras carga la página y datos de la tabla
            return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
        if(files !== null){
            return(
                <div>
                    <br/><br/>
                    <div className="stepper-wrapper">
                        <div className="stepper-item completed">
                        <div className="step-counter">1</div>
                        <div className="step-name">{this.props.t('TitleSteps.contact')}</div>
                        </div>
                        <div className="stepper-item completed">
                        <div className="step-counter">2</div>
                        <div className="step-name">{this.props.t('TitleSteps.datosGen')}</div>
                        </div>
                        <div className="stepper-item completed active">
                        <div className="step-counter">3</div>
                        <div className="step-name">{this.props.t('TitleSteps.documents')}</div>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <h1 className='intro-registro'>{this.props.t('Influencer.text2')}</h1>
                    </div>
                    <div className='col-md-12'>  
                        <div className="alert alert-success d-flex align-items-center" role="alert"><div>{this.props.t('Documents.label21')}</div></div>
                    </div>
                    <ol>
                    {uploadedFiles.map((fileName, index) => (
                        <li key={index}>{fileName}</li>
                    ))}
                    </ol>
                    {visibleAnuncio? (
                        <div className="alert alert-info" role="alert">{this.props.t('Documents.label24')}</div>
                    ) : null}
                    <div className='col-md-12 right'>
                        <button className='btn btn-primary btn-bg-str btn-lg' onClick={() =>this.limpiarSessionStorageYRenderizar()}>{this.props.t('Buttons.uploadnew')}</button>
                    </div>
                    <div className='form-group row'>
                        <div className='col-md-6 content-info '>
                        <h5><b>{this.props.t('ContactAgency.title')}</b></h5>
                        <i className="fa-solid fa-envelope"></i>&nbsp;<label>{this.props.t('ContactAgency.label1')}</label>: 
                        <br/><b>{contactoAgencia}</b>
                        </div>
                        <div className='col-md-6 content-info '>
                        <h5><b>{this.props.t('GeneralData.title')}</b></h5>
                        <i className="fa-solid fa-building"></i>&nbsp;<label>{this.props.t('GeneralData.label15')}: </label> <b>{tipoProv}</b> 
                        <br/> 
                        </div>
                    </div>
                    <hr/>
                    <form>
                        <div className="row center">
                            <div className="col-md-4">
                                <label>
                                <a className="aviso-privacidad " id="btn-aviso-confidencialidad" target="_blank" href={doc1} rel="noopener noreferrer"> {this.props.t('Confirm.confidencialidad')}</a>
                                </label>
                            </div>
                            <div className="col-md-4">
                                <label>
                                <a className="aviso-privacidad" id="btn-aviso-privacidad" target="_blank" href={doc2} rel="noopener noreferrer">{this.props.t('Confirm.privacidad')}</a>
                                </label>
                            </div>
                            <div className="col-md-4">
                                <label>
                                <a className="aviso-privacidad" id="btn-codigo-conducta" target="_blank" href={doc3} rel="noopener noreferrer"> {this.props.t('Confirm.conducta')}</a>
                                </label>
                            </div>
                        </div>
                        <br/><br/>
                        <div className='row center'>
                            <p>
                                <input 
                                    type="checkbox"  
                                    className="option-input checkbox"
                                    checked={this.state.isCheckedCC} 
                                    onChange={event=>{
                                        this.setState({
                                            isCheckedCC: event.target.checked,
                                        });
                                    }}
                                    id="aviso-CC" 
                                    /> 
                                &nbsp; <span className='condiciones'>{this.props.t('Confirm.terminos')}</span>
                                <br/><br/>
                                <span className='error condiciones' id="valid_AC"></span> 
                            </p>
                        </div> 
                        <div className='buttons'>
                            <button className='buttons__button buttons__button--back' onClick={this.back}  >{this.props.t('Buttons.previous')}</button>
                            <button className='buttons__button buttons__button--next'  onClick={this.handleCheck}>{this.props.t('Buttons.save')}</button>
                        </div>
                    </form>
                </div>
            )
        }
        else{
            return (
                <>
                <br/><br/>
                <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                    <div className="step-counter">1</div>
                    <div className="step-name">{this.props.t('TitleSteps.contact')}</div>
                    </div>
                   
                    <div className="stepper-item completed active">
                    <div className="step-counter">3</div>
                    <div className="step-name">{this.props.t('TitleSteps.documents')}</div>
                    </div>
                </div>
                <div className='form-group row'>
                    <h1 className='intro-registro'>DOCUMENTOS</h1>
                </div>
               
                 {visibleCartaIngresos? (
                <div className="alert alert-info" role="alert"><a href={carta} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-file-arrow-down"></i> &nbsp;{t('Documents.label25')}</a></div>
                ) : null}
                <form onSubmit={this.handleSubmit}>
                    {this.state.data.map(document => (
                    <div key={document.id} className="form-group files col-md-12">
                        <br/>
                        <p><b>{document.documento}:</b></p>
                        <input
                        type="file"
                        id={`file-${document.id_documento}`}
                        onChange={(e) => this.handleFileChange(e, document.id_documento)}
                        required={document.requerido === 1 ? true : false}
                        name={document.documento}
                        />
                        {errors[document.id_documento] && <div style={{ color: 'red' }}>{errors[document.id_documento]}</div>}
                    </div>
                    ))}
                    <br/>
                    {visibleCartaIngresos? (
                        <div className="alert warning" role="alert">{t('Influencer.modal9')}</div>
                    ) : null}
                    {visibleCartaPoder? (
                    <div className="alert warning" role="alert">
                        {t('Influencer.modal22')}
                    </div>
                    ) : null}

                    <div className='col-md-12 right'>
                        <br/>
                        <button className='btn btn-primary btn-bg-str btn-lg' type='submit'>{t('Buttons.upload')}</button>
                    </div>
                </form>
                <div className='buttons'>
                    <button className='buttons__button buttons__button--back' onClick={this.back}  >{this.props.t('Buttons.previous')}</button>
                </div>
                </>
            )
        }
    }
}

export default withTranslation()(Documentos)