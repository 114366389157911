/**
 * TABLA PERIODO CIERRE
*/
import React from 'react';
import DOMPurify from 'dompurify';
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Pager,
    HeaderFilter,
    //Selection,
    Editing,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';

;
//configura el número de filas que se mostraran en la tabla
const allowedPageSizes = [10, 15, 20, 25, 30, 35, 40, 45, 50,'all'];

class TablaCierrePeriodo extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
          }, {
            key: 'onClick',
            name: 'On Button Click'
          }];
        this.state = {
            selectAllCheckBox: null,
            checkBoxUpdating : false,
        //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
        showEmployeeInfo: false,
        displayMode: 'compact',
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        showFilterRow: true,
        showHeaderFilter: true,
        currentFilter: this.applyFilterTypes[0].key,
        selectedItemKeys: [],
        data:[],
        loading: true,
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.isEditDisabled = this.isEditDisabled.bind(this);
        this.dataGridRef = React.createRef();
    } 

    //Obtiene lista de periodos/fechas
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchFechas();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchFechas() { 
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        fetch(API_PATH + 'periodo_cierre/all', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()// si el estatus code arroja un 200  continua
            } else {// si no arroja el estatus code del error

                Swal.fire({
                    title: DOMPurify.sanitize(`${response.statusText}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    //Llama a las acciones de la barra de herramientas de la tabla
    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            
            { //activar portal
                location: 'after',
                widget: 'dxButton',
                options: {
                    onClick: this.activarPortal.bind(this),
                    text:"Activar Portal",
                    icon: 'check',
                },
            },
            { //Limpiar filtros
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'filter',
                    onClick: this.clearFilters.bind(this),
                    text:"Limpiar filtros ",
                },
            },

            {   //Actualizar tabla
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: this.refreshData.bind(this),
                },
            },

            
        );
    }

    //ACTUALIZAR TABLA
    refreshData() {
        this.setState({
        data: []
        });
        setTimeout(() => this.fetchFechas(), 10);;
    }

     //Limpiar filtros
     clearFilters = () => {
        const { current: dataGrid } = this.dataGridRef;
        if (dataGrid) {
          dataGrid.instance.clearFilter();
        }
    };  


    /**
    * Activa el portal
    */

    activarPortal(){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'PATCH',
            headers: { 
              'Content-Type': 'application/json' ,
              Authorization: 'Bearer ' + token,
            },
        }

        fetch(API_PATH + 'periodo_cierre/', options)
        .then(response => response.json())
        .then(result => {
            if(result.responseCode === 200){// si en responseCode arroja un 202  manda mensaje de exito 
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: "success",
                    timer: 3000
                })
            } else{// si no arroja un 200 en responseCode de desactivar proveedor
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }  
        })
        .catch(error => {
            Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });
    }

    /**
    * VERIFICAR ESTATUS Y DESACTIVA LA OPCIÓN PARA EDITAR periodos
    */
     isChief(activo) {
        if(activo === 1){
        return false
        }else{
            return true
        }
       // return estatus && ['0'].indexOf(estatus.trim()) >= 0;
    }
    isEditDisabled(e) { 
        return this.isChief
    }

    /**
    * Convierte la columna Estatus en activo o inactivo el estatus del periodo, ya que lo devuelve en 1 o 0
    */
    cellRenderP(data) {
        let estatus = ( data.data.activo === 0) ? 'Inactivo' : 'Activo'
        return <span>{estatus}</span>;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Guarda o actualiza un registro
    */

    onSaving = (e) => {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        let row = e.changes
        // obtiene el cammpo fecha inicio
        let fechaIni
        row.forEach(row =>fechaIni= row.data.inicio);
        // obtiene el campo fecha fin
        let fechaFin
        row.forEach(row =>fechaFin= row.data.fin);
        // obtiene si es una modificación o inserción
        let tipoMetodo
        row.forEach(row =>tipoMetodo= row.type); 
        
        const dataBody = {
            inicio: fechaIni,
            fin: fechaFin,
        };
        const jsonString = this.convertObjectToJsonString(dataBody);

        const options = {
            method: ( tipoMetodo === "insert") ? 'POST' : 'PATCH',
            headers: { 
              'Content-Type': 'application/json' ,
              Authorization: 'Bearer ' + token,
            },
            body: jsonString
        }

        fetch(API_PATH + 'periodo_cierre/', options)
        .then(response => response.json())
        .then(result => {
            
            if(result.responseCode === 200){// si en responseCode arroja un 202  manda mensaje de exito 
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: "success",
                    timer: 3000
                })
                this.refreshData()
            } else{// si no arroja un 200 en responseCode 
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }  
        })
        .catch(error => {
            Swal.fire({
                        text: "Ocurrio un error",
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
            })
            throw error;
        });
    }

    render() {
    if (this.state.loading === true) {
        return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <DataGrid
                ref={this.dataGridRef}
                dataSource={this.state.data}
                showBorders={true}
                hoverStateEnabled={true}
                keyExpr="id"
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
                allowColumnResizing={true}
                selectedRowKeys={this.state.selectedItemKeys}
                onToolbarPreparing={this.onToolbarPreparing}
                onEditorPreparing={this.onEditorPreparing}
                onSelectionChanged={this.onSelectionChanged}
                onSaving={this.onSaving}  
            >
                {/* Buscador */}
                <SearchPanel visible={true}
                    width={200}
                    placeholder="Buscar"
                /> 
                {/* <Selection mode="multiple" /> */}
                {/* Paginación */}
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={this.state.displayMode}
                    showPageSizeSelector={this.state.showPageSizeSelector}
                    showInfo={this.state.showInfo}
                    showNavigationButtons={this.state.showNavButtons}
                />
                {/* Filtros */}
                <HeaderFilter visible={this.state.showHeaderFilter} />
                {/* Modo de edición*/}
                <Editing
                    mode="batch"
                    allowUpdating={false}
                    allowAdding={true}
                />
                {/* Columnas*/}
                {/* Cambiar los nombre de datafield por como vengan en la api */}
                <Column dataField="inicio" caption="Fecha inicio" dataType="date" />
                <Column dataField="fin" caption="Fecha fin" dataType="date"  />
                <Column dataField="Estatus"  cellRender={this.cellRenderP} allowHeaderFiltering={false}/>
            </DataGrid>
        </React.Fragment>
    );
  }

   /**
    * Obtiene las filas seleccionadas
    * Verifica si tiene estatus activo o no para que se desahbilite la opcion de seleccionar
    */

   isSelectable(item) {
        return item.activo;
    }

    isSelectAll(dataGrid) {
        let items = [];
        dataGrid.getDataSource().store().load().done(function (data) {
        items = data;
        });
        let selectableItems = items.filter(this.isSelectable);
        let selectedRowKeys = dataGrid.option("selectedRowKeys");
        if (!selectedRowKeys.length) {
        return false;
        }
        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    }

    onEditorPreparing(e) {
        let dataGrid = e.component;
        if (e.command === "select") {
            if (e.parentType === "dataRow" && e.row) {
                if (!this.isSelectable(e.row.data))
                    e.editorOptions.disabled = true;
                } else if (e.parentType === "headerRow") {
                    e.editorOptions.onInitialized = (e) => {
                        this.setState({
                        'selectAllCheckBox': e.component
                        })
                    };
                    e.editorOptions.value = this.isSelectAll(dataGrid);
                    e.editorOptions.onValueChanged = (e) => {
                    if (!e.event) {
                        if (e.previousValue && !this.state.checkBoxUpdating) {
                            e.component.option("value", e.previousValue);
                        }
                        return;
                    }
                    if(this.isSelectAll(dataGrid) === e.value) {
                        return;
                    }
                    e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                    e.event.preventDefault();
                }
            }
        }
    }

    onSelectionChanged(e) {
        let deselectRowKeys = [];
        e.selectedRowsData.forEach((item) => {
            if (!this.isSelectable(item))
                deselectRowKeys.push(e.component.keyOf(item));
        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }
        this.setState({
            'checkBoxUpdating': true
        });
        if (this.state.selectAllCheckBox) {
            this.state.selectAllCheckBox.option && this.state.selectAllCheckBox.option("value", this.isSelectAll(e.component));
        }
        this.setState({
            'checkBoxUpdating': false
        });
    }  

}

export default TablaCierrePeriodo;