/**
 * PANTALLA DE INICIO PARA LOGUEO (no usa el cierre)
*/

import React, {Component} from 'react';
import Login from '../../login';
import logo from '../../assets/img/ipg-logo.png'

class HomeCierre extends Component {
    render() {
        return (
            <div>
                <div className="split left-login">
                <div className="centered"></div>
                </div>
                <div className="split right-login">
                    <div className="centered">
                        <div className="container ">
                            <form action="#" className="form-login">
                                <div className='center'>
                                    <h1 className="title1">BIENVENIDO</h1>
                                    <h3 className="title2">AL PORTAL DE PROVEEDORES</h3>
                                    <hr/><br/>
                                    <img src={logo} alt="logo-ipg"/><br/><br/><br/>
                                    <div className="d-grid gap-2">
                                    {/* Manda llamar al componete de Login para SSO, que abre en popup */}                 
                                    <Login/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>   
            </div>
        );
    }
}

export default HomeCierre;