/**
 * MODAL AL INICIO DEL REGISTRO
 */
import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';


class ModalInfluencer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    }; 
  }
  render() {
    const { t, isOpen, closeModal } = this.props;
      return(
      <div>
        <Modal show={isOpen} onHide={closeModal} dialogClassName="modal-dialog modal-xl">
          <Modal.Header closeButton>
            <Modal.Title>{t('Influencer.modal1')}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <p>
          {t('Influencer.modal0')}
          </p>
          <div className='row'>
            <div className='col-md-4'>
                <h3><small>{t('Modal.text5')}</small></h3>
                <ul>
                    <li>{t('Influencer.modal2')}</li>
                    <li>{t('Influencer.modal3')}</li>
                    <li>{t('Influencer.modal4')}</li>
                    <li>{t('Influencer.modal5')}</li>
                    <li>{t('Influencer.modal6')}</li>
                    <li>{t('Influencer.modal7')}</li>
                    <li>{t('Influencer.modal8')}</li>
                </ul>
                <div className="alert warning" role="alert">{t('Influencer.modal9')}</div>
            </div>
            <div className='col-md-4'>
            <h3><small>{t('Modal.text11')}</small></h3>
                <ul>
                    <li>{t('Influencer.modal2')}</li>
                    <li>{t('Influencer.modal3')}</li>
                    <li>{t('Influencer.modal5')}</li>
                    <li>{t('Influencer.modal6')}</li>
                    <li>{t('Influencer.modal10')}</li>
                    <li>{t('Influencer.modal11')}</li>
                    <li>{t('Influencer.modal12')}</li>
                    <li>{t('Influencer.modal8')}</li>
                    <li>{t('Influencer.modal14')}</li>
                </ul>
            </div>
            <div className='col-md-4'>
                <h3><small>{t('Modal.text19')}</small></h3>
                <ul>
                    <li>{t('Influencer.modal15')}</li>
                    <li>{t('Influencer.modal16')}</li>
                    <li>{t('Influencer.modal8')}</li>
                    <li>{t('Influencer.modal17')}</li>
                    <li>{t('Influencer.modal20')}</li>
                    <li>{t('Influencer.modal18')}</li>
                    <li>{t('Influencer.modal19')}</li>
                   
                </ul>
                <div className="alert info-alert" role="alert">{t('Influencer.modal21')}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
          {t('Modal.text25')}
          </Button>
        </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(ModalInfluencer);