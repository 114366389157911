/**
 * * CONTIENE EL FORMULARIO PARA LA SECCION DE DATOS GENERALES
*/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class DatosInfluencer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  /** 
  LLAMA A LA FUNCIÓN PARA REGRESAR AL PASO ANTERIOR
  */
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  
  /**
    * Llama a la función para continuar al siguiente paso
    * Primero valida que seleccionen un tipo de proveedor y luego que ingresen un texto en razon social
  */

  continue = e => {
    e.preventDefault(); 
    const { tipoProveedor, errores, t } = this.props;
  
    // Validar el campo tipo Proveedor
    if (!tipoProveedor) {
      errores.tipoProveedor = `${t('GeneralData.error13')}`;
    } else {
      delete errores.tipoProveedor; // Elimina el error si el campo es válido
    }

    // Comprueba si hay errores
    if (Object.keys(errores).length === 0) {
      this.props.nextStep();
    } else {
      this.setState(prevState => ({ errores: { ...prevState.errores, ...errores } })); // Actualiza el estado de errores si los hay
    }
  };
  

  render() {
    /**
    * Obtiene las propiedades desde form 
    * Obtiene los valores de los inputs, opciones de paises, mensajes de error 
    */
    const { 
      tipoProveedor,
      handleOpcionChange,
      errores,
      t
    } = this.props;

    return (
        <form> <br/><br/>
          <div className="stepper-wrapper">
                <div className="stepper-item completed active">
                    <div className="step-counter">1</div>
                    <div className="step-name">{t('TitleSteps.datosGen')}</div>
                </div>
                <div className="stepper-item">
                    <div className="step-counter">2</div>
                    <div className="step-name">{t('TitleSteps.documents')}</div>
                </div>

            </div>
          <div className='form-group row'>
            <h1 className='intro-registro'>{t('GeneralData.title')}</h1>
            <div className='col-md-6'>
              <input
                className="option-input radio"
                type="radio"
                name="tipoProveedor"
                value="fisica"
                checked={tipoProveedor === 'fisica'}
                onChange={handleOpcionChange}
              /> 
             {t('Modal.text5')}<br/><br/>

              <input
                className="option-input radio"
                type="radio"
                name='tipoProveedor'
                value="moral"
                checked={tipoProveedor === 'moral'}
                onChange={handleOpcionChange}
              />
               {t('Modal.text11')}<br/><br/>       

              <input
                className="option-input radio"
                type="radio"
                name="tipoProveedor"
                value="extranjero"
                checked={tipoProveedor === 'extranjero'}
                onChange={handleOpcionChange}
              /> 
                {t('Modal.text19')}<br/><br/>

               {errores.tipoProveedor && <p className="error">{errores.tipoProveedor}</p>}
            </div>
          </div>
          
          <div style={{textAlign: 'center'}}>
            <button className='buttons__button buttons__button--next' onClick={this.continue}>{t('Buttons.next')}</button>
          </div>

        </form>
    )
  }
}

export default withTranslation()(DatosInfluencer);