/**
 * INDEX PARA LA VISTA DE FACTURAS V3
*/
import React, { Component } from 'react';
import TablaFacturasV3 from './TableFacturasV3'
import {Redirect} from 'react-router-dom';

export default class VistaFacturasV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      valorBuscado: 19, //lfacturas v3
    }
  }

  componentWillMount() {
    //valida que solo los usuarios con rol de logs tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
    const data = JSON.parse(sessionStorage.getItem('userData'));
    const roles = data.roles;
    const { valorBuscado } = this.state;
    const contieneValor = roles.includes(valorBuscado);
      if (contieneValor) {
        this.setState({ redirectToReferrer: false });
    } else {
        this.setState({ redirectToReferrer: true });
    }
    }

  render() {

    if (this.state.redirectToReferrer) {
      return (<Redirect to={'/'}/>)
    }

    return (
      <div className='container'>
        <br/>
        <br/>
        <h3 className='blue'>Facturas V3</h3>
        <br/>
        <TablaFacturasV3/>
      </div>);
  
  }
  
}
