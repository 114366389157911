import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import "./styles/App.css";

/**
 * Muestra información sobre el usuario que inició sesión
 */
 const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [tokenValue,setTokenValue]=useState(null);
    function RequestProfileData() {
        // Adquiere "silenciosamente" un token de acceso que luego se adjunta a una solicitud de datos de MS Graph
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setTokenValue(response.accessToken);
            callMsGraph(response.accessToken)
            .then(response => setGraphData(response));
        });
    }

    useEffect(()=>{RequestProfileData()},[]);// eslint-disable-line
 
    return (
        /**
         * muestra un loading mientras se hace el redireccionmiento a la pantalla de inicio una vez logueado
        */
        <div>
            {graphData ? 
                <ProfileData graphData={graphData} tokenValue={tokenValue} />
                 :
                <div className="preloader" onClick={RequestProfileData}>
                     <div className="padre-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

/**
 * Si un usuario está autenticado, se va hacia el componente ProfileContent. De lo contrario, se muestra un mensaje que indica que un usuario no está autenticado.
*/
 const MainContent = () => {    
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <div>
            <PageLayout>
                <MainContent />
            </PageLayout>
        </div>
    );
}
