/**
 * INDEX PARA LA VISTA DE LOS LOGS
*/

import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import TableLogsInvoices from './TableLogsInvoices';

class VistaLogsInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            valorBuscado: 27, //logs
            loading: true,
        };
    }

    componentWillMount() {
        //valida que solo los usuarios con rol de logs tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }
    
        return (
            <div className="container">
                <br/>
                <br/>
                <h3 className='blue'>Logs Invoices</h3>
                <TableLogsInvoices/>
                <br/><br/>
               <br/>
            </div>
        )
    }
}

export default VistaLogsInvoices;
