/**
 * * FORMULARIO PARA EDITAR UN USUARIO SSC
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

class ResetearPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errores: {},
            passu:'',
            razon_social: '',
            razonSocialModificada: false
        }
    this.resetearPassword = this.resetearPassword.bind(this);
    }

    /**
    * Maneja los cambios que se realizan en los inputs
    */
 
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    
    /*
    * Función para resetear el password de los usuarios en azure
    */
    resetearPassword(event){
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        const {passu, razon_social} = this.state;
        const errores = {};
        // if (!passu) {
        //     errores.passu = 'El campo es obligatorio.';
        // }
        // if (!razon_social && razonSocialModificada) {
        //     errores.razon_social = 'El campo es obligatorio.';
        // }
        if (Object.keys(errores).length === 0) {
        const options = {
            method: 'PATCH',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify ({
                id: this.props.datosUsuario.uuid,
                name: razon_social || this.props.datosUsuario.razon_social,
                pwd:passu || this.props.datosUsuario.passU
            })
        }
        fetch(API_PATH + 'update_user/azure/', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
            if(result.status_code === 202){
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
                this.refreshData()
            }else{
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        }) 
        } else {
            this.setState({ errores });
        }
    }

    render() {
        const {errores} = this.state;
        return (
            <div className="container pad-30">
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={this.resetearPassword} >
                            <label>Razón Social:</label>
                            <input
                                ref="razon_social"
                                name="razon_social"
                                defaultValue={this.props.datosUsuario.razon_social}
                                placeholder='Ingresa el nuevo password'
                                onChange={this.handleChange}
                                className="form-control"                    
                            />
                            {errores.razon_social && <p className="error">{errores.razon_social}</p>}
                            <br/>
                           <label> Password:</label>
                            <input
                                ref="passu"
                                name="passu"
                                defaultValue={this.props.datosUsuario.passU}
                                placeholder='Ingresa el nuevo password'
                                onChange={this.handleChange}
                                className="form-control"                    
                            />
                            {errores.passu && <p className="error">{errores.passu}</p>}
                            <br/>
                            <div className='right'><button className='btn btn-primary btn-bg'>Guardar</button></div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetearPassword;