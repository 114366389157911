/**
*/

import React from 'react';
import DOMPurify from 'dompurify';
// import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import { withRouter } from 'react-router-dom';

class UsuariosExternos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            data: [],
            isClearable: true,
            hasSelection: true,
            isEditing: false,
            valorBuscado: 1, //editar proveedor
            redirectToReferrer: false,
            errors: {},
            input: {},
            isVisible:true,
            notVisible:false,        
        };

    }

    componentWillMount() {
        //valida que solo los usuarios con rol de administrador tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    /**
    * Obtiene los datos de azure
    */
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.fetchInfoAzure();
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    fetchInfoAzure() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken

        fetch(API_PATH + 'proveedor/datos?id_proveedor=2488' , {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        })
        .then(response => {
            if (response.ok) {
              return response.json()
            } else {
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
          })
        .then((responseJson) => {
            this.setState({ data: responseJson, loading: false });
        })
        .catch(error => {
            throw error;
        });
    }

    handleChange = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [e.target.name]: e.target.value
            }
        }));
    }

    handleEditClick = () => {
        this.setState({ isEditing: true });
    }

    handleCancelClick = () => {
        this.setState({ isEditing: false });
    }

    /**
    * Validación de inputs
    */
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        function validarVacios(campo, valor) {
            var patternVacio = /^.+$/;
            if (!patternVacio.test(valor)) {
              isValid = false;
              errors[campo] = "El campo no puede estar vacío";
            }
        }

        if (typeof input["scope"] !== "undefined") {
            validarVacios("scope", input["scope"]);
        }

        if (typeof input["secret"] !== "undefined") {
            validarVacios("secret", input["secret"]);
        }

        if (typeof input["endpoint"] !== "undefined") {
            validarVacios("endpoint", input["endpoint"]);
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }
    
    /**
    * Función para guardar los datos editados
    */
   
    handleSaveClick = (event) => {
        event.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken

        if(this.validate()){ // primero valida que los campos sean válidos
            let input = {};
            input["scope"] = "";
            input["secret"] = "";
            input["endpoint"] = "";
            this.setState({input:input});

            const dataBody = {
                    scope:this.state.input.scope,
                    secret:this.state.input.secret,
                    endpoint: this.state.input.endpoint,
            };
            const jsonString = this.convertObjectToJsonString(dataBody);

            const options = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: jsonString
            }
            fetch(API_PATH + 'proveedor/seguridad', options)
            .then(response => {
                if (response.ok) {// si el estatus code arroja un 200  continua
                    return response.json()
                } else {// si no arroja el estatus code del error
                    Swal.fire({
                        title: DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
            .then(result => {
                if(result.responseCode === 200 ){// si en responseCode arroja un 200  manda mensaje de exito 
                    Swal.fire({
                        text: DOMPurify.sanitize(`${result.detail}`),
                        showCancelButton: false,
                        showConfirmButton: false,
                        icon: "success",
                        timer: 3000
                    })
                    this.setState({ isEditing: false })
                    setTimeout(function () {
                        window.location.href = "/seguridad";
                    },  1000);
                } else{// si arroja un 200 en responseCode 
                    Swal.fire({
                        text: DOMPurify.sanitize(`${result.detail}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                    this.setState({ isEditing: true })
                    }  
                })
            .catch(error => {
                    this.setState({ isEditing: true })
                    Swal.fire({
                                text: "Ocurrio un error",
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                    })
                    throw error;
            });
        } 
    }

   
    render() {
        const { valorBuscado,  isEditing } = this.state;

        const data = JSON.parse(sessionStorage.getItem('userData'));

        //verifica si tiene el rol de seguridad
        const roles = data.roles;
        const contieneValor = roles.includes(valorBuscado);
        let editar = (contieneValor === true) ? 1 :0

        // if (this.state.redirectToReferrer) {
        //     return (<Redirect to={'/'}/>)
        // }
        return (
            <div>
                <br/><br/>              
                {this.state.data.map((azure, i )=> {
                    return(
                        <div className="container info-proveedores " key={i}>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Configuración de API de usuarios externos</h2><br/>
                                </div>
                                {/* {editar === 1 && !isEditing && (
                                    <div className='col-md-12 btns-edit'>
                                    <button onClick={this.handleEditClick} className='btn btn-primary btn-bg'><i className="fa-solid fa-pen-to-square white"></i> Editar datos</button>
                                    </div>
                                )}       */}
                            </div>
                        
                            {/* Verifica que tenga los permisos para editar*/}
                            {isEditing  && editar === 1 ? (
                                <><br/><br/>
                                    <div className='row'>

                                        <div className='col-md-3' key={i}>
                                            <b>scope:</b><br/>
                                            <input
                                                type="text"
                                                name="scope"
                                                id="scope"
                                                className='form-control'
                                                // value={this.state.input.scope}
                                                defaultValue={azure.detail.scope}
                                                onChange={this.handleChange} 
                                            />
                                           <p className='error'>{this.state.errors.scope}</p>
                                        </div>

                                        <div className='col-md-3'>
                                            <b>secret:</b><br/>
                                            <input
                                                type="text"
                                                name="secret"
                                                id="secret"
                                                className='form-control'
                                                // value={this.state.input.secret}
                                                defaultValue={azure.detail.secret}
                                                onChange={this.handleChange}   
                                                                      
                                            />
                                            <p className='error'>{this.state.errors.secret}</p>
                                        </div>

                                        <div className='col-md-3'>
                                            <b>endpoint:</b><br/>
                                            <input
                                                    type="text"
                                                    name="endpoint"
                                                    id="endpoint"
                                                    className='form-control'
                                                    // value={this.state.input.endpoint}
                                                    defaultValue={azure.detail.puesto_administrador}
                                                    onChange={this.handleChange}  
                                                                            
                                            />
                                            <p className='error'>{this.state.errors.endpoint}</p>
                                        </div>

                                        <div className='col-md-3'>
                                            <b>fecha de vencimiento:</b><br/>
                                            <input
                                                    type="text"
                                                    name="fechaVencimiento"
                                                    id="fechaVencimiento"
                                                    className='form-control'
                                                    // value={this.state.input.fechaVencimiento}
                                                    defaultValue={azure.detail.puesto_administrador}
                                                    onChange={this.handleChange}  
                                                                            
                                            />
                                            {/* <p className='error'>{this.state.errors.fechaVencimiento}</p> */}
                                        </div>

                                    </div>

                                    <br/>
                                    {isEditing  && editar === 1? (
                                    <>
                                        <div className='btns-edit'>
                                            <button onClick={this.handleCancelClick} className='btn btn-primary btn-red'>Cancelar</button>&nbsp;
                                            <button onClick={this.handleSaveClick} className='btn btn-primary btn-green'>Guardar</button>
                                        </div>
                                    </>
                                    ) : null}  
                                    </>
                                    ) : (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-12'>

                                            <b>authority:</b>  https://login.microsoftonline.com/69b88bc1-ac26-4ef5-91d7-8b9980382f55 <br/>
                                            <b>client_id: </b> 083de31c-ee82-4dcc-843f-3645d9743509 <br/>
                                            <b>scope: </b> https://graph.microsoft.com/.default <br/>
                                            <b>secret:</b>  7lV8Q~fPc4Baz60c7TD7in~5sKaX.exsuV3kzaLC <br/>
                                            <b>endpoint:</b>  https://graph.microsoft.com/v1.0/users <br/>

                                                {/* <b>scope: </b> {azure.detail.clave_pais}<br/>
                                                <b>secret:</b> {azure.detail.rfc}<br/>
                                                <b>endpoint:</b> {azure.detail.calle}<br/>
                                                <b>fecha de vencimiento:</b> {azure.detail.calle}<br/> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )
                    })}
            <br/><br/><br/><br/><br/>
        </div> 
        )
    }
}

export default withRouter (UsuariosExternos);
