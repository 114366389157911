/**
 * INDEX PARA LA ADMINISTRACIÓN UNA VEZ LOGUEDADOS
*/
import React, { Component } from 'react';
import VistaAprobadores from '../Admin/VistaAprobadores';
import VistaAdminUsuarios from '../Admin/VistaAdminUsuarios';
import VistaBajaProveedores from '../Admin/VistaBajaProveedores';
import VistaAdminUsuariosSSC from '../Admin/VistaAdminUsuariosSSC';
import VistaLogs from '../Admin/VistaLogs';
import VistaCierrePerido from '../Admin/VistaCierrePerido';
import VistaOrdenesCompra from '../Admin/VistaOrdenesCompra';
import VistaAltaProveedores from '../Admin/VistaAltaProveedores';
import VistaSFTP from '../Admin/VistaSFTP';
import VistaSeguridad from '../Admin/VistaSeguridad';
import VistaFacturasV3  from '../Admin/VistaFacturasV3'


export default class PaginaInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFeed: '',
      redirectToReferrer: false,
      loading: true,
    }
    this.getUserFeed = this.getUserFeed.bind(this);
  }

  /**
  * Obtiene los datos del usuario
  */
  componentWillMount() {
    if (sessionStorage.getItem('userData')) {
      this.getUserFeed();
    } else {
    this.setState({ redirect: true, loading: false });
    }
  }

  getUserFeed() {
    let data = {};
    new Promise((resolve, reject) => {
    data = JSON.parse(sessionStorage.getItem('userData'));
      resolve();
    }).then(() => {
      this.setState({ userData: data });
    });
  }

  render() {
    const data = JSON.parse(sessionStorage.getItem('userData'));
    const array = data.roles;
    //const array = [0,1, 2, 3, 4];
    let text = "";

    if (array.includes(0)){
      text = <VistaAprobadores/>;
    } else if (array.includes(1)) {
      text = <VistaAdminUsuarios/>;
    } else if (array.includes(2)) {
      text = <VistaBajaProveedores/>;
    } else if (array.includes(3)) {
      text = <VistaAdminUsuariosSSC/>;
    } else if (array.includes(4)) {
      text = <VistaLogs/>;
    } else if (array.includes(5) && array.includes(7)) {
      text = <VistaOrdenesCompra/>;
    } else if (array.includes(6)) {
      text = <VistaCierrePerido/>;
    } else if (array.includes(7)) {
      text = <VistaOrdenesCompra/>;
    } else if (array.includes(10)) {
      text = <VistaOrdenesCompra/>;
    } else if (array.includes(5)) {
      text = <VistaOrdenesCompra/>;
    }else if (array.includes(15)) {
      text = <VistaSFTP/>;
    }else if (array.includes(16)) {
      text = <VistaAltaProveedores/>;
    }else if (array.includes(18)) {
      text = <VistaSeguridad/>;
    }else if (array.includes(19)) {
      text = <VistaFacturasV3/>;
    }else if (array.includes(0)) {
      text = "";
    }
    return <div>{text}</div>;
  }
  
}
