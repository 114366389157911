/**
 * * FORMULARIO PARA AÑADIR NUEVO USUARIO SSC
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

class RegistrarUsuarioSSC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error : '',
            errors: {},
            input: {},
        }
    this.guardarUsuario = this.guardarUsuario.bind(this);
    this.limpiaCampos = this.limpiaCampos.bind(this);
    }


    /**
    * Maneja los cambios que se realizan en los inputs
    */
    handleChange = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [e.target.name]: e.target.value
            }
        }));
    }

    /**
    * LIMPIA LOS CAMPOS DESPUES DEL REGISTRO
    */
    limpiaCampos() {
        this.setState({
        input: '',
        });
    }

    /**
    * Validación de inputs
    */
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["name"]) {
            isValid = false;
            errors["name"] = "Ingresa el nombre del usuario";
        }

        if (typeof input["name"] !== "undefined") {
            var pattern = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
            if (!pattern.test(input["name"])) {
                isValid = false;
                errors["name"] = "Solo letras";
            }
        }

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Ingresa el correo del usuario";
        }

        if (typeof input["email"] !== "undefined") {

            var patternMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!patternMail.test(input["email"])) {
                isValid = false;
                errors["email"] = "Ingresa un correo válido";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Función para el guardado de datos (nuevo usuario ssc)
    */
    guardarUsuario(event){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        event.preventDefault();
            if(this.validate()){
                let input = {};
                input["name"] = "";
                input["email"] = "";
                this.setState({input:input});

                const dataBody = {
                    nombre: this.state.input.name,
                    correo:this.state.input.email,
                    grupo:13
                };
                const jsonString = this.convertObjectToJsonString(dataBody);

                const options = {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: jsonString
                }
                fetch(API_PATH + 'usuarios/nuevo_usuario', options)
                .then(response => {
                    if (response.ok) {// si el estatus code arroja un 200  continua
                      return response.json()
                    } else {// si no arroja el estatus code del error
                        Swal.fire({
                            title: DOMPurify.sanitize(`Error ${response.status}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
                .then(result => {
                    if(result.responseCode === 200){// si en responseCode arroja un 200 continua con el guardado
                        Swal.fire({
                            text: "El usuario se registro correctamente",
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: "success",
                            timer: 4000
                        })
                        this.props.actualizar();
                        this.limpiaCampos();
                    }else{
                        Swal.fire({
                            text: DOMPurify.sanitize(`${result.detail}`),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonColor: '#bb2d3b',
                        })
                    }
                })
            }     
        }

  render() {
    return (
        <div className="container pad-30">
            <div className="row">
                <div className="col-sm-12">
                    <form onSubmit={this.guardarUsuario} >
                        <i className="fa-solid fa-person"></i>&nbsp;<label>Nombre</label>
                        <input type="text"
                            placeholder="Nombre"
                            name="name"
                            onChange={this.handleChange}
                            value={this.state.input.name}
                            id="name"
                            autoFocus="autofocus"
                            className='form-control'
                        />
                        <p className='error'>{this.state.errors.name}</p>
                        <i className="fa-solid fa-envelope"></i>&nbsp;<label>Correo</label>
                        <input
                            type="text"
                            name="email"
                            value={this.state.input.email}
                            onChange={this.handleChange}
                            placeholder="Correo"
                            id="email"
                            className='form-control'
                        />                        
                        <p className='error'>{this.state.errors.email}</p>
                        <div className='right'><button className='btn btn-primary btn-bg'>Guardar</button></div>
                    </form>
                </div>
            </div>
        </div>
    );
  }
}

export default RegistrarUsuarioSSC;