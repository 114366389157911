/**
 * * FORMULARIO PARA EDITAR UN USUARIO INTERNO
*/

import React, {Component} from 'react';
import DOMPurify from 'dompurify';
import {API_PATH } from '../../siteConfig';
import Select from 'react-select'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss';



class EditarUsuarioInterno extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error : '',
            errors: {},
            input: {},
            isClearable: true,
            tipoUsuario: " ",
            tipoUsuarioList: [],
            gruposUsuario:[],
            loading: true,
        }
    this.actualizarUsuario = this.actualizarUsuario.bind(this);
    
    }


    /**
    * Obtiene la lista de los tipo de usuarios
    */
    componentDidMount() {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        this.fetchData();
        // deepcode ignore ReactMissingCleanup: <please specify a reason of ignoring this>
        setTimeout(() => {
        Promise.all([
            fetch(API_PATH +"portal_grupo/", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            }),
        ])
            .then(([res1, ]) => Promise.all([res1.json()]))
            .then(([data1, ]) => this.setState({
                tipoUsuarioList: data1,
            }));
        }, 100);
    }

    //obtener grupos del usuario
    fetchData = async () => {
        let idUser = this.props.datosUsuario.uuid  // uuid del usuario
        const dataU = JSON.parse(sessionStorage.getItem('userData'));
        const token = dataU.AccessToken
        const response = await fetch(API_PATH +"usuarios/grupos?uuid="+ idUser,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    
                },
            }
        );
        const data = await response.json();
        // Transformación de los datos recibidos
        const transformedOptions = data.map(option => ({
          value: option.id_grupo,
          label: option.nombre_grupo
        }));
        this.setState({ gruposUsuario: transformedOptions, loading:false });
    };

    /**
    * Maneja los cambios que se realizan en los inputs
    */
 
    handleChange = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [e.target.name]: e.target.value
            }
        }));
    }

    /**
    * Validación de inputs
    */
    validate(){
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (typeof input["name"] !== "undefined") {
            var pattern = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
            if (!pattern.test(input["name"])) {
                isValid = false;
                errors["name"] = "Ingresa sólo letras";
            }
        }

        if (typeof input["email"] !== "undefined") {

            var patternMail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!patternMail.test(input["email"])) {
                isValid = false;
                errors["email"] = "Ingresa un correo válido";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    /**
    * Convierte un objeto JavaScript en una cadena de texto JSON. sin usar json.stringyfy
    * haciendo uso de Object.entries() para obtener las entradas del objeto y luego recorrerlas para construir la cadena JSON manualmente
    */
    convertObjectToJsonString(obj) {
        let jsonString = '{';
        let isFirst = true;
        for (const [key, value] of Object.entries(obj)) {
          if (!isFirst) {
            jsonString += ',';
        }
        jsonString += `"${key}":`;
        if (typeof value === 'object') {
            jsonString += this.convertObjectToJsonString(value);
        } else if (typeof value === 'string') {
            jsonString += `"${value}"`;
        } else {
            jsonString += value;
        }
        isFirst = false;
        }
        jsonString += '}';
        return jsonString;
    }

    /**
    * Función para la actualización de datos
    * contiene dos endpoints uno para actualizar nombre y/o correo
    * otro para actualizar el grupo
    * estas peticiones estan anidadas
    */
    actualizarUsuario(event){
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        event.preventDefault();
        let idUser = this.props.datosUsuario.uuid  // uuid del usuario

        const tipoUser = this.state.tipoUsuario
        const ids = [];
        for (let i = 0; i < tipoUser.length; i++) {
            ids.push(tipoUser[i].value);
        }

        //convierte en arreglo de ids los valores que ya tenia
        let tipo = this.state.gruposUsuario;
        const valoresGrupo = tipo.map((elemento) => elemento.value);

        //validar si envio o no grupos nuevos si no tiene nuevos deja los anteriores
        let grupUser;
        if(ids.every((elemento) => elemento === undefined)){
            grupUser = valoresGrupo
        }else{
            grupUser = ids
        }

        let nameUser 
        let nombreUsuario  = this.state.input.name
        if(nombreUsuario === "" || nombreUsuario === null || nombreUsuario === undefined){
            nameUser = this.props.datosUsuario.nombre
        }else{
            nameUser = nombreUsuario
        }

        let mailUser 
        let mailUsuario  = this.state.input.email
        if(mailUsuario === "" || mailUsuario === null || mailUsuario === undefined){
            mailUser = this.props.datosUsuario.correo
        }else{
            mailUser = mailUsuario
        }

        const dataBody = {
            nombre: nameUser,
            correo:mailUser,
        };

        const jsonString = this.convertObjectToJsonString(dataBody);

        if(this.validate()){ // primero valida que los campos sean válidos
            let input = {};
            input["name"] = "";
            input["email"] = "";
            this.setState({input:input});
        
            const options = {
                method: 'PATCH',
                headers: { 
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body:jsonString
            }
            fetch(API_PATH + 'usuarios/modificar_usuario?uuid='+ idUser , options) // primero envía los datos de nombre y correo 
            .then(response => {
                if (response.ok) {// si el estatus code arroja un 200  continua
                  return response.json()
                } else {// si no arroja el estatus code del error
                    Swal.fire({
                        title: DOMPurify.sanitize(`Error ${response.status}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
            .then(result => {
                if(result.responseCode === 200){ // si en responseCode arroja un 200 continua con el envio del grupo
                    const options = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + token,},
                        body: JSON.stringify({
                            id_grupo:grupUser,
                            correo:mailUser,    
                        })
                    }
                    fetch(API_PATH + 'usuarios/grupos' , options) // envia el grupo
                    .then(response => {
                        if (response.ok) {// si el estatus code arroja un 200  continua
                          return response.json()
                        } else {// si no arroja el estatus code del error
                            Swal.fire({
                                title: DOMPurify.sanitize(`Error ${response.status}`),
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                            })
                        }
                    })
                    .then(result => {
                        if(result.responseCode === 200){   // si en responseCode arroja un 200  manda mensaje de exito despues de guardar nombre, correo y/o grupo
                            
                            Swal.fire({
                                text: "El usuario se actualizó correctamente",
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: "success",
                                timer: 4000
                            })
                            this.props.actualizar();
                        }else{ // si no arroja un 200 en responseCode de modificar_grupo
                            Swal.fire({
                                text:  DOMPurify.sanitize(`${result.detail}`),
                                icon: 'error',
                                showConfirmButton: true,
                                confirmButtonColor: '#bb2d3b',
                            })
                        }
                    })
                } else{
                    Swal.fire({// si no arroja un 200 en responseCode de modificar_usuario
                        text:  DOMPurify.sanitize(`${result.detail}`),
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonColor: '#bb2d3b',
                    })
                }
            })
             
        }
    }

    render() {
    //CONFIGURA EL RESULTADO DE VALUE Y LABEL DEL SELECT 
    let options = this.state.tipoUsuarioList.map(function (tipoUsuarioList) {
        return { value: tipoUsuarioList.id, label: tipoUsuarioList.nombre };
      })
    const {isClearable, gruposUsuario} = this.state;

    if (this.state.loading === true) {
        return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                            <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container pad-30">
            <div className="row">
                <div className="col-sm-12">
                    <form onSubmit={this.actualizarUsuario} >
                        <i className="fa-solid fa-person"></i>&nbsp;<label>Nombre</label>
                        <input type="text"
                            placeholder="Nombre"
                            name="name"
                            onChange={this.handleChange}
                            // value={this.state.input.name}
                            defaultValue={this.props.datosUsuario.nombre}
                            id="name"
                            autoFocus="autofocus"
                            className='form-control'
                        />
                        <p className='error'>{this.state.errors.name}</p>
                        <i className="fa-solid fa-envelope"></i>&nbsp;<label>Correo</label>
                        <input
                            type="text"
                            name="email"
                            // value={this.state.input.email}
                            defaultValue={this.props.datosUsuario.correo}
                            onChange={this.handleChange}
                            placeholder="Correo"
                            id="email"
                            className='form-control'
                        />
                        <p className='error'>{this.state.errors.email}</p>                   
                        <i className="fa-solid fa-address-card"></i>&nbsp;<label>Tipo de usuario</label>
                        <Select
                            isMulti
                            className="z-index-1"
                            placeholder="Tipo de usuario"
                            options={options}
                            isClearable={isClearable}
                            defaultValue={gruposUsuario}
                            onChange={event => {
                                this.setState({ tipoUsuario: event });
                            }}
                        />
                        <p></p>
                        <div className='right'><button className='btn btn-primary btn-bg'>Guardar</button></div>
                    </form>
                </div>
            </div>
        </div>
    );
  }
}

export default EditarUsuarioInterno;