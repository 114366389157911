/**
 * INDEX PARA LA VISTA DE LA ACTIVACION DE SFTP
*/

import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {API_PATH } from '../../siteConfig';
import TablaUsersNull from './TablaUsersNull';

class VistaSFTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            valorBuscado: 15, //activacion
            loading: false,
            data:[],
            showList: false,
            suppliers: null,
        };
        // this.sincronizarUsuariosDB = this.sincronizarUsuariosDB.bind(this);
        // this.estatusProveedoresDB = this.estatusProveedoresDB.bind(this);
        this.estatusProveedoresAzure = this.estatusProveedoresAzure.bind(this);
        this.sincronizarUsuariosaAzure = this.sincronizarUsuariosaAzure.bind(this);
    }

    componentDidMount() {
        //valida que solo los usuarios con rol de logs tengan acceso a visualizar esta página, en caso contrario los redirige al home de su usuario
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const roles = data.roles;
        const { valorBuscado } = this.state;
        const contieneValor = roles.includes(valorBuscado);
        
        if (contieneValor) {
            this.setState({ redirectToReferrer: false });
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }

    sincronizarUsuariosaAzure(e){
        e.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'Massive_Register_Of_Supplier/Register_Suppliers_User_Not_Linked_With_Azure', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
              return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
        })
        .catch(error => {
            throw error;
        });
    }




    estatusProveedoresAzure(e){
        e.preventDefault();
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        }
        fetch(API_PATH + 'Massive_Register_Of_Supplier/Get_Suppliers_User_Not_Linked_With_Azure', options)
        .then(response => {
            if (response.ok) {// si el estatus code arroja un 200  continua
              return response.json()
            } else {// si no arroja el estatus code del error
                Swal.fire({
                    title: DOMPurify.sanitize(`Error ${response.status}`),
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: '#bb2d3b',
                })
            }
        })
        .then(result => {
                Swal.fire({
                    text: DOMPurify.sanitize(`${result.detail}`),
                    showCancelButton: false,
                    showConfirmButton: true,
                    icon: "info",
                })
        })
        .catch(error => {
            throw error;
        });
    }

    
    render() {
        if (this.state.redirectToReferrer) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div className="container">
                <br/>
                <br/>
                <h3 className='blue'>Servicios DB</h3>
                <br/>
                <div className='row'>
                    <div className='col-md-12'>
                        {/* <button className='btn btn-sftp btn-azul' onClick={(e) => this.estatusProveedoresDB(e)}>  Obtener usuarios no sincronizados en la BD</button>
                        <button className='btn btn-sftp btn-verde' onClick={(e) => this.sincronizarUsuariosDB(e)}> Registrar proveedores</button> */}
                        <TablaUsersNull/>
                    </div>
       
                </div>
                <br/>
                <hr/>
                <h3 className='blue'>Servicios AZURE</h3>
                <br/>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className='btn btn-sftp btn-azul'  onClick={(e) => this.estatusProveedoresAzure(e)}> Obtener usuarios no sincronizados en azure  </button>
                    </div>
                    <br/><br/><br/>
                    <div className='col-md-12'>
                    <button  className='btn btn-sftp btn-verde' onClick={(e) => this.sincronizarUsuariosaAzure(e)}> Registrar proveedores en azure</button>
                    </div> 
                    
                </div>
                <br/><br/>
            </div>
        )
    }
}

export default VistaSFTP;
