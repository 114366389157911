/**
 * TABLA LOGS
*/
import React from 'react';
import DataGrid, {
    Column,
    HeaderFilter,
    SearchPanel,
    Pager,
    Scrolling,
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.softblue.css';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {API_PATH } from '../../siteConfig';

class TableLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //ATRIBUTOS PARA MOSTRAR PAGINACION EN LA TABLA
            displayMode: 'compact',
            showNavButtons: true,
            showHeaderFilter: true,
            selectedItemKeys: [],
            data: [],
            currentPage: 1,
            pageSize: 10,
            loading: true,
            startDate:'', 
            endDate:''
        };
        this.dataGridRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData(this.state.currentPage);
    }

    handleStartDateChange = (event) => {
        this.setState({ startDate: event.target.value });
    };

    handleEndDateChange = (event) => {
        this.setState({ endDate: event.target.value });
    };

    handleSearchButtonClick = () => {
        // Realiza la búsqueda solo si ambas fechas están presentes
        if (this.state.startDate && this.state.endDate) {
            this.fetchData(1); // Puedes cambiar el índice de página según tus necesidades
        } else {
            console.error('Ambas fechas son requeridas para la búsqueda');
            // Puedes mostrar un mensaje de error al usuario si lo deseas
        }
    };
      

    fetchData = async (pageIndex) => {
        const data = JSON.parse(sessionStorage.getItem('userData'));
        const token = data.AccessToken;
        const { startDate, endDate } = this.state;
        let url = API_PATH + `logs/?page=${pageIndex}&page_size=${this.state.pageSize}`;

        if (startDate) {
            url += `&start_date=${startDate}`;
        }
        if (endDate) {
            url += `&end_date=${endDate}`;
        }

        try {
            
            //const pageSize = this.state.pageSize; // Asegúrate de tener el valor correcto de pageSize
            // console.log('Fetching data. Page:', pageIndex, 'PageSize:', pageSize);
            const response = await axios.get( url,
                //API_PATH + `logs/?page=${pageIndex}&page_size=${pageSize}`,
               // API_PATH + `logs/?page=${pageIndex}&page_size=${this.state.pageSize}&start_date=${startDate}&end_date=${endDate}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
            this.setState({
                data: response.data,
                currentPage: pageIndex,
                loading: false,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire({
                title: 'Ocurrio un error',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: '#bb2d3b',
            })
        }
    };


    goToPage = (pageIndex) => {
        this.setState({ currentPage: pageIndex }, () => this.fetchData(pageIndex));
    };

     // Function to go to the next page
     goToNextPage = () => {
        const nextPage = this.state.currentPage + 1;
        this.goToPage(nextPage);
    };

    // Function to go to the previous page
    goToPrevPage = () => {
        const prevPage = this.state.currentPage - 1;
        if (prevPage > 0) {
            this.goToPage(prevPage);
        }
    };

   // Function to handle page number input
    handlePageNumberChange = (event) => {
        const pageNumber = parseInt(event.target.value, 10);
        // console.log('Input value:', event.target.value);
        // console.log('Parsed number:', pageNumber);

        if (!isNaN(pageNumber) && pageNumber > 0) {
            this.setState((prevState) => ({
                currentPage: pageNumber
            }), () => {
                this.fetchData(this.state.currentPage);
            });
        }
    };

    // Function to handle records per page input
    handlePageSizeChange = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        if (!isNaN(pageSize) && pageSize > 0) {
            this.setState({ pageSize: pageSize }, () => this.fetchData(this.state.currentPage));
        }
    };
    
    render() {
        const { data, currentPage, pageSize } = this.state;
        const pageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
        if (this.state.loading === true) { // muestra un loading mientras carga la página y datos de la tabla
            return(
            <div className="preloader" >
                <div className="padre-transform">
                    <div className="loader">
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                        <div className="face">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }

        return (
            <React.Fragment>
                <label>
                    Fecha incio:
                    <input
                        type="datetime-local"
                        value={this.state.startDate}
                        onChange={this.handleStartDateChange}
                        className='form-control'
                    />
                </label>
                &nbsp; &nbsp;
                <label>
                    Fecha fin:
                    <input
                        type="datetime-local"
                        value={this.state.endDate}
                        onChange={this.handleEndDateChange}
                        className='form-control'
                    />
                </label> &nbsp; &nbsp;
                <button onClick={this.handleSearchButtonClick} className='btn btn-primary'>Buscar</button>
                <DataGrid
                    ref={this.dataGridRef}
                    dataSource={data}
                    keyExpr="Id_log"
                    showBorders={true}
                    hoverStateEnabled={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    selectedRowKeys={this.state.selectedItemKeys}
                    // columnWidth={160}
                    remoteOperations={true }
                >
                    <SearchPanel visible={true}
                        width={200}
                        placeholder="Buscar"
                    />
                    {/* Paginación */}
                    {/* <Paging defaultPageSize={pageSize} enabled={true} /> */}
                    <Pager
                         pageIndex={currentPage}
                         pageSize={pageSize}
                         onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                         onPageChanged={this.handlePageChange}
                        
                    />
                    <HeaderFilter visible={this.state.showHeaderFilter} />
                    <Scrolling columnRenderingMode="virtual" />
                    {/* Columnas*/}
                    <Column dataField="Id_log" caption="Id log" width={120}/>
                    <Column dataField="mensaje" caption="Mensaje" />
                    <Column dataField="fecha" caption="Fecha" dataType="date" width={120}/>
                    <Column dataField="nivel" caption="Nivel"width={120} />
   
                </DataGrid>
                <br/>
                
                    <div className='row'>
                        <div className='col-md-6 col-sm-6  col-6'>
                            <select value={pageSize} onChange={this.handlePageSizeChange} className="form-select select-records" >
                                {pageSizeOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-md-6 col-sm-6 col-6 end'>

                            <button onClick={this.goToPrevPage} className='btn  btn-ant'><i className="fa-solid fa-chevron-left"></i></button>
                            <label className='label-page'>
                                <input
                                    className='form-control bg-white center'
                                    // disabled
                                    // type="number"
                                    value={currentPage}
                                    onChange={this.handlePageNumberChange}
                                />
                            </label>
                            <button onClick={this.goToNextPage} className='btn  btn-next' ><i className="fa-solid fa-chevron-right"></i></button>
                        </div>
                    </div>
                    <br/><br/>
                
               
            </React.Fragment>
        );
    }



}

export default TableLogs;